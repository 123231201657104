import {Module} from 'vuex'

export interface UserState {
  value: "unset" | "demo" | "offline" | "cloud"
}

export default {
  namespaced: true,
  state: {
    value: "unset"
  },
  getters: {
    isUnset(s) {
      return s.value === 'unset'
    },
    isDemo(s) {
      return s.value === 'demo'
    }
  },
  mutations: {
    startDemo(s) {
      if (s.value === "unset") s.value = "demo"
    },
    quitDemo(s) {
      if (s.value === "demo") s.value = "unset"
    },
    login(s) {
      if (s.value === "unset" || s.value === "demo") s.value = "cloud"
    },
    online(s) {
      if (s.value === "offline") s.value = "cloud"
    },
    offline(s) {
      if (s.value === "cloud") s.value = "offline"
    },
  }
} as Module<UserState, any>
