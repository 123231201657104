<template lang="pug">
//- span
  //- pre {{load}}

transition(name='fadescale')
    .startloader._middle(v-if='uiloader.startloader', @click="$store.commit('loader',['startloader',false])")
      .mainloader._middle
//- transition(name='loader', mode="out-in")
  .head(:key='startloader')
    p {{startloader}}
    .startloader._middle
      .mainloader._middle
</template>

<script>
import { mapGetters, mapState } from "vuex";
// let vu

/**
 * Component is described here.
 *
 */

export default {
  name: "MainLoader",
  data: function() {
    return {
      d: {}
    };
  },
  props: {
    /**  The size of the button */
    tester: {
      type: String,
      default: "#333"
    }
  },
  watch: {},
  created() {
    // vu = this
  },
  mounted() {},
  computed: {
    ...mapState(["uiloader"]),
    ...mapGetters(["text"])
  },
  methods: {
    /**  Insert text at cursor position.
     * @public */

    someact: function() {
      return "someact";
    }
  }
};
</script>

<style scoped lang="stylus">

.startloader
  width:90%;
  height:90%;
  max-height:160px;
  max-width:160px;
  border-radius:8px;
  position: fixed;
  background: rgba(30,30,30,0.6);
  z-index:9999;
  transform: rotateY(0deg)

  .mainloader

    position: absolute;
    border: 4px solid rgba(255, 255, 255, 0.96);
    border-radius: 50%;
    border-top: 4px solid #a4c121;
    width: 48px;
    height: 48px;
    animation: spin 1s ease infinite;

  @keyframes spin
    0%
      transform: rotate(0deg) scale(1.0) translateZ(90px)
    100%
      transform: rotate(360deg) scale(1.0) translateZ(90px)

.fadescale-enter-active, .fadescale-leave-active
  transition: all .4s ease;

.fadescale-enter, .fadescale-leave-to /* .fade-leave-active below version 2.1.8 */
  opacity: 0;
  transform:  rotateY(0deg) scale(0.9);
  // transform: translateY(0%) scale(.9) rotateY(90deg);

</style>

<docs>
# docs
## This da tees123t2
</docs>
