







import Component from 'vue-class-component'
import SettingsControl from "./SettingsControl.vue"
import {Mixins, Prop} from "vue-property-decorator"
import shadowValueState from './shadowValueStateMixin'

@Component({
  components: {SettingsControl}
})
export default class extends Mixins(shadowValueState('clb_cfg_open_time', 'setOpeningTime', 50)) {
  @Prop(Object) readonly door
}
