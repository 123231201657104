<template lang="pug">
  div.square
    div.square-content
      slot
</template>

<script>
  export default {
    name: "Square"
  }
</script>

<style lang="stylus">
.square
  position relative
  width 100%

  &:after
    content ""
    display block
    padding-top 100%

.square-content
  position absolute
  width 100%
  height 100%
</style>
