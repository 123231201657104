import Observable from 'zen-observable'
import {tap} from "@/libs/utils"
import TimeoutError from "@/libs/timeout-error"

const clients = new Map<string, Promise<WebSocket>>()
function getClient(url): Promise<WebSocket> {
  const client = clients.get(url)
  if (client) return client

  return makeClient(url)
}

function makeClient(url: string): Promise<WebSocket> {
  const client: WebSocket & {__timedOut?: boolean} = new WebSocket(url)

  return tap(p => clients.set(url, p),
    new Promise((res, rej) => {
      client.__timedOut = false
      client.addEventListener('open', () => {
        console.log('connection opened', url)
        if (timeout) clearTimeout(timeout)
        res(client)
      })
      client.addEventListener('error', e => {
        if (client.readyState === WebSocket.CLOSED && !client.__timedOut) rej(e)
      })
      client.addEventListener('close', () => {
        if (timeout) clearTimeout(timeout)
        clients.delete(url)
      })

      let timeout = setTimeout(() => {
        if (client.readyState === WebSocket.CONNECTING) {
          client.__timedOut = true
          client.close()
          rej(new TimeoutError())
        }
      }, 5000)
    }))
}

export function observe(url: string) {
  return new Observable<any>(observer => {
    getClient(url)
      .then((client: WebSocket & {__timedOut?: boolean}) => {
        client.addEventListener('message', e => {
          try {
            if (e.data) observer.next(JSON.parse(e.data))
          } catch (err) {
            console.log('error parsing json', err, e.data)
          }
        })
        client.addEventListener('error', err => {
          if (!client.__timedOut) observer.error(err)
        })
        client.addEventListener('close', e => {
          if (!client.__timedOut) observer.error(e)
        })
      })
      .catch(err => {
        observer.error(err)
      })
  })
}

export async function publish(url: string, payload: any) {
  const client = await getClient(url)
  client.send(JSON.stringify(payload))
}
