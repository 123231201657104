<template lang="pug">
//- #RFIDPage
f7-popup(:id="id")
  f7-page.white(:page-content='false', v-if="door && thisPet")
    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small
    .margin-top
      f7-block-title(medium)
        span {{$t('Assigns.popup-title', {pet: thisPet.name, door: door.caption})}}
        connection-state(:door="door" align="bottom")

        span(v-if="door.wscon && !RFID[door.thing_name]")  &nbsp;
          .pwloader._s
    f7-toolbar(tabbar, bottom)
      f7-link(:tab-link='`#tab-assignments-${id}`' tab-link-active)
        i.icon-settings.tritone
        label.tablabel {{$t("Assigns.assignments")}}
      f7-link(:tab-link='`#tab-settings-${id}`')
        i.icon-rfidsymbol.tritone
        label.tablabel {{$t("Assigns.rfidsettings")}}
    f7-tabs(animated)
      f7-tab.ttabs.page-content(:id="`tab-assignments-${id}`" tab-active)
        pet-assignments(:pet-id="petId" :door="door")

      f7-tab.ttabs.page-content(:id="`tab-settings-${id}`")
        .sf7-block
          f7-block-title
            i.icon-entry.tritone
            span &nbsp; {{$t("fromoutside")}}
          f7-list(no-hairlines)
            f7-list-item(radio, :title='$t("Assigns.likedoor")', name="settings-in", :value="0", :checked="settings.in === 'default'", @change="$set(selectedSettings, 'in', 'default')")
            f7-list-item(radio, :title='$t("Assigns.alwaysallowed")', name="settings-in", :value='1', :checked="settings.in === 'always'", @change="$set(selectedSettings, 'in', 'always')")
            f7-list-item(radio, :title='$t("Assigns.neverallowed")', name="settings-in", :value='2', :checked="settings.in === 'never'", @change="$set(selectedSettings, 'in', 'never')")
          f7-block-title
            i.icon-exit.tritone
            span &nbsp; {{$t("frominside")}}
          f7-list(no-hairlines)
            f7-list-item(radio, :title='$t("Assigns.likedoor")', name="settings-out", :value="0", :checked="settings.out === 'default'", @change="$set(selectedSettings, 'out', 'default')")
            f7-list-item(radio, :title='$t("Assigns.alwaysallowed")', name="settings-out", :value='1', :checked="settings.out === 'always'", @change="$set(selectedSettings, 'out', 'always')")
            f7-list-item(radio, :title='$t("Assigns.neverallowed")', name="settings-out", :value='2', :checked="settings.out === 'never'", @change="$set(selectedSettings, 'out', 'never')")
          pwButton(texter="Assigns.savechanges", loader="modifyPet", size="m", col="outline", position="center", @click="SaveRFID", :block="!hasChanged")
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import PetAssignments from "./PetAssignments"
import AssignNewChip from "./AssignNewChip"
import idx from 'idx'

export default {
  beforeCreate() {
  },
  props: ['petId', 'door', 'id'],
  components: { PetAssignments, AssignNewChip },
  data() {
    return {
      RFID_Sets: [],
      selectedSettings: {}
    };
  },

  computed: {
    ...mapState(["RFID", "pets"]),
    ...mapGetters(["text", "_doorConnectionSymbol", "assignmentRecords", "_allPets"]),

    settings() {
      const petSettings = idx(this.thisPet, _ => _.doors.find(x => x.doorID == this.door.id).data.settings)
      return {
        in: 'default',
        out: 'default',
        ...petSettings,
        ...this.selectedSettings
      }
    },

    thisPet() {
      return idx(this._allPets, _ => _[this.petId])
    },

    hasChanged() {
      return Object.entries(this.selectedSettings).length > 0
    }
  },
  methods: {
    ...mapActions(["updatePet"]),

    //! *************************************************/
    async SaveRFID() {
      const result = await this.updatePet({
        data: { id: this.thisPet.id },
        doorData: { [this.door.id]: { settings: this.settings } }
      })
      this.UIResponder(result);
    }
  }
};
</script>

<style lang="stylus">

._savesettings
  height: 0 !important;
  overflow: hidden
  transition: height 0.3s;
  &.opened
    height: 52px !important;

.link.tab-link
  background: #eee!important;
  color: #424242
  &.tab-link-active
    background: #fafafa!important;
    color: #91ac25
    i
      color: #91ac25

label.tablabel
  font-size:10px

// .ttabs
  // padding-top: 12px!important

</style>

