









import {mapState} from "vuex"

export default {
  name: "MobileAppNeeded.vue",
  computed: {
    ...mapState(['lang'])
  }
}
