import Vue from "vue"
import {Store} from 'vuex'
import {Device, get} from "@/devices/repository"
import {v4 as uuid} from "uuid"

export function registerModule(store: Store<any>, thingName: string) {
  if (store.hasModule(['doors', thingName])) return
  store.registerModule(['doors', thingName], {
    namespaced: true,
    state: () => ({...store.state.doors[thingName], desired: {}, wished: {}, mqttcon: false, wscon: false, firmware: {}}),
    getters: {
      desired(state, getter) {
        return state.desired
      },
      connected(state, getters) {
        return (state.mqttcon && state.reported.isConnected) || state.wscon
      },
      isUpdateInProgress(state, getters) {
        return ['running'].includes(state.firmware.jobStatus) ||
          (state.firmware.componentUpdates || []).some(x => ['running'].includes(x.jobStatus))
      }
    },
    mutations: {
      report(state, reported) {
        state.reported = {...state.reported, ...reported}
        state.desired = {...state.desired, ...reported}
      },

      hydrate(state, data) {
        Object.entries<any>(data).forEach(([k, v]) => {
          Vue.set(state, k, v)
        })
      },

      mqttConnected(state, flag) {
        state.mqttcon = flag
      },
      wsConnected(state, flag) {
        state.wscon = flag
      }
    },
    actions: {
      async desire({state, dispatch, commit, getters}, desired) {
        if (!Object.keys(desired).length) return

        const requestId = uuid() // TODO: problematic for v1 and stuff that doesn't return the id
        const device = get(thingName)
        if (device.fwVersion.minor < 1) {
          let mqttOnly: any = {}
          if ('pets' in desired) mqttOnly.pets = desired.pets
          if ('components' in desired) mqttOnly.components = desired.components

          delete desired.pets
          delete desired.components

          if (Object.keys(mqttOnly).length > 0) await device.mqtt.desire(mqttOnly, requestId)
        }

        await desire(device, desired, requestId)

        state.desired = {...state.desired, ...desired}
      }
    }
  })
}

async function desire(device: Device, payload: object, requestId?: string) {
  if (device.wsConnected) await device.ws.desire(payload, requestId)
  else if (device.mqttConnected) await device.mqtt.desire(payload, requestId)
}
