import store from '@/store/store'
import MQTT from '@/libs/MQTT'
import WS from '@/libs/WS'


const self = {
  INITCONNECTIONS() {
    // WS.init()
    // MQTT.init()
  },
  SEND(thing, key, value, method) {
    if (store.getters.inDemoMode) {
      if (key === 'clb_state_door_pos') {
        store.commit('setToDoor', [thing, 'reported', key, 'value', value])
        store.commit('setToDoor', [thing, 'wished', key, 'value', value])
      } else {
        store.commit('setToDoor', [thing, 'reported', key, value])
        store.commit('setToDoor', [thing, 'wished', key, value])
      }

      return
    }

    let doors = store.state.doors

    if (doors[thing] && doors[thing].wscon) {
      WS.WS_SEND(thing, key, value, method)
    } else if ((doors[thing] || {}).mqttcon) {
      MQTT.SendToMQTT(thing, key, value)
    } else {
      window.LOG.red('Neither MQTT nor Websocket connection established. Request could not be send');
    }
  }
}
export default self
