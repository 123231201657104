/* eslint-disable */
import TimeoutError from "@/libs/timeout-error"
import Vue from 'vue'
import { getPetImageUrl, uploadImage } from '@/libs/s3'
import { feedbackWithLoader, PositiveFeedback } from '@/libs/feedback'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import { deletePet, unlearnPet } from '@/devices/rfid'
import { confirmJoin, getJoinStatus, listDevices, nameDevice, startJoin } from "@/devices/zigbee"
import { get } from "@/devices/repository"
import { getImageUrl, updateImage } from "@/pets/imageCache"
import { compose, enumerate, first } from "@/libs/functional"
import { tagList } from "@/devices"
import { requestLocationAccessAuthorization } from "@/libs/permissions"
import { type as hostDeviceType } from "@/hostDevice"
import { updateNotificationToken } from "@/api"
import idx from "idx"
import {update} from '@/pets/storage'
import { setup } from "@/libs/notifications"

/**
 * Circuit breaker for mergePets
 */
let circuitClosed = true
let publishCounter = 0
let circuitDelay = undefined
const publishCounterReset = setInterval(() => publishCounter = 0, 1000)

export default {

  set(s, v) { s.commit('set', v) },
  unset(s, v) { s.commit('unset', v) },
  setToDoor(s, v) { s.commit('setToDoor', v) },
  wish(s, v) { s.commit('wish', v) },

  async scanForDevices({ commit, state, dispatch }, { thing, ct = {cancelled: false} }) {
    try {
      const result = await startJoin(thing)
      if (result !== 'pending') {
        return { text: 'ServiceAlb.unknown_error', err: true }
      }

      setZigbee('pending')
      for (let i = 0; i < 15; i++) {
        await new Promise(res => setTimeout(() => res(), 1000))
        if (ct.cancelled) {
          await abort()
          return null
        }
      }

      const checkResult = await getJoinStatus(thing)
      // Always set idle to reset state machine
      setZigbee('idle')

      // If the ZigBee controller has not returned to idle, it's state is undefined and we need to abort
      if (checkResult !== 'idle') {
        return { text: 'ServiceAlb.unknown_error', err: true }
      }

      await checkForNewDevices()

      return null
    } catch (err) {
      setZigbee('idle')

      // Don't attempt to abort if the error was a timeout. Since the door isn't responding right now, better not
      // fire additional requests at it.
      if (err instanceof TimeoutError) return { text: 'ServiceAlb.door_timeout', err: true }

      await abort()
      return { text: 'ServiceAlb.unknown_error', err: true }
    }

    async function abort() {
      setZigbee('idle')
      // await checkForNewDevices()
    }
    async function checkForNewDevices() {
      const newDevices = (await listDevices(thing))
        .filter(d => d.online === 'pending')

      commit('setZIGBEE', { thing, list: { ZigBeeNewDevices: newDevices }})
    }
    function setZigbee(to) {
      commit('setZIGBEE', { thing, list: {ZigBeeJoinAllowed: [to]} })
    }
  },

  async saveComponent({ dispatch, state, commit, getters }, { localComponentId, name }) {
    const thing = getters._settingsDoorId
    commit('loader', ['saveComponent', true])

    let result = {};
    try {
      const component = state.ZIGBEE[thing].ZigBeeNewDevices.find(x => x.localComponentId === localComponentId)
      if (!component) throw new Error("Selected component not found in new component list")

      await confirmJoin(thing, localComponentId)
      await nameDevice(thing, localComponentId, name)

      commit('setZIGBEE', { thing, list: {ZigBeeNewDevices: []} })
      result = {text: 'ServiceAlb.component_added', err: false}
    } catch (e) {
      console.error(e)
      result = {text: 'ServiceAlb.unknown_error', err: true}
    }

    commit('loader', ['saveComponent', false])
    return result
  },

  async updateComponent({ state, dispatch }, { thing, component }) {
    return feedbackWithLoader('updateComponent', async () => {
      try {
        const foundComponent = state.doors[thing].assigned_components.find(x => x.id === component.id)
        if (foundComponent) Object.assign(foundComponent, component)
        await dispatch('sendComponentsToDoor', { doorId: thing })
        return 'Component.notifications.saved.success'
      } catch (e) {
        console.error(e)
        throw 'Component.notifications.saved.generic-error'
      }
    })
  },

  async removeComponent({ dispatch, state, commit }, { component, thing }) {
    return feedbackWithLoader('removeComponent', async () => {
      try {
        commit('setAssignedComponents', [thing, state.doors[thing].assigned_components.filter(x => x.id !== component)])
        await dispatch('sendComponentsToDoor', { doorId: thing })
        return 'Component.notifications.deleted.success'
      } catch (e) {
        console.error(e)
        throw 'Component.notifications.deleted.generic-error'
      }
    })
  },

  async savePet({ dispatch, state, commit, getters }, { data, image }) {
    // TODO: ring buffer implementation
    const id = uuid()

    const pet = {
      ...data,
      id: id,
      doors: [],
      updatedAt: dayjs().unix()
    }

    return await feedbackWithLoader('addPet', async () => {
      if (image && image.startsWith('data:')) pet.petImage = await uploadImage(id, image)
      const numPets = Object.values(state.pets).length
      if (numPets < 25)
        state.pets = {...state.pets, [id]: pet}
      else {
        const oldestDeletedPet = Object.values(state.pets)
          .filter(p => p.delete)
          .sort((p1, p2) => p1.updatedAt - p2.updatedAt)
          [0]

        if (!oldestDeletedPet) {
          // What happens here? There must be one, since user's shouldn't be able to reach this far with no space
          console.error('Invariant violated. No space left to add new pet')
          throw 'NewPet.notifications.unknown-error'
        }

        Vue.delete(state.pets, oldestDeletedPet.id)
        Vue.set(state.pets, id, pet)
      }

      // await dispatch('generatePetImageUrl', pet.id)
      dispatch('sendPetsToAllDoors')

      return 'NewPet.notifications.pet-added.text'
    })
  },

  async updatePet({getters, dispatch, commit}, { data, doorData = {}, image }) {
    const pet = getters._allPets[data.id]
    pet.updatedAt = dayjs().unix()
    for (const [k, v] of Object.entries(data)) {
      Vue.set(pet, k, v)
    }
    for (const [doorId, data] of Object.entries(doorData)) {
      const entry = pet.doors.find(x => x.doorID == doorId)
      if (entry) Object.assign(entry.data, data)
    }

    return await feedbackWithLoader('modifyPet', async () => {
      if (!getters["userState/isDemo"] && image && image.startsWith('data:')) await updateImage(pet.id, image)
      dispatch('sendPetsToAllDoors')

      return 'Pet.notifications.changed.text'
    })
  },

  async deletePet({getters, dispatch, commit}, { petId }) {
    const pet = getters._allPets[petId]
    if (!pet) return PositiveFeedback('pet_deleted')

    return await feedbackWithLoader('deletePet', async () => {
      try {
        await dispatch('removePets', [pet.id])
        dispatch('sendPetsToAllDoors')

        return 'Pet.notifications.deleted.text'
      } catch (e) {
        console.error(e)
        throw 'Pet.notifications.deleted.generic-error'
      }
    })
  },

  async assignPetToExistingToken({getters, dispatch, commit}, { petId, doorId, componentId, idx }) {
    const door = Object.values(getters._allDoors).find(x => x.id === doorId)

    return await feedbackWithLoader('assignPet', async () => {
      commit('assignPetToSlot', { petId, thingName: door.thing_name, componentId, idx })
      dispatch('sendComponentsToDoor', { doorId: door.thing_name })

      return 'Assigns.notifications.pet-assigned-existing-chip.text'
    })
  },

  async unassignPet({getters, dispatch, commit}, { petId, doorId, componentId, idx }) {
    const door = Object.values(getters._allDoors).find(x => x.id === doorId)

    return await feedbackWithLoader('unassignPet', async () => {
      try {
        await unlearnPet(door.thing_name, petId, componentId, idx)

        commit('unassignPetFromComponent', { petId, doorId: door.thing_name, componentId })
        if (get(door.thing_name).fwVersion.minor === 0) {
          dispatch('sendComponentsToDoor', { doorId: door.thing_name })
        }

        await tagList(door.thing_name)
          .then(list => commit('setRFID', {thing: door.thing_name, list: {RFIDTagList: list}}))
          .catch(e => console.error('error when updating tag list', e))

        return 'Assigns.notifications.pet-unassigned.text'
      } catch (e) {
        console.error(e)
        throw 'Assigns.notifications.pet-unassigned.generic-error'
      }
    })
  },

  // TODO: extract into module, this shouldn't be an action
  async sendPetsToAllDoors({getters, state, dispatch}) {
    const doors = Object.values(getters._allDoors)
    const pets = Object.values(state.pets)

    await update(pets)

    for (const d of doors) {
      dispatch(`doors/${d.thing_name}/desire`, {
        pets: pets.map(p =>
          (p.delete ? {
              id: p.id,
              delete: true,
              updatedAt: p.updatedAt
            }
          : {
            id: p.id,
            name: p.name,
            species: p.species,
            updatedAt: p.updatedAt,
            ...(p.doors.find(x => x.doorID == d.id) || {
              data: {
                settings: { in: 'default', out: 'default' }
              }
            }).data
          }))
      })
    }
  },

  sendComponentsToDoor({getters, dispatch}, {doorId}) {
    const door = getters._allDoors[doorId]
    dispatch(`doors/${doorId}/desire`, {components: door.assigned_components})
  },

  async _askPermissionWifi({ commit }) {
    try {
      let permissionLocation = await requestLocationAccessAuthorization()
      commit('set', ['permissions', 'wifi', permissionLocation])
      return permissionLocation
    } catch (e) {
      console.error(e)
      return false
    }
  },

  _requestLocationPermission({state}) {
    return new Promise((resolve, reject) => {
      if (window.cordova) {


        let onError = err => {
          console.log(err)
          resolve(false)
        }
        let askToSwitchManually = () => {
          let confirmSwitch = window.confirm("To make the pairing easy, we recommend to turn on the location service for this app. This is needed to access to your Wifi.")
          if (confirmSwitch) {
            cordova.plugins.diagnostic.switchToSettings()
          } else {
            resolve(false)
          }
        }
        let askPermission = () => {
          window.cordova.plugins.diagnostic.requestLocationAuthorization(status => {
            console.log('requestLocationAuthorizationStatus', status)
            switch (status) {
              case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED://: "not_determined",
                askToSwitchManually()
                resolve(false)
                break
              case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS://: "denied_always",
                askToSwitchManually()
                resolve(false)
                break
              case cordova.plugins.diagnostic.permissionStatus.RESTRICTED://: "restricted",
                askToSwitchManually()
                resolve(false)
                break
              case cordova.plugins.diagnostic.permissionStatus.GRANTED://: "authorized",
                resolve(true)
                break
              case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE: // "authorized_when_in_use",
                resolve(true)
                break
            }
          }, onError, cordova.plugins.diagnostic.locationAuthorizationMode.ALWAYS)
        }
        window.cordova.plugins.diagnostic.getLocationAuthorizationStatus(function (status) {
          switch (status) {
            case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
              console.log("Permission not requested")
              askPermission()
              break
            case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
              console.log("Permission denied")
              askToSwitchManually()
              resolve(false)
              break
            case cordova.plugins.diagnostic.permissionStatus.RESTRICTED:
              console.log("Permission restricted")
              askToSwitchManually()
              resolve(false)
              break
            case cordova.plugins.diagnostic.permissionStatus.GRANTED:
              console.log("Permission granted always")
              resolve(true)
              break
            case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              console.log("Permission granted only when in use")
              resolve(true)
              break
          }
        }, onError)
      } else {
        resolve(false)
      }
    })
  },

  async _getWifiName({ state, dispatch }) {
    console.log('_getWifiName')

    if (['ios', 'android'].includes(state.device)) {
      if (!state.permissions.wifi) {
        await dispatch('_askPermissionWifi')
      }

      state.wifiName = state.permissions.wifi ? (await window.WifiWizard2.getConnectedSSID()) : '--noplugin--'
    } else {
      state.wifiName = '--noplugin--'
    }
  },

  //! *************************************************/
  async SetPushNotifications({ state, commit, dispatch }) {
    const data = await setup()
    if (!data) return

    window.LOG.green("registration event", data)
    state.fcmToken = data.registrationId
    dispatch('SaveTokenToCloud')
  },

  //! *************************************************/
  async SaveTokenToCloud({ state }) {

    if (hostDeviceType === "ios" || hostDeviceType === "android") {
      // window.LOG.log("SaveTokenToCloud");
      var nutoken = state.fcmToken
      var oldtoken = state.fcmTokenPrev
      var senddata = {
        // previousToken: oldtoken,
        deviceId: window.device.uuid,
        currentToken: nutoken,
        os: hostDeviceType
      }
      let resp = await updateNotificationToken(senddata)
      console.log(senddata, resp)
      // window.LOG.log(resp);
    } else {
      window.LOG.blue('no phone', hostDeviceType)
    }
  },

  async _zeroConfig({ commit, state, getters }) {
    window.LOG.orange("_zeroConfig", hostDeviceType)
    commit("set", ["bonjourList", {}])
    if (hostDeviceType === "ios" || hostDeviceType === "android") {
      commit("set", ["bonjourList", {}])
        if (typeof window.cordova !== "undefined" && window.cordova.plugins.zeroconf) {
          cordova.plugins.zeroconf.reInit(() => {
            cordova.plugins.zeroconf.watch("_http._tcp.", "local.", result => {
              // window.LOG.orange('_zeroConfig results:', result)
              window.LOG.orange(" - - - result", result)
              var action = result.action
              var service = result.service
              let url = service.ipv4Addresses[0]
              let name = service.name
              if (name.toLowerCase().includes("petwalk-control") && url) {
                let bonres = {
                  name: name,
                  url: url,
                  action: action,
                  assigned: false
                }
                let doors = getters._allDoors
                for (let door in doors) {
                  if (name.toLowerCase().includes(doors[door].device_serial.toLowerCase())) {
                    bonres.assigned = true
                    bonres.door = door
                    bonres.device_serial = doors[door].device_serial
                    bonres.case = doors[door].case
                  }
                }
                if (action === "resolved") {

                  window.LOG.orange("service resolved", service, bonres)
                  if (state.wifiName !== "petWALK.control.SETUP" && url === "192.168.1.1") {
                    window.LOG.orange("dont save !! resolved before switch!")
                  } else commit("set", ["bonjourList", service.name, bonres])
                }
              }
              if (action === "removed") {
                window.LOG.orange("service removed", service)
                // commit("unset", ["bonjourList", service.name]);
              }
            },
              err => { window.LOG.err("error in _zeroConfig", err) }
            )
          })
        } else {

          window.LOG.err('no cordova / plugin!!')
        }
    } else if (state.device === "desktop") {
      window.LOG.red('nofin here - only mobile!!')

    }
  },
  //! *************************************************/
  async switchToNativeSettings() {
    if (window.cordova && window.cordova.plugins.settings) {
      window.LOG.orange('openNativeSettingsTest is active')
      window.cordova.plugins.settings.open("wifi", function () {
        window.LOG.orange('opened settings')
      },
        function () {
          window.LOG.orange('failed to open settings')
        }
      )
    } else {
      window.LOG.orange('openNativeSettingsTest is not active!')
    }
  },

  async synchronizeComponentState({ commit, dispatch, state, getters }, { thing, pets, components }) {
    if (!pets && !components) return
    pets = pets || state.doors[thing].desired.pets || []
    components = components || state.doors[thing].reported.components
    if (components) {
      commit('setAssignedComponents', [thing, components])
      if (getters.doors[thing].fwVersion.minor >= 1) dispatch('updateTagListFromComponents', { thing, components })
    }
    else components = []

    const entries = mapPetShadowToApplicationState()

    await dispatch('mergePets', entries)

    function mapPetShadowToApplicationState() {
      const petMapping = _.keyBy(pets || [], id)
      const assignmentRecords = _.flatten(components.map(componentStorageToAssignmentRecords))

      const assignmentsGroupedByPet = _.groupBy(assignmentRecords, id)
      const result = Object.entries(assignmentsGroupedByPet).filter(([k, v]) => k in petMapping).map(([kp, v]) => ({
        ...toPetRecord(petMapping[kp]),
        doors: Object.entries(_.groupBy(v.map(x => x.door), x => x.doorID)).map(([k, v]) => ({
          doorID: k, components: v, data: getDoorSpecificData(petMapping[kp])
        }))
      }))

      return result.concat(unassignedPets())

      function componentStorageToAssignmentRecords(c) {
        return (c.petIds || [])
          .map(enumerate)
          .filter(compose(first, notNull))
          .map(([p, idx]) => ({
            id: p, door: { doorID: state.doors[thing].id, memIdx: idx, componentId: c.id }
          }))
      }
      function unassignedPets() {
        const assignedPets = assignmentRecords.map(id).map(x => `${x}`)
        return pets.filter(x => !assignedPets.includes(`${x.id}`)).map(p => ({
          ...toPetRecord(p),
          doors: [{ doorID: state.doors[thing].id, components: [], data: getDoorSpecificData(p) }]
        }))
      }
      function notNull(x) { return x !== null }
      function id(x) { return x.id }
      function toPetRecord(p) { return _.pick(p, ['id', 'name', 'species', 'updatedAt', 'petImage', 'delete']) }
      function getDoorSpecificData(p) { return _.pick(p, ['settings']) }
    }
  },
  updateTagListFromComponents({ commit }, { thing, components }) {
    const mappedTagList = components.map(c => {
      return {
        localComponentId: c.id,
        memIdx: c.petIds.map(enumerate).filter(([v]) => !!v).map(([_, idx]) => idx)
      }
    })
    commit('setRFID', {thing, list: {RFIDTagList: mappedTagList}})
  },

  async mergePets({ commit, state, getters, dispatch }, pets) {
    const stamps = new Set(Object.entries(state.pets).map(([k, v]) => `${k}:${v.updatedAt}`))
    commit('mergePets', pets)
    if (getters["userState/isDemo"]) return

    const newStamps = new Set(Object.entries(state.pets).map(([k, v]) => `${k}:${v.updatedAt}`))
    if (!eqSet(stamps, newStamps) && circuitClosed) {
      publishCounter++
      if (publishCounter <= 10)
        await Promise.all([
          await dispatch('sendPetsToAllDoors'),
          await update(pets)
        ])
      else {
        console.log('CIRCUIT OPENED. BLOCKING UPDATE PROPAGATION FOR 10 SECONDS')
        circuitClosed = false
        if (!circuitDelay) circuitDelay = setTimeout(() => {
          circuitClosed = true
          circuitDelay = undefined
          console.log('CIRCUIT CLOSED')
        }, 10000)
      }
    }

    for (const pet of pets) {
      dispatch('generatePetImageUrl', pet.id)
    }
  },

  async generatePetImageUrl({commit, state}, petId) {
    try {
      const signedUrl = await getPetImageUrl(petId) //'cordova' in window ? await getImageUrl(petId) : await getPetImageUrl(petId)
      commit('petUrl', [petId, signedUrl])
    } catch (e) {
      console.error(e)
    }
  },
  async removePets({ commit, state, getters }, pets) {
    await Promise.all(Object.values(getters.doors)

      // We do not send deletion requests to doors that do not have that pet assigned in the first place
      .filter(d => !!getters.assignmentRecords({ pet: pets[0], door: d.id }).length)

      // TODO: get slot for each door (but only for v1?)
      .map(d => {
        const index = d.fwVersion.minor < 1
          ? (idx(getters.assignmentRecords({ pet: pets[0], door: d.id, component: 'intern'}), _ => _[0].index))
          : undefined
        return deletePet(d.thing_name, pets[0], index)
      }))

    commit('removePets', pets)
  },

  async refreshExpiredPetImages({ commit, getters }) {
    const pets = Object.values(getters._allPets)
    const now = new Date().getTime() / 1000 | 0

    for (const pet of pets) {
      // no need to refresh dataURLs
      if (!pet.petImage || pet.petImage.startsWith('data:')) continue;

      const url = new URL(pet.petImage)
      const expiresAt = url.searchParams.get('Expires')
      // disabled. we need to check regularly for updates anyway, since other apps could update the image and
      // we need to pick that up.
      if (true || (expiresAt - now < 30)) {
        const signedUrl = await getPetImageUrl(pet.id) //'cordova' in window ? await getImageUrl(pet.id) : await getPetImageUrl(pet.id)
        commit('petUrl', [pet.id, signedUrl])
      }
    }
  }

}

function eqSet(as, bs) {
  if (as.size !== bs.size) { return false }
  for (let a of as) if (!bs.has(a)) { return false }
  return true;
}
