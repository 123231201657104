
<template lang="pug">

f7-page#NewService
  f7-navbar(title='Service', back-link='Back')

  f7-block-title.text-align-center {{$t("Services.connect_heading")}}
  f7-block.max960
    f7-row
      f7-col
        square
          div.component(@click="!isDemo && $f7router.navigate('/settings/newservice/module')")
            div.grow
              img(src="@/img/alb_symbol.jpg")
            div Petwalk ALB
      f7-col
        square
          div.component
            img(src="@/img/alexa_symbol.jpg")
            div Alexa
</template>

<script>
import { mapGetters } from "vuex"
import redirectWhenUnsetUserState from "@/libs/mixins/redirectWhenUnsetUserState"

export default {
  data: () => ({}),
  computed: {
    ...mapGetters("userState", ['isDemo'])
  },
  mixins: [redirectWhenUnsetUserState]
}
</script>

<style lang="stylus">
.component
  width 100%
  height 100%
  position relative
  box-sizing border-box
  border 1.5px solid grey
  border-radius 0.3em
  display flex
  flex-direction column
  justify-content space-between
  align-items center
  text-align center
  cursor pointer
  padding 1em
  background-color white

  &:hover
    border-color #91ac25

  .grow
    flex-grow 1
    display flex
    justify-content center
    width 100%
    img
      width 100%

img
  max-width 100%
</style>
