







































































import redirectWhenUnsetUserState from "@/libs/mixins/redirectWhenUnsetUserState"
import {Door} from '@/domain-types/door'
import Vue from "vue"
import Component from "vue-class-component"
import {Action, Getter, Mutation, State} from "vuex-class"
import FirmwareUpdatePop from "@/components/PopUp/FirmwareUpdatePop.vue"
import LocationChangerPop from "@/components/PopUp/LocationChangerPop.vue"
import SettingsBlock from '@/components/Settings/Block.vue'
import {Watch} from "vue-property-decorator"
import PetIcon from "@/components/PetIcon.vue"

@Component<DoorComponent>({
  components: {
    SettingsBlock,
    FirmwareUpdatePop,
    LocationChangerPop,
    PetIcon
  },
  mixins: [redirectWhenUnsetUserState]
})
class DoorComponent extends Vue {
  declare UIResponder

  componentId = null

  data: () => ({})

  get door(): Door { return this.doors[this.$f7route.params.id] }
  get doorPetComponentRelation() { return this._doorPetComponentRelation[this.door.thing_name] }
  get componentEntries() {
    return this.door.assigned_components.filter(c => c.type === 'petWALK_ALB').map(c => ({
      title: c.caption, link: `/settings/door/${this.door.thing_name}/components/${c.id}`, key: c.id
    }))
  }

  get firmwareVersion() {
    if (!this.door.firmware.currentVersion) return ''
    return this.door.firmware.currentVersion.split('.').slice(1).join('.')
  }

  get componentNames() {
    return Object.fromEntries(this.door.assigned_components.map(c => [c.id, c.caption]))
  }

  @Watch('door', {immediate: true}) onDoorChanged(nv) {
    if (nv) this.changeSettingsDoor(nv.thing_name)
  }

  @State RFID
  @Getter _doorConnectionSymbol
  @Getter _doorPetComponentRelation
  @Getter doors: Array<Door>

  @Action _fetchUpdateInfoFromDb
  @Mutation changeSettingsDoor

  get isDoorConnected() {
    return this.$store.getters[`doors/${this.door.thing_name}/connected`]
  }
  get isUpdateInProgress() {
    return this.$store.getters[`doors/${this.door.thing_name}/isUpdateInProgress`]
  }

  get isLocked() {
    return !this.isDoorConnected || this.isUpdateInProgress
  }

  getDbIdFromLocalCompId(localComponentId) {
    return localComponentId
  }
  async OpenFirmwarePopup() {
    await this._fetchUpdateInfoFromDb()

    if (this.door.firmware.jobStatus === 'none') {
      this.componentId = null
      this.$f7.popup.open("#FirmwareUpdatePop");
    }
  }

  async openFirmwarePopupForComponent(componentId) {
    await this._fetchUpdateInfoFromDb()

    const componentUpdateInfo = this.door.firmware.componentUpdates.find(x => x.componentId === componentId)
    if (componentUpdateInfo?.jobStatus === 'none') {
      this.componentId = componentId
      this.$f7.popup.open("#FirmwareUpdatePop");
    }
  }

  async mounted() {
    try {
      const result = await this._fetchUpdateInfoFromDb()
      if (result === false) this.UIResponder({
        text: this.$t('APIresponses.update-info.failed'),
        err: true
      })
    } catch (e) {
      this.UIResponder({
        text: this.$t('General.unknown-error'),
        err: true
      })
      console.error(e)
    }
  }
}
export default DoorComponent
