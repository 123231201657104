<template lang="pug">
  div
    f7-block-title(medium) {{header}}
    slot
      f7-list(strong, no-hairlines, v-if="items")
        f7-list-item(v-for="item in items" :header='item.header' :link='item.link', :title='item.title', :key="item.key || item.title" :disabled="!!item.disabled")
          i(:class="[item.icon, 'small']" slot='media')
</template>

<script>
  export default {
    name: "Block.vue",
    props: ['header', 'items']
  }
</script>

<style scoped>

</style>
