import store from '@/store/store'
import utils from '@/libs/utils'

import env from '@/env'
import { v4 as uuid } from "uuid"
import TimeoutError from "./timeout-error"

let devWS = env.devws

let self = {
  ...utils,

  requests: {},

  //! *************************************************
  init(thing) {
    if (thing === 'demodoor') return
    // let self = this
    if (!window.ws) window.ws = {}

    let doors = thing ? { [thing]: store.getters._allDoors[thing] } : store.getters._allDoors

    Object.entries(doors).forEach(([thing, data]) => {
      if (window.ws[thing]) {
        // store.commit('set', ['doors', thing, 'wscon', false])
        store.commit('setToDoor', [thing, 'wscon', false])
        store.commit('delete', ['RFID', thing])
        if (window.ws[thing].readyState < 2) window.ws[thing].close(1000)
        // delete window.ws[thing]
      }

      store.commit('setToDoor', [thing, 'isWsConnecting', true])

      let doorIP = doors[thing].reported.clb_state_ip
      if (!doorIP) return
      try {
        if (window.ws[thing]) {
          if (window.ws[thing].reconnectTimeout) clearTimeout(window.ws[thing].reconnectTimeout)
          if (window.ws[thing].connectTimeout) clearTimeout(window.ws[thing].connectTimeout)
        }
        if (devWS) { window.ws[thing] = new WebSocket('ws://127.0.0.1:1234') } // DEVELOPER
        else { window.ws[thing] = new WebSocket('ws://' + doorIP + ':1234') } // NORMAL

        window.ws[thing].connectTimeout = setTimeout(() => {
          store.commit('setToDoor', [thing, 'isWsConnecting', false])
        }, 5000)

        window.ws[thing].onopen = async event => {
          store.commit('setToDoor', [thing, 'wscon', true])
          store.commit('delete', ['RFID', thing])
          // AFTER OPEN WS!!

          const deviceInfoResponse = await this.WS_REQUEST(thing, 'DeviceInfo', [])
          const info = parseDeviceInfo(thing, deviceInfoResponse[0].DeviceInfo[0])
          self._updateReportedState(thing, info)
          const version = store.state.doors[thing].ws_version

          window.ws[thing].send('{"version":"' + version + '","requests":[{"function":"RFIDTagList","params":[]}]}')

          if (version.substring(0, 1) === '2') {
            window.ws[thing].send('{"version":"' + version + '","requests":[{"function":"ZigBeeListDevices","params":[]}]}')
          }
        }

        window.ws[thing].onmessage = event => {
          self.MessageController(thing, event)
          store.commit('setToDoor', [thing, 'isWsConnecting', false])
        }
        window.ws[thing].onerror = event => {
          store.commit('setToDoor', [thing, 'wscon', false])
          store.commit('delete', ['RFID', thing])

          store.commit('setToDoor', [thing, 'isWsConnecting', false])
        }
        window.ws[thing].onclose = event => {
          store.commit('setToDoor', [thing, 'wscon', false])
          store.commit('delete', ['RFID', thing])

          store.commit('setToDoor', [thing, 'isWsConnecting', false])

          if (event.code !== 1000) {
            window.ws[thing].reconnectTimeout = setTimeout(() => {
              self.init(thing)
            }, 5000)
          }
        }
      } catch (err) { LOG.err(err) }
    })
  },

  //! *************************************************
  MessageController(thing, event) {
    // LOG.orange('MessageController', event.data)

    // window.LOG.green(event);

    if (!event.data.startsWith('0{"sid":"') && event.data.length > 20) {
      let StringData = event.data
      if (event.data.startsWith('42') || event.data.startsWith('40')) {
        StringData = event.data.substring(2, event.data.length)
      }

      const { responses: msg, 'request-id': requestId, version } = JSON.parse(StringData)

      if (msg) {
        let savestate = {}

        if (requestId && requestId in this.requests) {
          this.requests[requestId](msg)
        } else {
          msg.forEach(element => {
            // window.LOG.black('key', element);

            if (element.DeviceInfo) {
              savestate = { ...savestate, ...parseDeviceInfo(thing, element.DeviceInfo[0]) }
            } else if
            (
              element.RFIDStartLearn ||
              element.RFIDStopLearn ||
              element.RFIDDelete ||
              element.RFIDDeleteAll ||
              element.RFIDTagList
            ) {
              window.LOG.log(JSON.stringify(msg))

              // ARRAY THAT SHOWS WICH RIFD SLOT FILLED
              store.commit('setRFID', { thing, list: element })

              if (element.RFIDDelete) {
                self.WS_SEND(self.whichdoortodeletechip, "RFIDTagList", "")
              }

              /*************************************************
               ZigBeeListDevices
               *************************************************/
            } else if
            (
              element.ZigBeeListDevices ||
              element.ZigBeeRemoveDevice ||
              element.ZigBeeJoinAllowed ||
              element.ZigBeeNameDevice
            ) {
              // ARRAY THAT SHOWS WICH RIFD SLOT FILLED
              window.LOG.log(JSON.stringify(msg))
              store.commit('setZIGBEE', { thing, list: element })

            } else {
              Object.keys(element).forEach(key => {
                let item = element[key]

                if (key === 'clb_state_door_pos') {
                  savestate[key] = { value: item[0].toLowerCase() }
                } else if (key === 'clb_state_error') {
                  savestate[key] = { value: item[0], message: '' }
                } else if (key === 'TimeSet') {
                  store.commit('loader', ['savetimeloader', false])
                  savestate[key] = item[0]
                } else if (['pets', 'components'].includes(key)) {
                  savestate[key] = item
                } else {
                  savestate[key] = item[0]
                }
              })
            }
          })

          self._updateReportedState(thing, savestate)
        }
      }
    }
  },

  _updateReportedState(thing, state) {
    store.dispatch('synchronizeComponentState', { components: state.components, pets: state.pets, thing })

    if (state.clb_cfg_flags !== undefined) {
      let madeFlags = self.$_makeFlags(state.clb_cfg_flags.toString())
      store.commit('updateFlagsReported', [thing, madeFlags])
    }

    store.commit('updateMQTTReported', [thing, state])
  },

  WS_REQUEST(thing, fname, params = [], method = null) {
    if (!window.ws[thing]) return Promise.reject(new Error(`Thing ${thing} does not have an active websocket!`))

    const requestId = uuid()
    const message = JSON.stringify({
      version: store.state.doors[thing].ws_version,
      requests: [{
        function: fname,
        'request-id': requestId,
        params,
        ...(method ? { method } : {})
      }]
    })

    return Promise.race([
      new Promise(resolve => {
        this.requests[requestId] = result => resolve(result)
        window.ws[thing].send(message)
      })
    ])
  },

  //! *************************************************
  WS_SEND(thing, key, value, method = null) {

    if (window.ws[thing]) {

      let _allDoors = store.getters._allDoors

      let doors = thing ? { [thing]: _allDoors[thing] } : _allDoors
      let savestate = {}
      if (key === 'clb_cfg_flags') {
        let madeFlags = self.$_makeBinFromFlags(_allDoors[thing].wishedflags)
        window.LOG.log('madeFlags', madeFlags)
        value = madeFlags
        savestate = { [key]: value }
      } else {
        savestate = { [key]: value }
      }
      // TODO IF DIFFER
      // LOG.log('savestatesavestatesavestate',savestate);
      store.commit('updateMQTTWish', [thing, savestate])

      let message = JSON.stringify({
        version: store.state.doors[thing].ws_version,
        requests: [{
          function: key,
          params: Array.isArray(value) ? value : [value],
          ...(method ? { method } : {})
        }]
      })

      window.ws[thing].send(message)
    }
  }
}
export default self

function parseDeviceInfo(thing, record) {
  const savestate = {}

  Object.keys(record).forEach(key => {
    let item = record[key]
    if (key === 'ws_version') {
      store.commit('setWebsocketApiVersion', [thing, item])
    } else if (key === 'clb_state_door_pos') {
      savestate[key] = { value: item.toLowerCase() }
    } else if (key === 'clb_state_error') {
      savestate[key] = { value: item, message: '' }
    } else {
      savestate[key] = item
    }
  })

  if (!('ws_version' in record)) store.commit('setWebsocketApiVersion', [thing, '1.0.0'])

  store.commit('updateMQTTWish', [thing, savestate])

  return savestate
}
