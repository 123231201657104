export const ifNotNull = func => value => value ? func(value) : undefined
export const fork = (...funcs) => (...args) => funcs.forEach(f => f(...args))
export const compose = (...funcs) => arg => funcs.reduce((acc, cur) => cur(acc), arg)
export const tap = cb => val => { cb(val); return val }
export const enumerate = (x, idx: number) => ([x, idx])
export const pick = (keys: string[]) => o => keys.reduce((acc, cur) => ({...acc, [cur]: o[cur]}), {})
export const except = (keys: string[]) => o => Object.fromEntries(Object.entries(o).filter(([k]) => !keys.includes(k)))

export const first = val => val[0]


export const mapValues = <T, TN>(o, fn) => Object.fromEntries<TN>(Object.entries<T>(o).map(([k, v]) => ([k, fn(v)])))
