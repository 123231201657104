import Vue from 'vue'
import Component from 'vue-class-component'
import {Watch} from "vue-property-decorator"
import {State} from "vuex-class"

export default function (shadowKey, loaderName, initialValue: any = '') {
  @Component
  class Mixin extends Vue {
    declare door
    shadowValue = initialValue
    adaption = false

    @State uiloader
    @Watch(`door.reported.${shadowKey}`, {immediate: true}) onReportedChanged(nv) {
      if (!this.isLoading) {
        if (nv === undefined) return

        // Super stupid hack. This flag is checked in the `onValueChanged` function, which should be invoked
        //  immediately after setting the shadowValue var below (because the control bound to `shadowValue` will fire
        //  the changed event). Breaking on this check prevents an infinite loop.
        this.adaption = true; setTimeout(() => this.adaption = false, 250);
        this.shadowValue = nv
      } else if (nv === this.shadowValue) {
        this.$store.commit('loader', [loaderName, false])
      }
    }

    async onValueChanged(e) {
      if (this.adaption) { this.adaption = false; return }
      if (this.shadowValue == e) return

      if (!this.uiloader[loaderName]) {
        this.$store.commit('loader', [loaderName, true])
        this.shadowValue = e
        await this.$store.dispatch(`doors/${this.door.thing_name}/desire`, {[shadowKey]: e})
      }
    }

    get isLoading() {
      return this.uiloader[loaderName]
    }

    get isDoorConnected() {
      return this.$store.getters[`doors/${this.door.thing_name}/connected`]
    }
  }
  return Mixin
}
