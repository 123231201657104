import { getPetImageUrl, uploadImage } from '@/libs/s3'
import { v4 as uuid } from 'uuid'

export const getImageUrl = async petId => {
  try {
    const fs = await openFs()
    const file = await getFileEntry(fs.root, `${petId}.jpg`)

    const url = await getPetImageUrl(petId)
    const result = await fetch(url)
    if (result.ok) {
      const data = await result.blob()
      await writeFile(file, data)
    }

    return file.toURL() + '?' + uuid()
  } catch (e) {
    return ''
  }
}

export const updateImage = async (petId, image) => {
  const blob = await uploadImage(petId, image)
  if ('requestFileSystem' in window) {
    const fs = await openFs()
    const file = await getFileEntry(fs.root, `${petId}.jpg`)
    await writeFile(file, blob)
  }
}

const openFs = () =>
  new Promise<FileSystem>((res, rej) => {
    window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, res, rej)
  })

const getFileEntry = (dirEntry, filename) =>
  new Promise<FileEntry>((res, rej) => {
    dirEntry.getFile(filename, {create: true, exclusive: false}, res, rej)
  })

const writeFile = (fileEntry: FileEntry, blob) =>
  new Promise<void>((res, rej) => {
    fileEntry.createWriter((fileWriter) => {
      fileWriter.onwriteend = res as any // Typescript dislikes this, so we convert to any. We simply drop the event anyway
      fileWriter.onerror = rej
      fileWriter.write(blob)
    })
  })
