<template lang="pug">
f7-app.noselect(:params='f7Params')
  //- f7-statusbar

  //- Main View
  f7-view.safe-areas(url='/', :main='true', :pushState="true", :master-detail-breakpoint='800')

  //- SidePanel
  DoorList
  MainLoader

  #NoInternet.shadow(v-if="!isInternet") No Internet Connection

</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import { f7App, f7Panel, f7View } from "framework7-vue";
import { reconnect } from "@/devices"

import routes from "./routes";
import env from "./env";
import dayjs from "dayjs"
import { setup } from "@/libs/notifications"
let self = null;

// Ts Desc
// @group MainApp
export default {
  beforeCreate() {
    self = this;
  },

  components: {},
  computed: {
    ...mapState(["lang", "isInternet", "bonjourList"])
  },

  watch: {
    userState: {
      deep: true,
      handler(nv, ov)
      {
        if (nv.value === "cloud") {
          self.CallAllApis();
          this.mountLifecycle()
        }
      }
    },
    bonjourList: {
      deep: true,
      handler(nv) {
        // TODO: Have a look at this
        // THIS IS FOR LOCAL DOORS AFTER IT CANNOT GET URL FROM MQTT IT LOOKS FOR CHANGES IN BONJOUR
        if (self.userState.value === "offline") {
          let isBJChanged = false;

          let bonjourList = self.$_.values(nv);

          bonjourList.forEach(bjElement => {
            if (bjElement.assigned) {
              self._allDoors.forEach(doorElement => {
                if (doorElement.device_serial === bjElement.device_serial) {
                  let thingName = bjElement.thing_name;
                  self.setToDoor([
                    thingName,
                    "wsurl",
                    "ws://" + bjElement.url + ":1234"
                  ]);
                  self.setToDoor([
                    thingName,
                    "reported",
                    "clb_state_ip",
                    bjElement.url
                  ]);
                  self.setToDoor([
                    thingName,
                    "wished",
                    "clb_state_ip",
                    bjElement.url
                  ]);
                  isBJChanged = true;
                }
              });
            }
          });

          if (isBJChanged) {
            setTimeout(() => {
              self.$connect.INITCONNECTIONS();
            }, 100);
          }
        }
      }
    },
    async isInternet(nv, ov) {
      window.LOG.log("isInternet changed", nv, ov);
      if (nv && !ov) {
        let resp = await self.$awsCognito.checkUserBeforeStartApp();
        if (!resp.err) self.CallAllApis();
      }
    },
    lang: {
      immediate: true,
      handler(nv) {
        if (nv !== 'unset') {
          this.$i18n.i18next.changeLanguage(nv);
          dayjs.locale(nv)
        }
      }
    }
  },
  created() {
    // BEFORE INIT
    // MOVE THIS TO STORE ACTIONS TODO!!

    self.set(["checkeduser", false]);
    for (let doorId in self._allDoors) {
      if (doorId !== "demodoor") {
        self.$store.commit("report", [doorId, "isConnected", false]);
        // self.$store.commit("setToDoor", [doorId, "mqttcon", false]);
        // self.$store.commit("setToDoor", [doorId, "wscon", false]);
        // self.$store.commit("setToDoor", [doorId, "isMqttConnecting", true]);
        // self.$store.commit("setToDoor", [doorId, "isWsConnecting", true]);
      }
    }

    document.addEventListener("pause", self.onPause, false);
    document.addEventListener("resume", self.onResume, false);

    let device = self.$device.android
      ? "android"
      : self.$device.ios
      ? "ios"
      : "desktop";
    self.set(["device", device]);
  },
  mounted() {
    if (self.lang === "unset") {
      let la = "en"; // DEFAULT , check if different and save in store
      if (navigator.language.toLowerCase().includes("de")) {
        la = "de";
      } else if (navigator.language.toLowerCase().includes("fr")) {
        la = "fr";
      }
      self.set(["lang", la]);
    }

    if (!self._allDoors[self.currentdoor]) {
      let nuDoor = Object.keys(self._allDoors)[0] || "demodoor";
      // window.LOG.log(nuDoor)
      self.set(["currentdoor", nuDoor]);
      self.set(["setdoor", nuDoor]);
    }

    window.addEventListener("online", () => {
      self.set(["isInternet", true])
      this.online()
      console.log('NOW ONLINE!')
    });
    window.addEventListener("offline", () => {
      self.set(["isInternet", false])
      this.offline()
      console.log('NOW OFFLINE!')
    });
    window.addEventListener("backbutton", self.handleBackButton, false);
    (async () => {
      self.$store.commit("loader", ["startloader", true]);

      let resp = await self.$awsCognito.checkUserBeforeStartApp();
      if (!resp.err) {
        await self.CallAllApis();
        this.mountLifecycle()
      }

      self.$store.commit("loader", ["startloader", false]);
    })();



    document.addEventListener("deviceready", onDeviceReady, false);
    function onDeviceReady() {
      if (window.StatusBar) {
        window.StatusBar.backgroundColorByHexString("#91ac25");
        window.StatusBar.overlaysWebView(false);
        window.StatusBar.styleBlackTranslucent();
      }
    }
  },
  data() {
    // let theme = 'auora'
    // let theme = 'md'
    // let theme = "ios";
    let theme = "auto";

    if (document.location.search.indexOf("theme=") >= 0) {
      theme = document.location.search.split("theme=")[1].split("&")[0];
    }
    return {
      reloaderTimer: null,
      refreshExpiredPetImagesLifecycle: null,
      updateInfoLifecycle: null,
      f7Params: {
        view: {
          iosDynamicNavbar: false
        },

        panel: { swipe: "right" },
        smartSelect: { closeOnSelect: true },
        popup: { closeByBackdropClick: false },

        darkTheme: true,
        theme,
        routes,
        router: false,
        id: env.bundle
      }
    };
  },
  methods: {
    // ...self.$awsCognito,
    ...mapActions(["CallAllApis", "_zeroConfig", "setToDoor", "refreshExpiredPetImages", '_fetchUpdateInfoFromDb']),
    ...mapMutations("userState", ["online", "offline"]),

    handleBackButton() {
      window.LOG.log("handleBackButton");
      if (document.querySelector(".panel-left.panel-active")) {
        return this.$f7.panel.close();
      }
      if (document.querySelector(".modal-in")) {
        return this.$f7.popover.close();
      }

      if (this.$f7.views.main.router.history.length > 1) {
        return this.$f7.views.main.router.back();
      }

      return window.navigator.app.exitApp();
    },
    onPause() {
      window.LOG.log("onPause");
    },
    async onResume() {
      await this.pwheader()
      this.mountLifecycle()
      await setup()
      await Promise.all(Object.entries(self._allDoors).map(async ([doorId, door]) => {
        if (!door.wscon || !door.mqttcon) { console.log('reconnecting', doorId); await reconnect(doorId) }
      }))
    },
    mountLifecycle() {
      if (this.refreshExpiredPetImagesLifecycle) clearInterval(this.refreshExpiredPetImagesLifecycle)
      this.refreshExpiredPetImagesLifecycle = setInterval(() => {
        this.refreshExpiredPetImages()
      }, 1000 * 60)

      if (this.updateInfoLifecycle) clearInterval(this.updateInfoLifecycle)
      this.updateInfoLifecycle = setInterval(() => {
        this._fetchUpdateInfoFromDb()
          .then(result => {
            if (result === false) this.UIResponder({
              text: this.$t('APIresponses.update-info.failed'),
              err: true
            })
          })
          .catch(err => {
            console.log(err)
            this.UIResponder({
              text: this.$t('APIresponses.update-info.failed'),
              err: true
            })
          })
      }, 1000 * 20)
    }
  }
};
</script>

<style lang="stylus">
#NoInternet
  top: 52px;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  background: #e55;
  color: #fff;
  max-width: 220px;
  /* height: 32px; */
  z-index: 9999;
  border-radius: 3px;
  padding: 3px;
  text-align: center;

</style>
