





import Component from 'vue-class-component'
import {Mixins, Prop, Watch} from "vue-property-decorator"
import shadowValueState from './shadowValueStateMixin'
import SettingsControl from "@/components/AdvancedSets/SettingsControl.vue"

@Component({
  components: {SettingsControl}
})
export default class extends Mixins(shadowValueState('clb_cfg_battery', 'setBattery')) {
  @Prop(Object) door

  switchBattery() {
    let onoff
    if (this.door.reported.clb_cfg_battery == 0) onoff = 1;
    else if (this.door.reported.clb_cfg_battery == 1) onoff = 0;
    this.onValueChanged(onoff)

  }
}
