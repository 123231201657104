import {DeviceShadowHandler} from "@/deviceShadow"
import {CommandClient} from "@/deviceWebsocket/commands"
import {JobsClient} from "@/deviceShadow/jobs"
import {Version} from "@/libs/version"

const devices: { [thingName: string]: Device | undefined } = {}

export interface Device {
  readonly mqttConnected: boolean
  readonly wsConnected: boolean
  readonly thingName: string
  readonly mqtt: DeviceShadowHandler
  readonly mqttJobs: JobsClient
  readonly ws: any
  readonly wsCommands: CommandClient
  readonly watchers: Array<() => void>

  readonly fwVersion: Version

  reconnect(): void
}

export function put(device: Device) {
  devices[device.thingName] = device
}

export function has(thingName: string): boolean
export function has(device: Device): boolean
export function has(device: string | Device): boolean {
  if (typeof device === 'string') return device in devices
  else return device.thingName in devices
}

export function get(thingName: string): Device | undefined {
  return devices[thingName]
}

export function del(thingName: string)
export function del(device: Device)
export function del(device: string | Device) {
  if (typeof device === 'string') delete devices[device]
  else delete devices[device.thingName]
}

export function all(): Array<Device> {
  return Object.values(devices)
}
