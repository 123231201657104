<template lang="pug">

//- f7-button(fill, :class="{ btnmid: position === 'center', _floatrightbtn:position === 'right' }")

.pwbutton(:class="{ btnmid: position === 'center', _floatrightbtn:position === 'right', _inlinebtn:position === 'inline' }")
    button.button._btn(
        :type="type",
        @click="handleClick",
        :class='{_grey: uiloader[loader] || col === "grey",_pwgreen: col === "green",_red: col === "red",_transparent: col === "transparent",_outline: col === "outline",_outlinered: col === "outlinered",_outlinegreen: col === "outlinegreen", _medium: size === "m",_small: size === "s",_ssmall: size === "ss",_block: block === true,"popup-open": !!dataPopup && !block}'
        :data-popup="dataPopup")
      span(v-if="!uiloader[loader]")
        slot
          span {{$t(texter)}}
      span(v-else-if="uiloader[loader]")
        span {{$t('loading')}} &nbsp;

          .pwloader._s.contrast(:class='{blackcol: col === "outline"}')
</template>
<script>
import { mapGetters, mapState } from 'vuex'
let self = null

export default {
  beforeCreate () {
    self = this
  },
  name: 'pwButton',
  data: function () {
    return {}
  },

  // created () {vu = this},
  mounted () {},

  methods: {
    handleClick (e) {
      if (!this.block && !this.uiloader[this.loader]) {
        this.$emit('click', e)
      }
    }
  },
  props: ['type', 'loader', 'texter', 'col', 'size', 'position', 'block', 'dataPopup'],
  computed: {
    // ...mapGetters(['text']),
    ...mapState(['uiloader'])
    // uiloader() {
    // return this.$store.state.uiloader;
    // },
    // buttonClass() {
    //   return {
    //     _grey: self.col === "grey",
    //     _pwgreen: self.col === "green",
    //     _red: self.col === "red",
    //     _transparent: self.col === "transparent",
    //     _medium: self.size === "m",
    //     _small: self.size === "s",
    //     _ssmall: self.size === "ss",
    //     _block: self.block === true
    //   };
    // }
  }
}
</script>
<style lang="stylus">

// pwgreen = #91ac25

$pwgreen = #91ac25
$pwgreen_light = #9cb927
$pwgreen_light_light = #a3c229

$green = #50be5e

$red = #ee5555
$red_light = #f06666
$red_light_light = #f17474

$grey = #a0afc1
$grey_light = #acb9c9
$grey_light_light = #b5c1cf

.pwbutton
  margin: 2px;
  display: inline-block;
  font-weight: 700;

.btnmid
  display: flex;
  align-items: center;
  justify-content: center;

._floatrightbtn
  float: right
  @media only screen and (max-width: 420px)
    float: inherit
._inlinebtn
  display: inline-block;

button._btn,a._btn
  transition: all .25s ease
  position: relative
  overflow: hidden

  outline: none;
  font-size: 14px;

  font-weight: 700;
  color: #fff!important;
  border:none;
  padding: 0 8px;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  cursor: pointer;

  span
    vertical-align: middle;
    display: block;
    line-height: 1rem;

  &._pwgreen
    background: $pwgreen;
    &:hover
      background: $pwgreen_light;
    &:active
      background: $pwgreen_light_light;
  &._red
    background: $red;
    &:hover
      background: $red_light;
    &:active
      background: $red_light_light;

  &._transparent
    background: transparent
    color: #424242!important;
    text-decoration:underline
    &:hover
      background: #42424224!important;

  &._grey
    background: $grey!important;
    &:hover
      background: $grey_light!important;
    &:active
      background: $grey_light_light!important;

  &._outline

    border: 2px solid #424242
    color: #424242!important
    background: #eeeeee00!important
    // &:hover
    //   background: #cccccc67!important
    //   border: 2px solid #676767
    &:active
      background: #aaaaaa67!important
      border: 2px solid #9a9a9a

  &._outlinered

    border: 2px solid #ee555500
    color: #ee5555!important
    background: #eeeeee00!important
    &:hover
      background: #cccccc11!important
      border: 2px solid #ee5555aa

    &:active
      background: #cccccc22!important
      border: 2px solid #ee5555aa

  &._outlinegreen

    border: 2px solid #ee555500
    color: $pwgreen!important
    background: #eeeeee00!important
    &:hover
      background: #cccccc11!important
      border: 2px solid $pwgreen
    &:active
      background: #cccccc22!important
      border: 2px solid $pwgreen

  &._block
    // background: #ccc!important;
    // color: #f7f7f7!important
    background: #cccccc2e !important;
    color: #cccccc !important;
    cursor: not-allowed
    border-color #cccccc2e!important;
    // border: 2px solid #ccc!important

  &._small
    height: 24px;
    line-height: 20px;
    padding: 0 10px;
    font-weight: 700;
    width: inherit;

  &._ssmall
    height: 24px;
    line-height: 24px;
    padding: 0 12px;
    font-weight: 700;
    width: inherit;
    letter-spacing:-0.5px;

  &._medium
    min-width: 260px;
    height: 42px;
    line-height: 40px;
    padding: 0 32px;
    width: inherit;
    font-size: 16px;
    max-width: 320px;
  &._mid
    margin-left:auto
    margin-right:auto

  // &._large
  //   // margin:32px;
  //   min-width: 180px;
  //   height: 48px;
  //   padding: 0 48px;
  //   line-height: 48px;
  //   font-size: 20px;
  //   width: inherit;
  //   min-width : 260px

  // &._block
  //   background: $lgrey!important;

  // &:hover
  //   opacity: .8;
  // &:active
  //   opacity: .6;

  .icon
    display: inline-block;
    margin-right: 4px;
  .innertext
    display: inline-block;
  .pwbtnload
    margin:12px

</style>
