/*

Description:
  - gets all images and sets choosen Design by folder name (=design) and format (=svg/png)
  - svg is more welcome!
Version: 2.0
Author: Wayne
Date: 2019-05-09

*/

export default {
  setDesign: function (design, end) {
    let img = {
      pwlogo: require('@/img/pw_logo.svg'),
      campreview: require('@/img/campreview.jpg'),
      settings: require('@/img/settings.png'),
      settingsandroid: require('@/img/settingsandroid.png'),
      glogo: require('@/img/glogo.png'),
      wifi: require('@/img/wifi.png'),
      timeactive: require('@/img/accs/time-active.svg'),
      // democlicker: require("@/img/democlicker.svg"),
      newcat: require('@/img/cat.svg'),
      newdog: require('@/img/dog.svg'),
      nomqtt: require('@/img/homeicons/nomqtt.svg'),
      allcon: require('@/img/homeicons/allcon.svg'),
      homeicon: require('@/img/homeicons/homeicon.svg'),
      homenocl: require('@/img/homeicons/homenocl.svg'),
      homenodr: require('@/img/homeicons/homenodr.svg'),
      redhomeicon: require('@/img/homeicons/redhomeicon.svg'),
      nowifi: require('@/img/homeicons/nowifi.svg'),
      mqtt: require('@/img/homeicons/mqtt.svg'),
      nodoorcon: require('@/img/homeicons/nodoorcon.svg'),
      nodoorcon_mqtt: require('@/img/homeicons/nodoorcon.svg'),
      cloudbroken: require('@/img/cloudbroken.svg'),
      icons: {
        SERVICE_NEST: require('@/img/icons/nest.png'),
        SERVICE_ALEXA: require('@/img/icons/alexa.png'),
        SERVICE_DPD: require('@/img/icons/dpd.png')
      },
      minidoor: require('@/img/design/' + design + '/minidoor.' + end),
      maindoor: require('@/img/design/' + design + '/maindoor.' + end),
      backpic: require('@/img/design/' + design + '/backpic.' + end),
      doorclose: require('@/img/design/' + design + '/door_closed.' + end),
      dooropen: require('@/img/design/' + design + '/door_opened.' + end),
      backwall: require('@/img/design/' + design + '/backwall.' + end),
      clouds_1_day: require('@/img/design/' + design + '/weather/clouds_1_day.' + end),
      clouds_1_night: require('@/img/design/' + design + '/weather/clouds_1_night.' + end),
      clouds_2_day: require('@/img/design/' + design + '/weather/clouds_2_day.' + end),
      clouds_2_night: require('@/img/design/' + design + '/weather/clouds_2_night.' + end),
      clouds_3_day: require('@/img/design/' + design + '/weather/clouds_3_day.' + end),
      clouds_3_night: require('@/img/design/' + design + '/weather/clouds_3_night.' + end),
      backpic_snow: require('@/img/design/' + design + '/weather/backpic_snow.' + end),
      dark_snow: require('@/img/design/' + design + '/weather/dark_snow.' + end),
      dark: require('@/img/design/' + design + '/weather/dark.' + end),
      fog: require('@/img/design/' + design + '/weather/dark.' + end),
      moon: require('@/img/design/' + design + '/weather/dark.' + end),
      rain_1: require('@/img/design/' + design + '/weather/rain_1.' + end),
      rain_2: require('@/img/design/' + design + '/weather/rain_2.' + end),
      snow_1: require('@/img/design/' + design + '/weather/snow_1.' + end),
      snow_2: require('@/img/design/' + design + '/weather/snow_2.' + end),
      sun: require('@/img/design/' + design + '/weather/sun.' + end),
      tutorial: {
        slide_1: require('@/img/tutorial/slide_1.jpg'),
        slide_2: require('@/img/tutorial/slide_2.jpg'),
        slide_3: require('@/img/tutorial/slide_3.jpg'),
        slide_4: require('@/img/tutorial/slide_4.jpg'),
        slide_5: require('@/img/tutorial/slide_5.jpg')
      },
      demo: {
        demoslide_1: require('@/img/demoslides/demoslide_1.jpg'),
        demoslide_2: require('@/img/demoslides/demoslide_2.jpg'),
        demoslide_3: require('@/img/demoslides/demoslide_3.jpg')
      },
      // dooropenclose: require("@/img/dooropenclose.svg"),

      setdemopic: {
        en: require('@/img/setdemopicen.jpg'),
        de: require('@/img/setdemopicde.jpg'),
        fr: require('@/img/setdemopicfr.jpg')
      },
      // preloader: require("@/img/preloader.png"),
      loader: require('@/img/homeicons/loader.svg'),
      lang: {
        en: require('@/img/lang/en.png'),
        de: require('@/img/lang/de.png'),
        fr: require('@/img/lang/fr.png')
      },
      storeLinks: {
        google: {
          de: require('@/img/get-google-play-de.png'),
          en: require('@/img/get-google-play-en.png'),
          fr: require('@/img/get-google-play-fr.png')
        },
        apple: {
          de: require('@/img/get-apple-de.svg'),
          en: require('@/img/get-apple-en.svg'),
          fr: require('@/img/get-apple-fr.svg')
        }
      },
      // contact: {
      //   yt: require('@/img/contact/yt.svg'),
      //   tw: require('@/img/contact/tw.svg'),
      //   fb: require('@/img/contact/fb.svg'),
      //   ig: require('@/img/contact/ig.svg')
      // },
      preset: require('@/img/presetimages.json')

    } // }, // doorframe: require('@/img/door_frame.png'), // darkpic_snow: require('@/img/dark_snow.png'), // darkpic: require('@/img/dark.png'), // cat1: require('@/img/pets/cat1.png') // pets // pets: { // new set: // icons // DESIGN!! // weather DESIGN!! // nestdemo:  require('@/img/nestdemo.mp4'),
    return img
  }

}
