<template lang="pug">
  f7-page#NewModule.theme-clear(v-if="!selectedComponent", :page-content="hasNewDevices")
    f7-navbar(back-link="Back", no-hairline, no-shadow, large-transparent, @back-click="onBackClicked")
      f7-nav-right
        f7-link(@click="onExitClicked")
          i.small.icon-close
    mobile-app-needed(v-if="!onMobile")
    template(v-else)
      f7-block.max960.full-height(v-if="state === 'idle'" class="display-flex justify-content-center align-items-center")
        div.information-container
          div
            f7-block-title {{$t("ServiceAlb.infobox.title")}}
            f7-block {{$t("ServiceAlb.infobox.description")}}
          div
            img(src="@/img/alb_symbol.jpg")
            div {{$t("ServiceAlb.symbol_image_caption")}}
          pw-button.block(v-if="_settingsDoor.wscon" col="green", texter='ServiceAlb.infobox.button_start', @click="initiateScan", :disabled="true")
          span(v-else) {{$t('ServiceAlb.infobox.no_connection')}}

      div.full-height(v-else-if="state === 'pending'")
        pw-pulse

      template(v-if="state === 'finished'")
        f7-block.max960.full-height(v-if="!hasNewDevices" class="display-flex justify-content-center align-items-center")
          div.information-container.warn
            f7-block
              f7-block-title {{$t('ServiceAlb.no_signal.title')}}
              f7-block {{$t("ServiceAlb.no_signal.description")}}
            div
              div {{$t("ServiceAlb.symbol_image_caption")}}
            pw-button.block(col="green", texter='ServiceAlb.no_signal.button_try_again', @click="initiateScan")

        template(v-else)
          f7-block.max960
            f7-block-title {{$t('ServiceAlb.new_things_heading')}}
            f7-list
              f7-list-item(v-for="device in ZIGBEE[_settingsDoorId].ZigBeeNewDevices" :title="`petWALK.access (${device.serial})`" link="#" :key="device.localComponentId" @click="selectComponent(device.localComponentId)")
  new-module(v-else, :local-component-id="selectedComponent")
</template>

<script>
  // TODO: refactor component structure: separate scanning from component list
  import { mapGetters, mapState, mapActions } from "vuex"
  import NewModule from './NewModule'
  import PwPulse from '@/components/PwPulse'
  import MobileAppNeeded from '@/pages/MobileAppNeeded'

  export default {
    name: "ScanForModules",
    data: () => ({
      state: 'idle',
      selectedComponent: null,
      ct: { cancelled: false }
    }),
    components: { MobileAppNeeded, NewModule, PwPulse },
    computed: {
      ...mapGetters(['_settingsDoor', 'onMobile']),
      ...mapState(['ZIGBEE']),
      hasNewDevices() {
        return !!(this.ZIGBEE[this._settingsDoorId] && this.ZIGBEE[this._settingsDoorId].ZigBeeNewDevices &&
          this.ZIGBEE[this._settingsDoorId].ZigBeeNewDevices.length > 0)
      }
    },
    watch: {
      ZIGBEE: {
        deep: true,
        handler(oldValue, newValue) {
          if (!newValue[this._settingsDoorId]) return
          if (!newValue[this._settingsDoorId].ZigBeeJoinAllowed) return

          if (newValue[this._settingsDoorId].ZigBeeJoinAllowed[0] === 'pending') this.state = 'pending'
          if (newValue[this._settingsDoorId].ZigBeeJoinAllowed[0] === 'idle' && this.state === 'pending') this.state = 'finished'
        }
      },
      _settingsDoor() {
        console.log(this._settingsDoor)
      }
    },
    methods: {
      ...mapActions([
        'scanForDevices'
      ]),
      selectComponent(localComponentId) {
        this.selectedComponent = localComponentId
      },
      onBackClicked() {
        this.ct.cancelled = true
      },
      onExitClicked() {
        this.onBackClicked()
        this.$f7router.back()
      },
      async initiateScan() {
        this.ct.cancelled = false
        const result = await this.scanForDevices({ thing: this._settingsDoorId, ct: this.ct })
        if (result && result.err) {
          this.UIResponder(result)
          this.state = 'idle'
        }
      },
    }
  }
</script>

<style lang="stylus" scoped>
.information-container
  width 100%
  height 80%
  box-sizing border-box
  padding 3em
  border 1.5px solid #91ac25
  border-radius 0.3em
  display flex
  flex-direction column
  justify-content space-between
  align-items center
  text-align center

.warn
  border-color: orange

.full-height
  //height calc(100% - var(--f7-page-navbar-offset,0px) + var(--f7-page-toolbar-top-offset,0px) + var(--f7-page-subnavbar-offset,0px) + var(--f7-page-searchbar-offset,0px) + var(--f7-page-content-extra-padding-top,0px))
  height 100%
  overflow hidden

.block
  width 100%
</style>
