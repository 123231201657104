<template lang="pug">

//- f7-page#Advanced(@page:afterin="beforeOpenPage()")
f7-page#Advanced(v-if="door")
  f7-navbar(:title='door.caption || "nodoor"' , back-link='Back')
    .right.padding
      connection-state.white(:door="door" monotone)

  .max960(v-if="door")

    form(autocomplete="off", onsubmit="return false;")

      f7-list(strong, media-list, no-hairlines)
        f7-list-input(:label='$t("Door.name")', type='text', placeholder='name', clear-button='', :value="newDoorName", @input="newDoorName = $event.target.value")
          i.icon-minidoor.small(slot='media')

      pw-collapse(:open="newDoorName && newDoorName.toLowerCase() !== door.caption.toLowerCase()")
        pw-button(texter="Advanced.savedoorname", loader="renameDoor", size="m", col="outline", position="center", @click="postNewNameForCloudDoor()")

    f7-block.color-red.bold.tac(inset v-if="!isDoorConnected") {{$t('General.no-active-connection-controls-disabled')}}

    f7-block-title(medium) {{$t("Advanced.time_settings")}}
    f7-list(strong, media-list, no-hairlines)

      f7-list-item.popup-open(:disabled="isLocked" link='#', data-popup='#TimePickerPop', :title='$t("Advanced.time_opensettings")',  :text='$t("Advanced.time_description")', :after="textForTime")
        i.icon-clock.smaller(slot='media')

    f7-block-title(medium) {{$t("Advanced.Advanced")}}
    f7-list(strong, media-list, no-hairlines)
      settings-list-item(:disabled="isLocked"
        :title='$t("Advanced.openingangle")',
        :description='$t("Advanced.openingangle_description")',
        :show-loader='uiloader.setDoorAngle'
        icon="angle"
        :current-value-info='` ${door.reported.clb_cfg_door_angle}/99%`')
        opening-angle(:door="door")
      settings-list-item(:disabled="isLocked"
        :title='$t("Advanced.opentime")'
        :description='$t("Advanced.opentime_description")'
        :show-loader='uiloader.setOpeningTime'
        icon="clock"
        :current-value-info='` ${door.reported.clb_cfg_open_time}s`')
        opening-time(:door="door")
      settings-list-item(:disabled="isLocked"
        :title='$t("Advanced.signalvol")'
        :description='$t("Advanced.signalvol_description")'
        :show-loader='uiloader.setSignalVolume'
        icon="volume"
        :current-value-info='` ${door.reported.clb_cfg_volume}/4`')
        signal-volume(:door="door")
      settings-list-item(:disabled="isLocked"
        :title='$t("Advanced.brightness")'
        :description='$t("Advanced.brightness_description")'
        :show-loader='uiloader.setLedBrightness'
        icon="brightness"
        :current-value-info='` ${door.reported.clb_cfg_led_brightness}/100%`')
        led-brightness(:door="door")
      opt-door-contact-list-item(:door="door" :disabled="isLocked")
      settings-list-item(:disabled="isLocked"
        v-if="deviceVersion.minor >= 1"
        :title="$t('Advanced.rest-api')"
        :description="$t('Advanced.rest-api_description', {address: `http://${door.reported.clb_state_ip}:8080`})"
        :show-loader="uiloader.setRestApi"
        icon=""
        :current-value-info="$t('Advanced.RestApi.values.' + (door.reported.clb_cfg_rest_api || 0))")
        rest-api(:door="door")
      settings-info-list-item(v-else
        :disabled="isLocked"
        :title="$t('Advanced.rest-api')"
        :description="$t('Advanced.rest-api_upgrade-notice')"
        icon="")

    f7-block-title(medium) {{$t("Advanced.Power")}}
    f7-list( strong, media-list, no-hairlines)
      settings-list-item(:disabled="isLocked"
        :title='$t("Advanced.battery")'
        :description='$t("Advanced.battery_description")'
        :show-loader='uiloader.setBattery'
        icon="battery"
        :current-value-info="$t('Advanced.Battery.values.' + door.reported.clb_cfg_battery)")
        cfg-battery(:door="door")
      settings-info-list-item(:disabled="isLocked"
        :title='$t("Advanced.statepower")'
        :description='$t("Advanced.statepower_description")'
        icon="mains"
        :value-info="$t('Advanced.StatePower.values.' + door.reported.clb_state_power)")

    template(v-if="!isDemo")
      f7-block-title(medium) {{$t("Advanced.Dangerous")}}
      f7-list(strong, media-list, no-hairlines)
        settings-info-list-item(@click="factoryReset"
          :disabled="!hasWebsocketConnection || isLocked"
          :description='$t("Advanced.factory-reset.description")'
          icon="times")
          span(slot='title').bold.color-red {{$t("Advanced.factory-reset.title")}}
        settings-info-list-item(@click="removeCloudDoorFromUser"
          :description='$t("Advanced.deletedoor_description")'
          icon="times")
          span(slot='title').bold.color-red {{$t("Advanced.deletedoor")}}

  .cont(v-else)
    .tac
      pre {{$t("nodoor")}}

  TimePickerPop
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"

import OpeningAngle from "@/components/AdvancedSets/OpeningAngle.vue"
import SignalVolume from "@/components/AdvancedSets/SignalVolume.vue"
import OpeningTime from "@/components/AdvancedSets/OpeningTime.vue"
import LedBrightness from "@/components/AdvancedSets/LedBrightness.vue"
import redirectWhenUnsetUserState from "@/libs/mixins/redirectWhenUnsetUserState"
import PwCollapse from '@/components/PwCollapse.vue'
import SettingsListItem from "./Advanced/SettingsListItem"
import SettingsInfoListItem from "./Advanced/SettingsInfoListItem"
import CfgBattery from "@/components/AdvancedSets/CfgBattery"
import OptDoorContactListItem from "./Advanced/OptDoorContactListItem"
import RestApi from './Advanced/RestApi'
import { version } from "@/libs/version"
import { factoryReset } from "@/devices/system"

let self = null
export default {
  components: {
    SettingsListItem,
    SettingsInfoListItem,
    OpeningAngle,
    SignalVolume,
    OpeningTime,
    LedBrightness,
    PwCollapse,
    CfgBattery,
    OptDoorContactListItem,
    RestApi
  },
  beforeCreate() {
    self = this
  },
  data() {
    return {
      newDoorName: ""
    }
  },
  watch: {
    "door.caption": {
      immediate: true,
      handler(nv) {
        self.newDoorName = nv
      }
    }
  },
  computed: {
    ...mapState(["uiloader", "doors"]),
    ...mapGetters(["_doorConnectionSymbol"]),
    ...mapGetters("userState", ["isDemo"]),
    thisThingName() {
      return self.$f7route.params.thing
    },
    door() {
      return this.doors[this.$f7route.params.thing]
    },
    deviceVersion() {
      return version(this.door.reported.clb_state_version)
    },
    textForTime() {
      let tz = self.door.reported
        ? parseInt(self.door.reported.clb_cfg_timezone)
        : 0
      let prefix = tz > 0 ? "+" : ""

      return (
        self.door.reported.clb_cfg_time_mode +
        "h / GMT" +
        prefix +
        tz.toString()
      )
    },
    isDoorConnected() {
      return this.$store.getters[`doors/${this.door.thing_name}/connected`]
    },
    isUpdateInProgress() {
      return this.$store.getters[`doors/${this.door.thing_name}/isUpdateInProgress`]
    },
    isLocked() {
      return !this.isDoorConnected || this.isUpdateInProgress
    },
    hasWebsocketConnection() {
      console.log(this.$store.state.doors[this.door.thing_name].wscon)
      return this.$store.state.doors[this.door.thing_name].wscon
    }
  },
  created() {},
  methods: {
    ...mapActions([
      "setToDoor",
      "_postNewNameForCloudDoor",
      "_removeCloudDoorFromUser"
    ]),

    async postNewNameForCloudDoor() {
      self.$f7.dialog.progress()
      let resp = await self._postNewNameForCloudDoor({
        deviceID: self.door.id,
        device_caption: self.newDoorName
      })
      self.UIResponder(resp)
      self.$f7.dialog.close()
    },

    factoryReset() {
      this.$f7.dialog.confirm(
        this.$t('Advanced.factory-reset.description'),
        this.$t('Advanced.factory-reset.title'),
        async () => {
          this.$f7.dialog.progress(this.$t('Advanced.factory-reset.title'))
          try {
            await factoryReset(this.thisThingName)
            this.UIResponder({
              title: "Advanced.factory-reset.title",
              text: "Advanced.factory-reset.notifications.success"
            })
          } catch (e) {
            console.error(e)
            this.UIResponder({
              title: "Advanced.factory-reset.title",
              err: true,
              text: "Advanced.factory-reset.notifications.generic-error"
            })
          }

          this.$f7.dialog.close()
        }
      )
    },

    async removeCloudDoorFromUser() {

      self.$f7.dialog.confirm(
        self.$t("Advanced.deletedoor_description"),
        self.$t("Advanced.deletedoor"),
        async () => {
          self.$f7.dialog.progress(self.$t("Advanced.deletedoor"))
          let resp = await self._removeCloudDoorFromUser(self.thisThingName)
          resp.title = "Advanced.deletedoor"
          if (!resp.err) self.$f7router.navigate("/")
          self.UIResponder(resp)
          self.$f7.dialog.close()
        }
      )
    }
  },
  mixins: [redirectWhenUnsetUserState]
}
</script>

<style scoped lang="stylus">

#Advanced
  ._setname
    height: 0 !important;
    overflow: hidden
    transition: height 0.3s;
    &.opened
      height: 52px !important;

i.small
  height 28px
</style>
