









import {Vue, Component, Prop} from '@/vue-collection'
import {observe} from '@/libs/bonjour'

@Component
export default class extends Vue {
  @Prop(String) serialToSearch
  bonjourList: any[] = []
  subscription: ZenObservable.Subscription
  error: string = null

  async mounted() {
    this.subscription = (await observe())
      .filter(e => e.ipv4Addresses[0] && e.name && e.name.toLowerCase().startsWith('petwalk-control'))
      .map(e => ({
        serial: e.name.split('-').pop(),
        url: e.ipv4Addresses[0]
      })).subscribe({
        next: (value) => {
          if (value.serial === this.serialToSearch) this.$emit('finished', value)
          this.bonjourList = [...this.bonjourList, value]
        },
        error: (errorValue) => {
          console.error(errorValue)
          this.error = errorValue
        }
      })
  }

  beforeDestroy() {
    this.subscription && this.subscription.unsubscribe()
  }
}
