









import Vue from "vue"
import Component from "vue-class-component"

@Component({
  name: 'settings-info-list-item',
  props: {
    title: String,
    description: String,
    valueInfo: String,
    icon: String,
    disabled: Boolean
  }
})
export default class extends Vue {

}
