<template lang="pug">

f7-popup#UserDataChangerPop(@popup:open="beforeOpen()")

  f7-page.white
    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small

    f7-block(v-if="user")

      transition(name='logfade', mode="out-in")

        .transhead(:key="block")
          .ceb(v-if="block === 'verification'")
            form(autocomplete="off", onsubmit="return false;")

              h3.tac {{$t("UserData.email_unverified")}}
              p.tac.bold {{user.email}}
              p.tac {{$t("UserData.email_verifywithcode")}}
              f7-list(inset, strong)
                f7-list-input(:label='$t("UserData.verificationcode")', type='text', :placeholder='$t("UserData.verificationcode")', name='verificationciode', :value="verificationcode", @input="verificationcode = $event.target.value")

              pwButton(texter="UserData.verifyemail", loader="verificationcode", size="m", col="green", position="center", @click="verifyCodeinAws()")

              span(v-if="!coderesent")
                p.tac {{$t("UserData.emailuserhasnocode")}}
                span
                  pwButton(texter="UserData.resend_code", loader="resendcode", size="ss", col="transparent", position="center", @click="reSendAWSVerificationCode()")

              span.bold(v-else-if="coderesent")
                p.tac {{$t("UserData.codeis_sent")}}

              p.tac {{$t("UserData.wrong_email")}}
              span
                pwButton(texter="UserData.change_email", size="ss", col="transparent", position="center", @click="block= 'email'")

          .ceb(v-if="block === 'email'")
            form(autocomplete="off", onsubmit="return false;")
              h3.tac {{$t("UserData.change_email")}}
              p.tac {{$t("UserData.change_emailinfo")}}

              f7-list(inset, strong)
                f7-list-input(:label='$t("UserData.newemail")', type='email', :placeholder='$t("UserData.newemail")', name='thenewemail', :value="newemail", @input="newemail = $event.target.value")

              pwButton(texter="UserData.send_code", loader="changeemail", size="m", col="green", position="center", @click="changeUserEmailInAws()")

              span(v-if="!user.email_verified")
                pwButton(texter="UserData.alreadyhavecode", loader="resendcode", size="ss", col="transparent", position="center", @click="block= 'verification'")

</template>
<script>
import { mapGetters, mapState } from "vuex";
let self;
export default {
  beforeCreate() {
    self = this;
  },
  name: "UserDataChanger",
  data: function() {
    return {
      block: "email",
      newemail: "",
      verificationcode: "",
      coderesent: false
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(["user"])
  },

  methods: {
    beforeOpen() {
      self.block =
        self.user && self.user.email_verified ? "email" : "verification";
    },

    async changeUserEmailInAws() {
      self.$store.commit("loader", ["changeemail", true]);

      try {
        let resp = await self.$awsCognito.changeUserEmailInAws(self.newemail);
        window.LOG.log(resp);
        self.UIResponder(resp);

        if (!resp.err) {
          self.codesent = true;
          self.block = "verification";
        }
      } catch (err) {
        window.LOG.err(err);
      }
      self.$store.commit("loader", ["changeemail", false]);
    },

    async verifyCodeinAws() {
      self.$store.commit("loader", ["verificationcode", true]);
      self.coderesent = false;
      try {
        let resp = await self.$awsCognito.verifyCodeinAws(
          self.verificationcode
        );
        window.LOG.log(resp);
        self.UIResponder(resp);
        if (!resp.err) {
          self.block = "email";
          self.verificationcode = "";
          self.$f7.popup.close("#UserDataChangerPop", true);
        }
      } catch (err) {
        window.LOG.err(err);
      }
      self.$store.commit("loader", ["verificationcode", false]);
    },

    async reSendAWSVerificationCode() {
      self.$store.commit("loader", ["resendcode", true]);
      try {
        let resp = await self.$awsCognito.reSendAWSVerificationCode();
        window.LOG.log(resp);
        self.UIResponder(resp);
        self.coderesent = true;
      } catch (err) {
        window.LOG.err(err);
      }
      self.$store.commit("loader", ["resendcode", false]);
    }
  }
};
</script>

<style scoped lang="stylus">



// .hide-enter-active, .hide-leave-active
//   transition opacity .3s ease
// .hide-enter, .hide-leave-to
//   opacity 0
img
  width:46px
  height:46px
  margin-right: 10px;

// .transhead
//   padding-top:26px
//   height: calc(100% - 44px);
//   position: absolute;
//   margin:auto
//   padding: 0
//   width: calc(100% - 30px);

</style>
