export default () => {
  const subscriptions = []
  return {
    push: cb => subscriptions.push(cb),
    callEachWith: (...args) =>
      subscriptions.forEach(s => {
        try {
          s(...args)
        } catch (e) {
          console.error(e)
        }
      })
  }
}
