



































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapState, mapActions } from "vuex";

const weathercodes = require("@/json/weatherstack.json");

let self: any = null;

@Component({
  beforeCreate() {
    self = this;
  },

  data() {
    return {
      homenavbar: "",
      back1: {
        "background-image": "url('" + require("@/img/backpic.svg") + "')"
      },
      back2: {
        "background-image": "url('" + require("@/img/backwall.svg") + "')"
      },

      backpic: {
        "background-image":
          "url('" + require("@/img/design/nizzle/backpic.svg") + "')"
      },

      pic_sun: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/sun.svg") + "')"
      },
      pic_snow_2: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/snow_2.svg") + "')"
      },
      pic_snow_1: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/snow_1.svg") + "')"
      },
      pic_rain_2: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/rain_2.svg") + "')"
      },
      pic_rain_1: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/rain_1.svg") + "')"
      },
      pic_moon: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/moon.svg") + "')"
      },
      pic_dark: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/dark.svg") + "')"
      },
      pic_fog: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/fog.svg") + "')"
      },
      pic_dark_snow: {
        "background-image":
          "url('" + require("@/img/design/nizzle/weather/dark_snow.svg") + "')"
      },
      pic_clouds_3_night: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/clouds_3_night.svg") +
          "')"
      },
      pic_clouds_3_day: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/clouds_3_day.svg") +
          "')"
      },
      pic_clouds_2_night: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/clouds_2_night.svg") +
          "')"
      },
      pic_clouds_2_day: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/clouds_2_day.svg") +
          "')"
      },
      pic_clouds_1_night: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/clouds_1_night.svg") +
          "')"
      },
      pic_clouds_1_day: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/clouds_1_day.svg") +
          "')"
      },
      pic_backpic_snow: {
        "background-image":
          "url('" +
          require("@/img/design/nizzle/weather/backpic_snow.svg") +
          "')"
      },

      opendoor: {
        "background-image": "url('" + require("@/img/door_opened.svg") + "')"
      },
      closeddoor: {
        "background-image": "url('" + require("@/img/door_closed.svg") + "')"
      }
    };
  },
  computed: {
    ...mapGetters(["_reported"]),

    code: () =>
      ((self._currentDoor.weather || {}).current || {}).weather_code || 113,
    isDay: () => ((self._currentDoor.weather || {}).current || {}).is_day || 1,

    weatherPics() {
      if (weathercodes) {
        let data = weathercodes[self.code].data;
        return {
          sun: data[0],
          cloud: data[1],
          rain: data[2],
          fog: data[3],
          snow: data[4],
          code: self.code
        };
      }
    }
  },
})
export default class DoorScreen extends Vue {}
