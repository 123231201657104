import {get, has} from "@/devices/repository"
import {tagList} from "@/devices/rfid"
import store from "@/store/store"

export async function listDevices(thingName: string) {
  const device = getDeviceOrThrow(thingName)
  return device.wsCommands.request('ZigBeeListDevices')
}

export async function removeDevice(thingName: string, componentId: string) {
  const device = getDeviceOrThrow(thingName)
  return device.wsCommands.request('ZigBeeRemoveDevice', [componentId])
}

export async function nameDevice(thingName: string, componentId: string, name: string) {
  const device = getDeviceOrThrow(thingName)
  return device.wsCommands.request('ZigBeeNameDevice', [componentId, name])
}

export async function startJoin(thingName: string, type = 'petWALK_ALB') {
  const device = getDeviceOrThrow(thingName)
  return (await device.wsCommands.request('ZigBeeJoinAllowed', ['start', type]))[0]
}

export async function getJoinStatus(thingName: string) {
  const device = getDeviceOrThrow(thingName)
  return (await device.wsCommands.request('ZigBeeJoinAllowed'))[0]
}

export async function confirmJoin(thingName: string, componentId: string) {
  const device = getDeviceOrThrow(thingName)
  const result = await device.wsCommands.request('ZigBeeJoinConfirm', [componentId])

  // Need to update legacy tag list after adding command. Not the ideal place, but a central one
  const list = await tagList(thingName)
  store.commit('setRFID', {thing: thingName, list: {RFIDTagList: list}})

  return result
}

function getDeviceOrThrow(thingName: string) {
  if (!has(thingName)) throw new Error(`${thingName} does not exist`)
  return get(thingName)
}
