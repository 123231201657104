













import Vue from 'vue'
import { Component } from "vue-property-decorator"
import { acknowledgeError } from "@/devices/errorHandling"

@Component
export default class extends Vue {

  acknowledgeError() {
    acknowledgeError(this._currentDoorId)
      .then(() => this.$f7.popup.close())
      .catch(err => console.error(err)) // TODO: proper error reporting
  }
}
