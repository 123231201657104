/// * eslint-disable */

// import Vue from 'vue'
// import vueresource from 'vue-resource'
// Vue.use(vueresource)
import { mapGetters, mapState, mapActions } from 'vuex'

import utils from './utils'
let self = null

const mixins = {
  install(Vue, options) {
    Vue.mixin({
      // const mixins = {
      beforeCreate() {
        self = this
        // LOG.log('mixins')
      },

      watch: {},
      computed: {
        ...mapState(["userState"]),
        ...mapGetters(["_currentDoor", "_currentDoorId", "_settingsDoor", '_settingsDoorId', '_allPets', '_allDoors', "_settingsPet", "_settingsPetId"]),
        _hasdoors: () => self.$_size(self.$store.state.doors) > 0 || self.userState.value === 'demo'
      },

      methods: {
        ...mapActions(["set", "unset"]),
        ...utils,

        UIResponder(resp) {
          this.$f7.notification
            .create({
              title: resp && resp.title ? this.$t(resp.title) : 'petWALK',
              text: resp && resp.text ? this.$t(resp.text) : '',
              closeButton: true,
              cssClass: resp && resp.err ? 'ErrorNote' : 'SuccessNote',
              closeTimeout: 3500
            })
            .open()
        },

        _TOAST(resp) {
          // vu.$notify
          // self.$f7.notification
          //   .create({
          //     title: 'Change door name',
          //     text: resp ? resp.text : '-',
          //     closeButton: true,
          //     cssClass: resp && resp.err ? 'ErrorNote' : 'SuccessNote',
          //     closeTimeout: 3500
          //   })
          //   .open()

          // group
          // text
          // type

          self.$f7.toast.create({
            text: resp ? resp.text : '-',
            position: 'top',
            closeTimeout: 2000
          }).open()
        },

        getVersion(serial) {
          version = '2'
          if (serial.substring(0, 1) === '2') {
            version = '2'
          } else if (serial.substring(1, 1) === '3') {
            if (serial.substring(3, 5) === '01') {
              version = '1'
            } else if (serial.substring(3, 5) === '02') {
              version = '2'
            }
          }
          return version
        }

      }
    })
  }
}


export default mixins
