





import Component from 'vue-class-component'
import SettingsControl from './SettingsControl.vue'
import {Mixins, Prop} from "vue-property-decorator"
import shadowValueState from './shadowValueStateMixin'
import {initDriveStart} from "@/devices/system"

@Component({
  components: {SettingsControl}
})
export default class extends Mixins(shadowValueState('clb_cfg_door_angle', 'setDoorAngle', 50)) {
  @Prop(Object) readonly door
}
