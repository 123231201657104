









import Component from 'vue-class-component'
import { Mixins, Prop } from "vue-property-decorator"
import shadowValueState from '@/components/AdvancedSets/shadowValueStateMixin'
import SettingsControl from "@/components/AdvancedSets/SettingsControl.vue"

@Component({
  components: {SettingsControl}
})
export default class extends Mixins(shadowValueState('clb_cfg_rest_api', 'setRestApi', 0)) {
  @Prop(Object) door

  switchRestApi() {
    let onoff
    if (this.door.reported.clb_cfg_rest_api == 1) onoff = 0;
    else onoff = 1;

    this.onValueChanged(onoff)
  }
}

