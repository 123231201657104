import store from '@/store/store'

export function NegativeFeedback(...args) {

  let text = args[0] || ''
  let title = args[1] || ''
  if (title) { return { text, title, err: true } }
  else { return { text, err: true } }
}

export function PositiveFeedback(...args) {
  let text = args[0] || ''
  let title = args[1] || ''
  if (title) { return { text, title, err: false } }
  else { return { text, err: false } }
}

export async function feedbackWithLoader(tag, cb) {
  let response
  store.commit('loader', [tag, true])

  try {
    const result = await cb()
    response = PositiveFeedback(result)
  } catch (e) {
    console.error(e)
    response = NegativeFeedback(e)
  } finally {
    store.commit('loader', [tag, false])
  }

  return response
}
