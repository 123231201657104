import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import * as gettersGlobal from './gettersGlobal'
import * as actionsGlobal from './actionsGlobal'
import * as apiActions from './apiActions'
import * as initialState from './initialState'
import * as mutationsGlobal from './mutationsGlobal'
import {plugin as getterReactiveCachingPlugin} from './getterReactiveCachingPlugin'
import {register} from "@/devices"
import env from "@/env"
import AWS from "aws-sdk"
import userState from "./userState"
import instrumentation from "@/store/instrumentation"

Vue.use(Vuex)

const vuexPersist = new VuexPersistence({
  storage: window.localStorage,
  key: 'pwstate',
  modules: ['doors', 'credentials', 'userState'],
  reducer: (state: any) => {
    return {
      doors: state.userState.value === 'demo' ? {} : state.doors,
      pets: state.userState.value === 'demo' ? {} : state.pets,
      // uiloader: state.uiloader,
      currentdoor: state.currentdoor,
      lang: state.lang,
      userState: state.userState,
      fcmToken: state.fcmToken,
      fcmTokenPrev: state.fcmTokenPrev,
      credentials: state.credentials,
      instrumentation: state.instrumentation
    }
  }
})

const plugins = [
  vuexPersist.plugin,
  getterReactiveCachingPlugin,
  store => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: store.state.credentials.aws.IdentityPoolId,
      Logins: store.state.credentials.aws.Logins
    })

    store.watch(s => s.credentials.aws.Logins, logins => {
      for (const k of Object.keys((AWS.config.credentials as any).params.Logins))
        if (!(k in logins)) delete (AWS.config.credentials as any).params.Logins[k]
      for (const [k, v] of Object.entries<any>(logins))
        (AWS.config.credentials as any).params.Logins[k] = v
      ;(AWS.config.credentials as any).expired = true
    }, {deep: true})

    store.watch(s => s.device, nv => {
      console.log('device changed to', nv)
    })
  },
  store => Object.entries<any>(store.state.doors).forEach(([thingName, data]) => {
    if (thingName === 'demodoor') {} else // todo: register demo door module
    register(store, thingName)
  })
]
const state = initialState.default()
const mutations = mutationsGlobal.default
const getters = Object.assign({}, gettersGlobal.default)
const actions = Object.assign({}, actionsGlobal.default, apiActions.default)


const store = new Vuex.Store({
  plugins,
  state,
  mutations,
  getters,
  actions,
  modules: {
    doors: {
      namespaced: true,
      state: {},
    },
    credentials: {
      namespaced: true,
      state: {
        aws: {
          IdentityPoolId: env.aws.iot.IdentityPoolId,
          Logins: {}
        }
      },
      getters: {
        awsCredentials(s) {
          return new AWS.CognitoIdentityCredentials({
            IdentityPoolId: s.aws.IdentityPoolId,
            Logins: s.aws.Logins
          })
        }
      },
      mutations: {
        setLogin(s, [arn, jwt]) {
          console.log('setting login', jwt)
          s.aws.Logins = { [arn]: jwt }
          // Vue.set(s.aws.Logins, arn, jwt)
        },
        clearLogin(s) {
          s.aws.Logins = {}
        }
      }
    },
    userState,
    instrumentation
  }
})

export default store
