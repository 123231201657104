<template lang="pug">
#PetAssignments
  mobile-app-needed(v-if="!onMobile")
  div(v-else-if="[AssignState.waiting].includes(assignState)")
    pw-pulse
  div.sf7-block(v-else)
    f7-block-title {{$t("Assigns.petdoorassigns")}}
    template(v-for="(comp,idx) in _doorPetComponentRelation[door.thing_name]")
      f7-block-title
        i.icon-minidoor.inline
        span {{comp.caption}}
        span.bold.color-red(v-if="!comp.online") &nbsp; (offline) &nbsp;

      f7-block(inset)
        f7-list(v-if="(comp.memIdx||{}).length > 0")
          f7-list-item(v-for="(slot) in comp.memIdx" :key="slot")
            span(slot="title", :class="{bold : slot === comp.pets[thisPet.id] }") {{$t("Assigns.slot")}} {{slot+1}}:&nbsp;
                span.petname.bold(v-if="comp.mems[slot]") {{pets[comp.mems[slot]].Name || pets[comp.mems[slot]].name}}
                span.petname(v-else) {{$t("Assigns.empty")}}

            span(slot="after", v-if="slot === comp.pets[thisPet.id] && comp.online")
              pw-button(texter="Door.removepet", loader="", size="s", col="outlinered", position="center", @click="askBeforeDeAssign(door.id,getDbIdFromLocalCompId(idx),slot)")
            span(slot="after", v-else-if="!comp.mems[slot]")
              pw-button(texter="Door.assign", loader="", size="s", col="outlinegreen", position="center", @click="askBeforeAssign(door.id,getDbIdFromLocalCompId(idx),slot)")

        f7-list(v-else-if="(comp.memIdx||{}).length === 0")
          f7-list-item(v-for="(slot,pet) in comp.pets" :key="slot")
            span(slot="title", :class="{ bold : pet === thisPet.id }") {{$t("Assigns.slot")}} {{slot+1}}:&nbsp;
                span.petname.bold(v-if="comp.mems[slot]") {{pets[comp.mems[slot]].Name || pets[comp.mems[slot]].name}}
                span.petname(v-else) {{$t("Assigns.empty")}}

            span(slot="after", v-if="pet === thisPet.id && comp.online")
              pw-button(texter="Door.removepet", loader="", size="s", col="outlinered", position="center", @click="askBeforeDeAssign(door.id,idx,slot)")

          f7-list-item(v-if="Object.keys(comp.pets).length === 0")
            span {{$t("Assigns.nopetshere")}}
              span(v-if="!comp.online")  {{$t("Assigns.andnoantenna")}}
        pw-button(texter="Assigns.assignchip", size="s", col="outlinegreen", position="inline", @click="AssignChip(comp.lcId)", :block="!comp.online || !door.wscon",
          v-if="assignmentRecords({ door: door.id, component: comp.lcId, pet: petId }).length === 0")
    f7-block(v-if="!door.wscon" inset)
      p.bold.color-red {{$t("General.ws-connection-required-prompt")}}
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import WS from '@/libs/WS'
import { startLearn } from "@/devices"
import PwPulse from "@/components/PwPulse"
import MobileAppNeeded from "@/pages/MobileAppNeeded"

const AssignState = Object.freeze({
  unset: Symbol('unset'),
  waiting: Symbol('waiting'),
  spinner: Symbol('spinner'),
  assigning: Symbol('assigning'),
  ready: Symbol('ready')
})

export default {
  props: ['petId', 'door'],
  components: { PwPulse, MobileAppNeeded },
  data: () => ({
    assignState: AssignState.unset,
    toUseCompId: "",
    watchedComp: "",
    learnMode: ""
  }),
  watch: {
    "RFID._update"() {
      let nv = this.RFID;
      if (nv._updateval === "RFIDDelete") {
        WS.WS_SEND(this.door.thing_name, "RFIDTagList", "");
      }
    }
  },

  computed: {
    ...mapState(["pets", "RFID"]),
    ...mapGetters(["_doorPetComponentRelation", "assignmentRecords", "onMobile"]),
    thisPet() {
      if (this.pets && this.pets[this.petId]) {
        return this.pets[this.petId];
      } else return undefined
    },
  },
  methods: {
    ...mapActions(["unassignPet", 'assignPetToExistingToken']),

    //! *************************************************/
    async askBeforeAssign(doorId, compId, slot) {
      const progress = this.$f7.dialog.progress();
      const response = await this.assignPetToExistingToken({ doorId, componentId: compId, petId: this.thisPet.id, idx: slot })
      progress.close()

      this.UIResponder(response);
    },

    //! *************************************************/
    async askBeforeDeAssign(doorId, compId, slot) {
      if (
        this.assignmentRecords({ door: doorId, component: compId, index: slot }).length > 0
      ) {
        await this.$f7.dialog
          .create({
            title: this.$t("Assigns.removepet"),
            text: this.$t("Assigns.confirm-description"),
            buttons: [
              {
                text: this.$t("Assigns.confirm"),
                onClick: async () => {
                  this.$f7.dialog.progress();

                  const res = await this.unassignPet({ doorId, componentId: compId, petId: this.thisPet.id, idx: slot })
                  res.title = 'Assigns.notifications.pet-unassigned.title'
                  this.UIResponder(res)

                  this.$f7.dialog.close();
                }
              },
              {
                text: this.$t("Assigns.cancel")
              }
            ],
            verticalButtons: true
          })
          .open();
      }
    },
    //! *************************************************/
    getDbIdFromLocalCompId(localComponentId) {
      return localComponentId
    },

    async AssignChip(compId) {
      if (this.RFID[this.door.thing_name]) {
        let freeslots = Array.from(Array(20), (x, i) => i);

        const tagListForComponent = this.RFID[this.door.thing_name].RFIDTagList.find(x => x.localComponentId === compId)
        this.$_removeDoubles(freeslots, tagListForComponent.memIdx)

        this.freeslot = freeslots[0];
        this.toUseCompId = compId;

        this.beforeLearnListLength = tagListForComponent.memIdx.length

        if (this.freeslot >= 0 && this.freeslot < 20) {
          try {
            this.assignState = AssignState.waiting
            const result = await startLearn(this.door.thing_name, compId, this.freeslot)
            this.assignState = AssignState.unset

            if (result === 'ok') {
              await this.assignPetToCloudDoor()
              this.UIResponder({
                title: this.$t('Assigns.notifications.chip-learned.success-title'),
                text: this.$t('Assigns.notifications.chip-learned.success-text-extern'),
                err: false
              })
            } else if (result === 'error_duplicate')
              this.resetWithError({
                title: this.$t('Assigns.notifications.learn-started.error-title'),
                text: this.$t('Assigns.notifications.learn-started.duplicate-error'),
              })
            else if (result === 'error_timeout')
              this.resetWithError({
                title: this.$t('Assigns.notifications.learn-started.error-title'),
                text: this.$t('Assigns.notifications.learn-started.timeout-error'),
              })
            else
              this.resetWithError({
                title: this.$t('Assigns.notifications.learn-started.error-title'),
                text: this.$t('Assigns.notifications.learn-started.generic-error'),
              })
          } catch (e) {
            console.error(e)
            this.resetWithError({
              title: this.$t('Assigns.notifications.learn-started.error-title'),
              text: this.$t('Assigns.notifications.learn-started.generic-error'),
            })
          }
        }
      } else {
        window.LOG.err("no door RFIDLISTED");
      }
    },

    //! *************************************************/
    async assignPetToCloudDoor() {
      let doorId = this.door.id;

      let resp = await this.assignPetToExistingToken({
        doorId,
        componentId: this.toUseCompId,
        petId: this.petId,
        idx: this.freeslot
      })

      resp.title = "Assigns.notifications.pet-assigned-existing-chip.title";
      this.UIResponder(resp);

      this.$f7.popup.close("#PetsDoorSets", true);
    },

    resetWithError(message) {
      this.assignState = AssignState.unset;
      this.UIResponder({
        ...message,
        err: true
      });
    }
  },
  mixins: [{
    computed: {
      AssignState: () => AssignState
    }
  }]
};
</script>

<style lang="stylus">

</style>

