// @ts-ignore
import utils from '@/libs/utils'
import {cache} from './getterReactiveCachingPlugin'
import * as _ from 'lodash'
import idx from 'idx'
import {Door, FeatureList} from '@/domain-types/door'
import {version} from "@/libs/version"
import {mapValues} from "@/libs/functional"

let self = utils

declare let window: any
export default {

  _allDoors: (s): Array<Door> => s.doors,
  doors: (s): { [id: string]: Door } => mapValues(s.doors, d => ({
    ...d,
    fwVersion: d.reported.clb_state_version ? version(d.reported.clb_state_version) : version('0.0.0'),
    features: (d.reported.clb_features || {}) as FeatureList
  })),
  _sortedDoorList: (s, g) => self.$_sortBy(self.$_O2A(g._allDoors), 'Caption'),

  _currentDoor: (s, g): Door => g._allDoors[g._currentDoorId],
  _currentDoorId: (s, g) => s.currentdoor,

  _settingsDoor: (s, g): Door => s.setdoor ? g._allDoors[s.setdoor] : g.door,
  _settingsDoorId: s => s.setdoor,

  _settingsPet: (s, g) => g._allPets[s.setpet],
  _settingsPetId: (s, g) => s.setpet,

  _wished: (s, g) => idx(g, _ => _._allDoors[g._currentDoorId].wished) || {},
  _reported: (s, g) => idx(g, _ => _._allDoors[g._currentDoorId].reported) || {},

  _reportedFlags: (s, g) => (g._allDoors[g._currentDoorId] || {}).reportedflags || {},
  _wishedFlags: (s, g) => (g._allDoors[g._currentDoorId] || {}).wishedflags || {},

  _allPets: s => Object.fromEntries(Object.entries<any>(s.pets).filter(([k, p]) => !p.delete)),

  onMobile: s => s.device === 'android' || s.device === 'ios',

  assignmentRecords: cache((s, g) => ({ door, component, pet, index }) =>
    Object.values(g._allPets).filter((p: any) => !pet || p.id == pet).map((p: any) =>
      (p.doors || []).filter(d => !door || d.doorID == door).map(d =>
        (d.components || [])
          .filter(c => !component || c.componentId == component)
          .filter(c => !index || c.memIdx == index)
          .map(c => ({
            door: d.doorID,
            component: c.componentId,
            index: c.memIdx,
            pet: p.id
          }))))
      .flat(2)),

  componentsById: cache(state => thing => {
    return _.keyBy(idx(state, _ => _.doors[thing].assigned_components.filter(x => x.type === 'petWALK_ALB' || x.type === 'intern')) || [], x => x.id)
  }),

  _doorConnectionSymbol: (s, g) => {
    let obj = {}
    for (let doorId in g._allDoors) {
      let door = g._allDoors[doorId]
      let doorcase = door.case
      obj[doorId] = {}
      if (doorId !== 'demodoor') {
        let isInternet = s.isInternet
        let checkws = door.wscon
        let checkmqtt = door.mqttcon
        let checkrouter = (door.reported.isConnected || door.wscon)

        if (isInternet && checkmqtt && checkrouter && checkws) obj[doorId] = {local: 'homeicon', cloud: 'allcon'}
        else if (isInternet && checkmqtt && checkrouter && !checkws) obj[doorId] = {local: 'nodoorcon', cloud: 'mqtt'}
        else if (isInternet && checkmqtt && !checkrouter && checkws) obj[doorId] = {local: 'homeicon', cloud: 'homenodr'}
        else if (isInternet && checkmqtt && !checkrouter && !checkws) obj[doorId] = {local: 'nodoorcon', cloud: 'nodoorcon'}
        else if (isInternet && !checkmqtt && !checkrouter && checkws) obj[doorId] = {local: 'homeicon', cloud: 'homenocl'}
        else if (isInternet && !checkmqtt && checkrouter && !checkws) obj[doorId] = {local: 'redhomeicon', cloud: 'nodoorcon'}
        else if (isInternet && !checkmqtt && checkrouter && checkws) obj[doorId] = {local: 'homeicon', cloud: 'homenocl'}
        else if (isInternet && !checkmqtt && !checkrouter && !checkws) obj[doorId] = {local: 'nodoorcon', cloud: 'nodoorcon'}
        else if (!isInternet && checkws) obj[doorId] = {local: 'home', cloud: 'nowifi'}
        else if (!isInternet && !checkws) obj[doorId] = {local: 'nohome', cloud: 'nowifi'}
        else obj[doorId] = { local: 'loader', cloud: 'loader' }

        obj[doorId] = obj[doorId][doorcase]
      } else if (doorId === 'demodoor') obj[doorId] = 'allcon'
    }
    return obj
  },
  // mainComponentList: s => {
  _doorPetComponentRelation: (s, g) => {
    let obj = {};
    for (let door in g._allDoors) {
      let memIdx = Object.entries<any>(idx(s, _ => _.RFID[door].RFIDTagList) || {})
        .reduce((acc, [_, v]) => ({...acc, [v.localComponentId]: v.memIdx}), {})
      console.log('current taglists', memIdx)

      obj[door] = {};
      let doorId = g._allDoors[door].id

      const componentsById = g.componentsById(door)

      for (let [lcId, component] of Object.entries<any>(componentsById)) {
        let assignments = g.assignmentRecords({ door: doorId, component: lcId }) || []

        if (!obj[door][lcId]) obj[door][lcId] = { lcId: lcId };
        obj[door][lcId].caption = component.type === 'intern' ? 'Intern' : component.caption
        obj[door][lcId].online = component.online === 'online';
        obj[door][lcId].pets = assignments.reduce((acc, cur) => ({ ...acc, [cur.pet]: cur.index }), {})
        obj[door][lcId].mems = assignments.reduce((acc, cur) => ({ ...acc, [cur.index]: cur.pet }), {})
        obj[door][lcId].memIdx = memIdx[lcId] || []
      }
    }

    return obj;
  },
}
