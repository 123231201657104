<template lang="pug">

#FlagControl(v-if="_currentDoor && _reportedFlags")
    div.topflag.sheet-open(data-sheet='.Flag_Sheet')
      flag-screen-head

    f7-sheet#Flag_Sheet.Flag_Sheet(style='--f7-sheet-bg-color: #fff;', swipe-to-close, backdrop, swipe-handler="#FlagScreenHead")
        .flag-sheet-container
          flag-screen-head
          div.flag-sheet-content
            f7-list( strong, media-list, no-hairlines).flgbtnslist
              flag-control-button-list-item(flag="INFLAG", :thingName="_currentDoorId" icon="icon-entry-small", @click="switchFlag" :disabled="!isDoorConnected")
              flag-control-button-list-item(flag="OUTFLAG", :thingName="_currentDoorId" icon="icon-exit-small", @click="switchFlag" :disabled="!isDoorConnected")
              flag-control-button-list-item(flag="ACCESSFLAG", :thingName="_currentDoorId" icon="icon-lock-small", @click="switchFlag" :disabled="!isDoorConnected")
              flag-control-button-list-item(flag="TIMEFLAG", :thingName="_currentDoorId" icon="icon-time-small", @click="switchFlag" :disabled="!isDoorConnected")
              flag-control-button-list-item(flag="DARKFLAG", :thingName="_currentDoorId" icon="icon-dark-small", @click="switchFlag" :disabled="!isDoorConnected")
              flag-control-button-list-item(flag="RAINFLAG", :thingName="_currentDoorId" icon="icon-rain-small", @click="switchFlag" :disabled="!isDoorConnected")
              f7-list-item(:disabled="!isDoorConnected || _reported.clb_state_error.value != 0")
                .flagbtn.nomover(slot='media', @click.stop="setOnOff()", :class="{_active:_reported.clb_state_opmode === 'on'}")
                  i.icon-onoff-small
                  ._preload(v-if="inchange")

            f7-list( strong, media-list, no-hairlines).flaginputlist
              flag-control-list-item(sheet=".Flag_Entry", :title="$t('Flagcontrol.Entry.title')", :flag="_reportedFlags.INFLAG" :disabled="!isDoorConnected")
                template(#text) {{$t("Flagcontrol.sensitivity")}}: {{_reported.clb_cfg_sens_out}} /100

              flag-control-list-item(sheet=".Flag_Exit", :title="$t('Flagcontrol.Exit.title')", :flag="_reportedFlags.OUTFLAG" :disabled="!isDoorConnected")
                template(#text) {{$t("Flagcontrol.sensitivity")}}: {{_reported.clb_cfg_sens_in}} /100

              flag-control-list-item(sheet=".Flag_RFID", :title="$t('Flagcontrol.Rfid.title')", :flag="_reportedFlags.ACCESSFLAG" :disabled="!isDoorConnected")
                template(#text) --

              flag-control-list-item(sheet=".Flag_Time", :title="$t('Flagcontrol.Time.title')", :flag="_reportedFlags.ACCESSFLAG" :disabled="!isDoorConnected")
                template(#after)
                  i.icon-entry-small
                  span &nbsp; {{$_getTimeFromMins(_reported.clb_cfg_time_in_on,_reported.clb_cfg_time_mode)}} - {{$_getTimeFromMins(_reported.clb_cfg_time_in_off,_reported.clb_cfg_time_mode)}}
                  br
                  i.icon-exit-small
                  span &nbsp; {{$_getTimeFromMins(_reported.clb_cfg_time_out_on,_reported.clb_cfg_time_mode)}} - {{$_getTimeFromMins(_reported.clb_cfg_time_out_off,_reported.clb_cfg_time_mode)}}


              flag-control-list-item(sheet=".Flag_Dark", :title="$t('Flagcontrol.Dark.title')", :flag="_reportedFlags.DARKFLAG" :disabled="!isDoorConnected")
                template(#text) {{$t("Flagcontrol.sensitivity")}}: {{_reported.clb_cfg_brightness}} /100

              flag-control-list-item(sheet=".Flag_Rain", :title="$t('Flagcontrol.Rain.title')", :flag="_reportedFlags.RAINFLAG" :disabled="!isDoorConnected")
                template(#text) {{$t("Flagcontrol.sensitivity")}}: {{_reported.clb_cfg_sens_rain}} /100

              flag-control-list-item(:title="$t('Flagcontrol.OnOff.title')", :flag="_reported.clb_state_opmode === 'on'" @click="setOnOff" :disabled="!isDoorConnected || _reported.clb_state_error.value != 0")
    flag-control-sheet(sheetClass="Flag_Entry", :backlinkinfo="$t('Flagcontrol.Entry.info')", icon="icon-entry-small small",
      flag="INFLAG", shadow="clb_cfg_sens_out" :thingName="_currentDoorId" @click="switchFlag")
      template(#text)
        span(v-html="$t('Flagcontrol.access-description', { direction: $t('Flagcontrol.Entry.direction'), interpolation: { escapeValue: false } })")

    flag-control-sheet(sheetClass="Flag_Exit", :backlinkinfo="$t('Flagcontrol.Exit.info')", icon="icon-exit-small small",
      flag="OUTFLAG", shadow="clb_cfg_sens_in" :thingName="_currentDoorId" @click="switchFlag")
      template(#text)
        span(v-html="$t('Flagcontrol.access-description', { direction: $t('Flagcontrol.Exit.direction'), interpolation: { escapeValue: false } })")

    flag-control-sheet(sheetClass="Flag_RFID", :backlinkinfo="$t('Flagcontrol.Rfid.info')", icon="icon-lock-small small",
      flag="ACCESSFLAG", :thingName="_currentDoorId" @click="switchFlag")
      template(#radio)
        div.tac
          span.margin-right {{$t('accsin')}}
          pw-radio.inline(:left="$t('OFF')", :right="$t('ON')", :initval="false" :valone="flagValue('reported', 'PARTIAL_RFID_INFLAG')", :valtwo="flagValue('desired', 'PARTIAL_RFID_INFLAG') !== undefined ? flagValue('desired', 'PARTIAL_RFID_INFLAG') : flagValue('reported', 'PARTIAL_RFID_INFLAG')", @click="switchFlag('PARTIAL_RFID_INFLAG')")
        div.tac
          span.margin-right {{$t('accsout')}}
          pw-radio.inline(:left="$t('OFF')", :right="$t('ON')", :initval="false" :valone="flagValue('reported', 'PARTIAL_RFID_OUTFLAG')", :valtwo="flagValue('desired', 'PARTIAL_RFID_OUTFLAG') !== undefined ? flagValue('desired', 'PARTIAL_RFID_OUTFLAG') : flagValue('reported', 'PARTIAL_RFID_OUTFLAG')", @click="switchFlag('PARTIAL_RFID_OUTFLAG')")
      template(#content)
        span
      template(#text)
        span {{$t("Flagcontrol.Rfid.description")}}

    flag-control-sheet(sheetClass="Flag_Time", :backlinkinfo="$t('Flagcontrol.Time.info')", icon="icon-time-small small",
      flag="TIMEFLAG", :thingName="_currentDoorId" @click="switchFlag" :swipe="false")
      template(#content)
        .tac
          .link.padding(@click="timeflag = 'in'", :class="{_aktive:timeflag === 'in'}")  {{$t("Flagcontrol.Time.in-to-out")}}
          span &nbsp;&nbsp;&nbsp;
          .link.padding(@click="timeflag = 'out'", :class="{_aktive:timeflag === 'out'}")  {{$t("Flagcontrol.Time.out-to-in")}}
        .tac
          i.icon-entry-small
          span &nbsp; {{$_getTimeFromMins(_reported.clb_cfg_time_in_on,_reported.clb_cfg_time_mode)}} - {{$_getTimeFromMins(_reported.clb_cfg_time_in_off,_reported.clb_cfg_time_mode)}}
          br
          i.icon-exit-small
          span &nbsp; {{$_getTimeFromMins(_reported.clb_cfg_time_out_on,_reported.clb_cfg_time_mode)}} - {{$_getTimeFromMins(_reported.clb_cfg_time_out_off,_reported.clb_cfg_time_mode)}}
        transition(name='fade', mode="out-in")
          .timeflagFader(:key='timeflag')
            span(v-if="timeflag === 'out'")
              RoundTimePicker(on="clb_cfg_time_in_on", off="clb_cfg_time_in_off", tclass="accsin", :currentDoor="_currentDoor")
            span(v-if="timeflag === 'in'")
              RoundTimePicker(on="clb_cfg_time_out_on", off="clb_cfg_time_out_off", tclass="accsout", :currentDoor="_currentDoor")
      template(#text)
        span {{$t("Flagcontrol.Time.description")}}


    flag-control-sheet(sheetClass="Flag_Dark", :backlinkinfo="$t('Flagcontrol.Dark.info')", icon="icon-dark-small small",
      flag="DARKFLAG", shadow="clb_cfg_brightness" :thingName="_currentDoorId" @click="switchFlag")
      template(#radio)
        div.tac
          span.margin-right {{$t('accsin')}}
          pw-radio.inline(:left="$t('OFF')", :right="$t('ON')", :initval="false" :valone="flagValue('reported', 'DARK_IN_AUTHFLAG')", :valtwo="flagValue('desired', 'DARK_IN_AUTHFLAG') !== undefined ? flagValue('desired', 'DARK_IN_AUTHFLAG') : flagValue('reported', 'DARK_IN_AUTHFLAG')", @click="switchFlag('DARK_IN_AUTHFLAG')")
        div.tac
          span.margin-right {{$t('accsout')}}
          pw-radio.inline(:left="$t('OFF')", :right="$t('ON')", :initval="false" :valone="flagValue('reported', 'DARK_OUT_AUTHFLAG')", :valtwo="flagValue('desired', 'DARK_OUT_AUTHFLAG') !== undefined ? flagValue('desired', 'DARK_OUT_AUTHFLAG') : flagValue('reported', 'DARK_OUT_AUTHFLAG')", @click="switchFlag('DARK_OUT_AUTHFLAG')")
      template(#text)
        span {{$t("Flagcontrol.Dark.description")}}

    flag-control-sheet(sheetClass="Flag_Rain", :backlinkinfo="$t('Flagcontrol.Rain.info')", icon="icon-rain-small small",
      flag="RAINFLAG", shadow="clb_cfg_sens_rain" :thingName="_currentDoorId" @click="switchFlag")
      template(#radio)
        div.tac
          span.margin-right {{$t('accsin')}}
          pw-radio.inline(:left="$t('OFF')", :right="$t('ON')", :initval="false" :valone="flagValue('reported', 'RAIN_IN_AUTHFLAG')", :valtwo="flagValue('desired', 'RAIN_IN_AUTHFLAG') !== undefined ? flagValue('desired', 'RAIN_IN_AUTHFLAG') : flagValue('reported', 'RAIN_IN_AUTHFLAG')", @click="switchFlag('RAIN_IN_AUTHFLAG')")
        div.tac
          span.margin-right {{$t('accsout')}}
          pw-radio.inline(:left="$t('OFF')", :right="$t('ON')", :initval="false" :valone="flagValue('reported', 'RAIN_OUT_AUTHFLAG')", :valtwo="flagValue('desired', 'RAIN_OUT_AUTHFLAG') !== undefined ? flagValue('desired', 'RAIN_OUT_AUTHFLAG') : flagValue('reported', 'RAIN_OUT_AUTHFLAG')", @click="switchFlag('RAIN_OUT_AUTHFLAG')")
      template(#text)
        span {{$t("Flagcontrol.Rain.description")}}


</template>

<script>
import FlagScreenHead from "@/components/FlagScreenHead"

import { Component, Prop, Vue } from "vue-property-decorator"
import { mapGetters, mapState, mapActions, createNamespacedHelpers } from "vuex"
import pwRadio from "@/components/pwRadio"
import RoundTimePicker from "@/components/RoundTimePicker"
import FlagControlListItem from "@/components/FlagControlListItem.vue";
import FlagControlSheet from "@/components/FlagControlSheet";
import FlagControlButtonListItem from "@/components/FlagControlButtonListItem";
import idx from "idx"

export default {

  components: { FlagControlButtonListItem, FlagControlSheet, FlagControlListItem, pwRadio, RoundTimePicker },
  data() {
    return {
      timeflag: "in",
      inchange: false
    }
  },
  watch: {
    _reported(nv, ov) {
      if (nv.clb_state_opmode === idx(this, _ => _.desired.clb_state_opmode) && this.inchange) {
        this.inchange = false;
      }
    }
  },
  computed: {
    // ...mapState([]),
    ...mapGetters(["_reported", "_reportedFlags", "_wishedFlags", "_wished"]),
    desired() {
      return this.$store.getters[`doors/${this._currentDoorId}/desired`]
    },

    isDoorConnected() {
      return this.$store.getters[`doors/${this._currentDoorId}/connected`]
    }
  },
  mounted() {},
  methods: {
    flagValue(kind, flag) {
      return idx(this._currentDoor, _ => _[kind].clb_cfg_flags[flag])
    },
    switchFlag(flag) {
      this.$store.dispatch(`doors/${this._currentDoorId}/desire`, {
        clb_cfg_flags: {...this._reportedFlags, [flag]: !this._reportedFlags[flag]}
      })
    },
    setOnOff() {
      this.inchange = true;
      let onoff = this._reported.clb_state_opmode === "off" ? "on" : "off"
      this.$store.dispatch(`doors/${this._currentDoorId}/desire`, {
        clb_state_opmode: onoff
      })
    }
  }
}
</script>

<style scoped lang="stylus">

// #FlagControl

.Flag_Sheet
  height: auto
  box-shadow: 0px 0px 18px 0 rgba(0, 0, 0, 0.2);
  padding-bottom:32px
  max-width: 840px
  left:0
  right:0
  margin: auto
  background: var(--f7-list-bg-color)

  @media screen and (max-height 600px)
    height: 100%
    padding-bottom 0

.flag-sheet-container
  display flex
  flex-direction column
  height 100%
  .flag-sheet-content
    flex-grow 1
    @media screen and (max-height 600px)
      overflow-y scroll

.autoheight
  height: auto
  max-width: 840px
  left:0
  right:0
  margin: auto

.backlink
  overflow: auto;
  margin-top: 18px
  a
    margin-left: 8px
    height: 20px;
    line-height: 20px;
    font-size: 14px!important
    font-weight: 700
    color: #424242
    font-size: 16px
  // background: #234
.backlinkinfo
  float: right
  margin-right: 8px
  font-size: 14px!important
  font-weight: 700

.spacer84
  // height: 84px
  clear:both

.flagbtn
  display: inline-block
  vertical-align: baseline;
  position: relative
  // background: white
  background: var(--f7-list-bg-color)
  padding: .5em

  line-height: 38px
  height: 24px
  width: 24px

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.7)
  border-radius: 32px
  transition: all .1s
  border: 3px solid transparent
  color: #bfbfbf
  text-align: center

  &:hover
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5)
  &:active
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3)
    background: #65656533

  &._active
    color: #91ac25
    border: 3px solid #91ac25
    i
      color: #91ac25

  i
    display: inline-block;
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    vertical-align: top;

  ._preload
    border: 3px solid #67676767
    border-top-color #91ac25
    animation: spin 1s infinite ease
    position: absolute
    border-radius: 32px
    // background #233
    top:0
    right:0
    left:0
    height: 34px
    width: 34px
    padding: 0
    margin: 0
  // .preloader i
  //   width: 32px
  //   height: 32px
  //   border: 3px solid #fff
  //   border-top: 3px solid #91ac25
  // &:active
  //   background: #fafafa
  //   box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5)

@keyframes spin
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}

.flgbtnslist
  width: 62px
  float:left
  margin:0
  .media-item
    height: 64px

.flaginputlist
  float:right
  width: calc(100% - 64px)
  margin:0
  .media-item
    height: 64px

.topflag
  overflow:auto
  bottom: 0px
  position: absolute
  max-width: 840px
  width: 100%
  margin: auto
  margin-bottom: 0px
  left: 0
  right: 0
  box-shadow: 0 3px 8px rgba(0,0,0,.2);

.timeflagFader
  // position: relative
  // margin-bottom: 24px

.link._aktive
  text-decoration: underline
.overfly
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff88
  top: 0;
  z-index: 12;
  text-align: center;
  padding: 32px 0px;
  color: #e55;
  font-weight: 700;

.inline
  display inline-block
</style>
