<template lang="pug">
.timeturner(:class="tclass")
  img(:src="$img.timeactive")
  svg.midsvg.roter(width="280" height="280" viewbox="0 0 140 140")
    circle(cx="140" cy="140" r="120" fill="none" stroke="#e7e7e7" stroke-width="40")
  svg.midsvg.roter(width="280" height="280" viewbox="0 0 140 140", :style="{transform:'rotate('+rotationStartAngle+'deg)'}")
    circle.cric(cx="140" cy="140" r="120" fill="none" stroke="#A4C121" stroke-width="40", :style="{'stroke-dashoffset':diffBetweenAngles/180*3.1415926*120}")
  svg.midsvg.msvg(:class="tclass", width="280" height="280" viewbox="0 0 140 140")
    //- circle.cric(circleXPos="120" cy="120" r="100" fill="none" stroke="#e6e6e6" stroke-width="40")
    circle(:cx="circleXPos", :cy="circleYPos", r="12" fill="#170" stroke="#fff" stroke-width="8" )
    circle.selectedCircle.nomover(:class="tclass",:cx="circleXPos", :cy="circleYPos", r="16" fill="rgba(255, 255, 255, 0.6)" stroke="#424242" stroke-width="2" )
    circle(:cx="circleXPos1", :cy="circleYPos1", r="12" fill="#e66" stroke="#fff" stroke-width="8" )
    circle.selectedCircle1.nomover(:class="tclass",:cx="circleXPos1", :cy="circleYPos1", r="16" fill="rgba(255, 255, 255, 0.6)" stroke="#424242" stroke-width="2")
    g
      text.plus(width="32", x='100', y='100',fill='white') +
      circle.plus._t1up(:class="tclass",:cx="100", :cy="90", r="16" fill="transparent" stroke="#fff" stroke-width="2", @click="startingMinutes++")
      text.plus(width="32", x='180', y='100',fill='white') +
      circle.plus._t1dw(:class="tclass",:cx="180", :cy="90", r="16" fill="transparent" stroke="#fff" stroke-width="2", @click="endingMinutes++")
      text.plus(width="32", x='100', y='198',fill='white') -
      circle.plus._t2up(:class="tclass",:cx="100", :cy="190", r="16" fill="transparent" stroke="#fff" stroke-width="2", @click="startingMinutes--")
      text.plus(width="32", x='180', y='198',fill='white') -
      circle.plus._t2dw(:class="tclass",:cx="180", :cy="190", r="16" fill="transparent" stroke="#fff" stroke-width="2", @click="endingMinutes--")
      text.timetext._bold(x='140', y='135',fill='white')  {{$t(tclass)}}
      text.time._bold(x='100', y='160',width="60" fill='white') {{$_getTimeFromMins(startingMinutes,currentDoor.reported.clb_cfg_time_mode)}}
      text.time._bold(x='180', y='160',width="60" fill='white') {{$_getTimeFromMins(endingMinutes,currentDoor.reported.clb_cfg_time_mode)}}
    //- for debuging:
    //- text.infg(width="32", x='80', y='128',fill='white') {{startingMinutes}}/{{endingMinutes}}
    //- text.infg(width="32", x='80', y='198',fill='white') {{rotationStartAngle}}
    //- text.infg(width="32", x='130', y='168',fill='white') {{circleYPos}}
    //- text.infg(width="32", x='180', y='198',fill='white') {{rotationEndAngle}}
  .isCurrentlyChangingscreen(v-if="isCurrentlyChanging")
    .p_center
      .pwloader._l
</template>

<script>

// 1440 mins split to 5 min per step = 1440/5 = 288  => to degree: 360 /288
let SPLITTER = 360 / 288;
let OUTER_RADIUS = 140;

export default {
  name: "RoundTimePicker",
  data() {
    return {
      touch: false,
      touch1: false,

      touchstart: 0,
      touchstartX: 0,

      touchstartdiff: 0,
      touchstartdiffX: 0,

      circleXPos: 20,
      circleYPos: 140,
      circleXPos1: 260,
      circleYPos1: 140,

      rotationStartAngle: 180,
      rotationEndAngle: 180,

      startingMinutes: 0,
      endingMinutes: 0,

      waitForActionTimer: null,
      midpoint: {},
      layer: {},
      isCurrentlyChanging: false
    };
  },
  props: {
    on: {
      type: String,
      required: true
    },
    off: {
      type: String,
      required: true
    },
    tclass: {
      type: String,
      required: true
    },
    currentDoor: {
      type: Object,
      required: true
    }
  },
  watch: {
    currentDoor(nv) {
      !!nv && this.initTurtimer();
    },
    endingMinutes(nv) {
      nv < 0 && (this.endingMinutes = 0);
      nv >= 1440 && (this.endingMinutes = 0);
    },
    startingMinutes(nv) {
      nv < 0 && (this.startingMinutes = 0);
      nv >= 1440 && (this.startingMinutes = 0);
    },
    timeoff(nv) {
      if (nv !== (this.currentDoor || {}).wished[this.off]) {
        this.isCurrentlyChanging = false;
        this.calculateNewValues((((nv / 1440) * 360 - 90) / 180) * Math.PI, 2);
      }
    },
    timeon(nv) {
      if (nv !== (this.currentDoor || {}).wished[this.on]) {
        this.isCurrentlyChanging = false;
        this.calculateNewValues((((nv / 1440) * 360 - 90) / 180) * Math.PI, 1);
      }
    },
    tclass() {}
  },
  computed: {
    timeoff() {
      return parseInt((this.currentDoor || {}).reported[this.off]);
    },
    timeon() {
      return parseInt((this.currentDoor || {}).reported[this.on]);
    },

    diffBetweenAngles() {
      let ar = 0;
      if (140 - this.circleYPos === 0) {
        if (140 - this.circleXPos > 0) {
          ar = 180 + 180;
        } else {
          ar = 180;
        }
      } else if (140 - this.circleYPos <= 0) {
        ar =
          (-Math.atan((140 - this.circleXPos) / (140 - this.circleYPos)) / Math.PI) * 180 +
          180 +
          90;
      } else {
        ar =
          (-Math.atan((140 - this.circleXPos) / (140 - this.circleYPos)) / Math.PI) * 180 + 90;
      }
      let ar1 = 0;
      if (140 - this.circleYPos1 === 0) {
        if (140 - this.circleXPos1 > 0) {
          ar1 = 180 + 180;
        } else {
          ar1 = 180;
        }
      } else if (140 - this.circleYPos1 < 0) {
        ar1 =
          (-Math.atan((140 - this.circleXPos1) / (140 - this.circleYPos1)) / Math.PI) * 180 +
          180 +
          90;
      } else {
        ar1 =
          (-Math.atan((140 - this.circleXPos1) / (140 - this.circleYPos1)) / Math.PI) * 180 +
          90;
      }

      let diff = ar - ar1;

      if (diff < 0) diff = diff - 360;
      else if (diff === 0) diff = 0;
      return diff;
    }
  },
  mounted() {
    this.initTurtimer();
  },
  methods: {
    starter1(th) {
      this.$set(this, "touchstart", th.layerY);
      this.$set(this, "touchstartX", th.layerX);
      this.$set(this, "touchstartdiffX", 0);
      this.touch1 = true;
      this.disableScroll();
      // mover1(th);
    },

    starter(th) {
      this.$set(this, "touchstart", th.layerY);
      this.$set(this, "touchstartX", th.layerX);
      this.$set(this, "touchstartdiffX", 0);
      this.touch = true;
      this.disableScroll();
    },

    mover1(th) {
      if (this.touch1) {
        let deggi = Math.atan((OUTER_RADIUS - this.layer.y) / (OUTER_RADIUS - this.layer.x));
        if (OUTER_RADIUS - this.layer.x < 0) {
          deggi += Math.PI;
        }
        deggi =
          (Math.round(((deggi / Math.PI) * 180) / SPLITTER) *
            SPLITTER *
            Math.PI) /
          180;
        if ((deggi / Math.PI) * 180 === -90) {
          deggi = ((-90 + 0) * Math.PI) / 180;
        }
        if ((deggi / Math.PI) * 180 === 270) {
          deggi = ((270 - 0) * Math.PI) / 180;
        }

        this.calculateNewValues(deggi, 2);

      }
    },

    mover(th, device) {
      if (this.touch) {
        let deggi = Math.atan((OUTER_RADIUS - this.layer.y) / (OUTER_RADIUS - this.layer.x));
        if (OUTER_RADIUS - this.layer.x < 0) {
          deggi += Math.PI;
        }
        deggi =
          (Math.round(((deggi / Math.PI) * 180) / SPLITTER) *
            SPLITTER *
            Math.PI) /
          180;

        if ((deggi / Math.PI) * 180 === -90) {
          deggi = ((-90 + 0) * Math.PI) / 180;
        }
        if ((deggi / Math.PI) * 180 === 270) {
          deggi = ((270 - 0) * Math.PI) / 180;
        }
        this.calculateNewValues(deggi, 1);

      }
    },

    ender() {
      this.touch = false;
      this.touch1 = false;
      this.$set(this, "touchstartdiff", 0);
      this.$set(this, "touchstartdiffX", 0);
      this.enableScroll();
      this.sendValuesToWs();
    },

    disableScroll() {
      function preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      }
      function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
          preventDefault(e);
          return false;
        }
      }
      if (window.addEventListener) {
        window.addEventListener("DOMMouseScroll", preventDefault, false);
      }
      window.onwheel = preventDefault; // modern standard
      window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
      window.ontouchmove = preventDefault; // mobile
      document.onkeydown = preventDefaultForScrollKeys;
    },

    enableScroll() {
      function preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      }

      function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
          preventDefault(e);
          return false;
        }
      }
      if (window.removeEventListener) {
        window.removeEventListener("DOMMouseScroll", preventDefault, false);
      }
      window.onmousewheel = document.onmousewheel = null;
      window.onwheel = null;
      window.ontouchmove = null;
      document.onkeydown = null;
    },

    initTurtimer() {
      let self = this;
      let tclass = self.tclass;
      // LOG.log("initTurtimer", self.tclass);

      // MOUSE
      self.$$(".timeturner." + tclass).mousemove(th => {
        self.layer = { x: th.offsetX, y: th.offsetY };
        self.mover(th);
        self.mover1(th);
      });

      self.$$(".timeturner." + tclass).mouseup(th => {
        self.ender();
      });
      self.$$(".timeturner." + tclass).mouseleave(th => {
        self.ender();
      });

      self.$$(".selectedCircle1." + tclass).mousedown(th => {
        self.starter1(th);
        var rect = self.$$(".timeturner." + tclass)[0].getBoundingClientRect();
        self.midpoint = { y: rect.y, x: rect.x };
        // LOG.log(th);
      });

      self.$$(".selectedCircle." + tclass).mousedown(th => {
        self.starter(th);
        var rect = self.$$(".timeturner." + tclass)[0].getBoundingClientRect();
        self.midpoint = { y: rect.y, x: rect.x };
      });

      // TOUCHES

      self.$$(".timeturner." + tclass).touchmove(th => {
        var rect = self.$$(".timeturner." + tclass)[0].getBoundingClientRect();
        self.midpoint = { y: rect.y, x: rect.x };
        self.layer = {
          x: th.touches[0].pageX - self.midpoint.x,
          y: th.touches[0].pageY - self.midpoint.y
        };
        self.mover(th);
        self.mover1(th);
      });

      self.$$(".timeturner." + tclass).touchend(th => {
        self.ender();
      });

      self.$$(".selectedCircle1." + tclass).touchstart(th => {
        self.starter1(th);
        var rect = self.$$(".timeturner." + tclass)[0].getBoundingClientRect();
        self.midpoint = { y: rect.y, x: rect.x };
      });

      self.$$(".selectedCircle." + tclass).touchstart(th => {
        self.starter(th);
        var rect = self.$$(".timeturner." + tclass)[0].getBoundingClientRect();
        self.midpoint = { y: rect.y, x: rect.x };
      });

      self.$$(".selectedCircle." + tclass).touchmove(ev => {
        ev.preventDefault();
      });
      self.$$(".selectedCircle1." + tclass).touchmove(ev => {
        ev.preventDefault();
      });

      self.calculateNewValues(
        (((self.timeon / 1440) * 360 - 90) / 180) * Math.PI,
        1
      );
      self.calculateNewValues(
        (((self.timeoff / 1440) * 360 - 90) / 180) * Math.PI,
        2
      );

      var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
    },

    sendValuesToWs() {
      clearTimeout(this.waitForActionTimer);
      this.waitForActionTimer = setTimeout(() => {
        // this.isCurrentlyChanging = true
        this.startingMinutes = Math.round(this.startingMinutes);
        this.endingMinutes = Math.round(this.endingMinutes);
        if (this.startingMinutes !== parseInt(this.currentDoor.reported[this.on])) {
          this.$store.dispatch(`doors/${this.currentDoor.thing_name}/desire`, {
            [this.on]: this.startingMinutes
          })
        }
        if (this.endingMinutes !== parseInt(this.currentDoor.reported[this.off])) {
          this.$store.dispatch(`doors/${this.currentDoor.thing_name}/desire`, {
            [this.off]: this.endingMinutes
          })
        }
      }, 500);
    },

    calculateNewValues(deggi, which) {
      let minutesPerDay = 1440;
      let startingMinutes, endingMinutes;
      let circleYPos = OUTER_RADIUS + 120 * -Math.sin(deggi);
      let circleXPos = OUTER_RADIUS + 120 * -Math.cos(deggi);
      let rotationStartAngle = (deggi / Math.PI) * 180 + 180;
      let rotationEndAngle = -((deggi / Math.PI) * 180 - this.rotationStartAngle) + 180;
      if (which === 1) {
        this.rotationEndAngle += rotationStartAngle - this.rotationStartAngle;
        this.rotationStartAngle = rotationStartAngle;
        this.startingMinutes = ((this.rotationStartAngle - 90) / 360) * minutesPerDay;
        this.circleXPos = circleXPos;
        this.circleYPos = circleYPos;
      } else if (which === 2) {
        this.rotationEndAngle = rotationEndAngle;
        this.endingMinutes =
          ((-rotationEndAngle + 360 + this.rotationStartAngle - 90) / 360) * minutesPerDay;
        this.startingMinutes = ((this.rotationStartAngle - 90) / 360) * minutesPerDay;
        this.circleXPos1 = circleXPos;
        this.circleYPos1 = circleYPos;
      }
      this.startingMinutes = Math.round(this.startingMinutes);
      this.endingMinutes = Math.round(this.endingMinutes);
    }
  }
};
</script>

<style scoped lang="stylus">

.isCurrentlyChangingscreen
  // background:#f0f0f099
  background:transparent
  width:100%
  height:100%
  z-idex:999
  position: absolute

.p_center
  text-align:center
  margin auto
  margin-top:130px
img
  position: absolute;

.midsvg

  // margin: 40px 40px 0px 40px
  left: 20px;
  top: 20px;
  position: absolute;

  // position: absolute;
  // transform: rotate(180deg)
// .roter
  // transform: rotate(180deg)
.time
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  // letter-spacing: -1px;
  text-anchor: middle;
.cric
  stroke-dasharray: 753.982224;
  stroke-dashoffset: 90;
  stroke-linecap: round;
.selectedCircle,.selectedCircle1
  transition: r .2s, fill .2s
  &:active
    r: 19
    fill: rgba(204, 204, 204, 0.6)

  // stroke-dasharray: 62.83184;
  // stroke-dashoffset: 90;
.timeturner

  top: 23px;
  // position: absolute;
  position: relative;
  margin:auto
  width: 320px;
  height: 320px;

pre.abs
  top:0
  position: absolute;

circle.plus
  &:active
    fill: #e559

text.timetext
  text-anchor: middle;

text.plus
  font-size:32px
  text-anchor: middle;
  // &:hover,&:active
    // fill: #e55

.infg
  font-size:32px
  text-anchor: middle;
</style>
