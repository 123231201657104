


























import {Vue, Component} from '@/vue-collection'

@Component
export default class extends Vue {
  serial: string = ""
  secret: string = ""

  scanQrCode() {
    (window as any).cordova.plugins.barcodeScanner.scan(
      result => {
        if (!result.text) {
          this.UIResponder({
            text: this.$t('NewDoor.credentials.notifications.unknown-error'),
            err: true
          })
        }

        try {
          const {serial, secret} = JSON.parse(result.text)
          if (!serial || !secret) {
            this.UIResponder({
              text: this.$t('NewDoor.credentials.notifications.invalid-format'),
              err: true
            })
          }

          this.serial = serial
          this.secret = secret
        } catch (e) {
          this.UIResponder({
            text: this.$t('NewDoor.credentials.notifications.invalid-format'),
            err: true
          })
        }
      },
      err => {
        console.error(err)
        this.UIResponder({
          text: this.$t('NewDoor.credentials.notifications.unknown-error'),
          err: true
        })
      },
      {
        preferFrontCamera: false, // iOS and Android
        showFlipCameraButton: true, // iOS and Android
        showTorchButton: true, // iOS and Android
        torchOn: false, // Android, launch with the torch switched on (if available)
        saveHistory: true, // Android, save scan history (default false)
        prompt: this.$t("NewDoor.credentials.barcode-finder-prompt"), // Android
        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
        formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
        orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
        disableAnimations: true, // iOS
        disableSuccessBeep: false // iOS and Android
      }
    )
  }
}
