<template lang="pug">

f7-page#Pets
  f7-navbar(:title='currentPet.Name', back-link='Back')
  .max960(v-if="currentPet.id")
    PetImage(:petId="thisPetId", @rotated="rotatePicture")

    form(autocomplete="off", onsubmit="return false;")
      //- f7-block-title(medium) Name

      f7-list(strong, no-hairlines)
        f7-list-input(:label='$t("Pet.petsname")',
                      type='text',
                      placeholder='*name',
                      clear-button='',
                      :value="currentPet.Name || currentPet.name",
                      @input="currentPet.Name = currentPet.name = $event.target.value"
                      maxlength="20")

          i.icon-cat.medium(slot='media', v-if="currentPet.species === 'Cat'")
          i.icon-dog.medium(slot='media', v-else-if="currentPet.species === 'Dog'")

        f7-list-item(smart-select,
                    :title='$t("Pet.species")'
                    :smart-select-params="{openIn: 'sheet'}")

          select(name='species', v-model="currentPet.species")
            option(:value="'Dog'") {{$t("Pet.dog")}}
            option(:value="'Cat'") {{$t("Pet.cat")}}

      ._savesettings(:class="{ opened: isPetChanged}")
        pw-button(texter="Pet.savechanges",
                 loader="modifyPet",
                 size="m",
                 col="outline",
                 position="center",
                 @click="onSaveClicked")

    f7-block.color-red.bold.tac(inset v-if="isAnyDoorNotConnected") {{$t('General.no-active-connection-controls-disabled')}}

    f7-block-title {{$t("Pet.connecteddoors")}}
    f7-list( strong, no-hairlines)

      f7-list-item(link='#',
                    v-for="(doorItem,thingName) in _allDoors",
                    :popup-open="`#pet-door-assignment-${thingName}`"
                    :key="thingName",
                    v-if="thingName",
                    :title="doorItem.Caption",
                    :disabled="!$store.getters[`doors/${thingName}/connected`] || $store.getters[`doors/${thingName}/isUpdateInProgress`]")
        i.icon-minidoor.small(slot='media')
        span(slot="after" )
          span(v-if="assignmentRecords({ door: doorItem.id, pet: currentPet.id }).length") *connected
          span &nbsp;
          connection-state(:door="doorItem")
        PetsDoorSetsPopUp(:id="`pet-door-assignment-${thingName}`" :pet-id="thisPetId" :door="doorItem")

    template(v-if="!isDemo")
      f7-block-title {{$t("Pet.sadsection")}}
      f7-list( strong, media-list, no-hairlines)

        f7-list-item(link, @click="onDeleteClicked" v-if="canDeletePet" key="can-delete")
          i.icon-times.small(slot='media')
          span(slot="title").bold.color-red {{$t("Pet.deletepet")}}
          span(slot="text") {{$t("Pet.deletepet_description")}}
        f7-list-item(v-else key="cannot-delete")
          i.icon-times.small(slot='media')
          span(slot="title").bold.color-red {{$t("Pet.deletepet")}}
          span(slot="text") {{$t("Pet.delete.door_unreachable")}}
  .cont(v-else)
    pre * No pet Found
</template>

<script>
import PetImage from "./PetImage";

import { mapGetters, mapState, mapActions } from "vuex";
import redirectWhenUnsetUserState from "@/libs/mixins/redirectWhenUnsetUserState"
import { tagList } from "@/devices/rfid"
import { tap } from "@/libs/functional"

let self = null;
export default {
  components: { PetImage },
  beforeCreate() {
    self = this;
  },
  data() {
    return {
      currentPet: {}
    };
  },

  watch: {
    thisPetId: {
      immediate: true,
      handler(nv) {
        let thePet = self._allPets[nv];
        if (thePet) {
          self.set(["setpet", nv]);
          self.currentPet = self.$_cp(thePet);
        }
      }
    }
  },
  computed: {
    ...mapState(["RFID", "ZIGBEE"]),
    ...mapGetters(["_doorConnectionSymbol", "assignmentRecords", 'doors']),
    ...mapGetters("userState", ["isDemo"]),
    thisPetId: () => self.$f7route.params.id,
    isPetChanged: () => {
      let cp = self.$_cp(self._settingsPet);
      let tp = self.$_cp(self.currentPet);
      delete cp.doors;
      delete tp.doors;
      return JSON.stringify(cp) !== JSON.stringify(tp);
    },
    isAnyDoorNotConnected() {
      return !Object.values(this._allDoors)
        .map(d => this.$store.getters[`doors/${d.thing_name}/connected`] || this.$store.getters[`doors/${d.thing_name}/isUpdateInProgress`])
        .reduce((a, b) => a && b, true)
    },
    canDeletePet() {
      return Object.values(this.doors).every(d =>
        this.assignmentRecords({ door: d.id, pet: this.thisPetId }).length === 0 ||
        d.fwVersion.minor >= 1 ||
        d.wscon
      )
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(["updatePet", "deletePet"]),

    //! *************************************************/
    rotatePicture(newpetimg) {
      self.currentPet.petImage = newpetimg;
    },

    //! *************************************************/
    openRFIDPopUp(thingName) {
      self.set(["setdoor", thingName]);
      tagList(thingName)
        .then(list => this.$store.commit('setRFID', {thing: thingName, list: {RFIDTagList: list}}))
        .catch(() => {}) // ignore errors, likely no connection

      self.$f7.popup.open("#PetsDoorSets", true);
    },

    async onDeleteClicked() {
      self.$f7.dialog.confirm(
        self.$t("Pet.deletepet_description"),
        self.$t("Pet.deletepet"),
        async () => {
          self.$f7.dialog.progress();
          const res = await this.deletePet({ petId: this.currentPet.id })
          res.title = 'Pet.notifications.deleted.title';
          self.UIResponder(res);

          self.$f7.dialog.close();
          if (!res.err) this.$f7router.back()
        }
      );
    },

    async onSaveClicked() {
      self.$f7.dialog.progress();

      let data = {
        id: self.currentPet.id,
        name: self.currentPet.Name || self.currentPet.name,
        species: self.currentPet.species,
      };

      const res = await this.updatePet({ data, image: this.currentPet.petImage })

      res.title = 'Pet.notifications.changed.title';
      self.UIResponder(res);

      self.$f7.dialog.close();
    }
  },
  mixins: [redirectWhenUnsetUserState]
};
</script>

<style scoped lang="stylus">

._savesettings
  height: 0 !important;
  overflow: hidden
  transition: height 0.3s;
  &.opened
    height: 52px !important;

</style>
