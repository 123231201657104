








import {Vue, Component, Action, Watch, Prop, State} from '@/vue-collection'
import ManualDoorConnectionInfo from './ManualDoorConnectionInfo.vue'
import {setupSsid} from './constants'
import {observe, reInit} from "@/libs/bonjour"

@Component({
  components: {
    ManualDoorConnectionInfo
  }
})
export default class extends Vue {
  @Prop(String) serialToSearch
  @Prop(String) doorWifi
  @Action _getWifiName

  searching: boolean = true
  bonjourList = []

  @State wifiName
  @Watch('wifiName', {immediate: true}) async onWifiNameChanged(nv, ov) {
    await reInit()
    if (this.wifiName !== setupSsid && !this.subscription) await this.subscribeToBonjour()
  }

  created() {
    document.addEventListener("resume", this.onResume, false)
    document.addEventListener("pause", this.onPause, false)
  }

  subscription: ZenObservable.Subscription
  error: string = null

  async mounted() {
    this.updateWifiName().catch(console.error) // fire and forget. controlled by `this.searching`
  }

  async subscribeToBonjour() {
    this.subscription = (await observe())
      .filter(e => e.ipv4Addresses[0] && e.name && e.name.toLowerCase().startsWith('petwalk-control'))
      .map(e => ({
        serial: e.name.split('-').pop(),
        url: e.ipv4Addresses[0]
      })).subscribe({
        next: (value) => {
          if (value.serial === this.serialToSearch) this.$emit('finished', value)
          this.bonjourList = [...this.bonjourList, value]
        },
        error: (errorValue) => {
          console.error(errorValue)
          this.error = errorValue
        }
      })
  }

  beforeDestroy() {
    document.removeEventListener("resume", this.onResume, false)
    document.removeEventListener("pause", this.onPause, false)
    this.searching = null
    this.subscription && this.subscription.unsubscribe()
  }

  onResume() {
    this.searching = true
  }

  onPause() {
    this.searching = false
  }

  async updateWifiName() {
    if (this.searching === null) return
    if (this.searching) try {
      await this._getWifiName()
    } catch (e) {
      console.error(e)
    }
    setTimeout(this.updateWifiName.bind(this), 500)
  }
}
