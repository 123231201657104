











































import {Vue, Component, Prop, State} from '@/vue-collection'
import PetIcon from "@/components/PetIcon.vue"

/* eslint-disable camelcase */
interface Event {
  hrtime: string
  event_source: 'ALEXA' | 'DOOR' | 'APP' | 'SUPPORT'
  event_type: 'open' | 'close' | 'power' | 'on' | 'off' | 'online' | 'offline' | 'error'
  properties: {
    direction?: 'IN' | 'OUT'
    rfid_index?: number
    value: any
    error_message: string
    [key: string]: any
  }
  pet: {
    Name: string
    id: number | string
    species: string
  }
}
/* eslint-enable camelcase */

@Component({
  name: 'pw-event-list-item',
  components: {PetIcon}
})
export default class extends Vue {
  @Prop(Object) ev: Event
  @State pets
}
