




























import {Vue, Component, Action, Getter} from '@/vue-collection'
import {Door} from "@/domain-types/door"
import idx from "idx"
import {Prop, Watch} from "vue-property-decorator"
import redirectWhenUnsetUserState from "@/libs/mixins/redirectWhenUnsetUserState"
import PwCollapse from "@/components/PwCollapse.vue"

@Component({
  components: { PwCollapse },
  mixins: [redirectWhenUnsetUserState]
})
export default class extends Vue {
  @Prop() doorId: string
  @Prop() compId: string
  currentCaption = ''
  mountingPosition = null

  @Watch('$f7route', { immediate: true }) routeWatcher() {
    const component = this.component
    this.currentCaption = idx(component, _ => _.caption) || ''
    this.mountingPosition = idx(component, _ => _.mountingPosition)
  }

  @Getter _allDoors
  @Getter _doorConnectionSymbol
  @Action updateComponent: ({thing, component}) => Promise<{ err }>
  @Action removeComponent: ({thing, component}) => Promise<{ err }>

  get door(): Door {
     return this.$store.getters._allDoors[this.doorId]
  }

  get component() {
    return idx(this.door, _ => _.assigned_components.find(c => c.id === this.compId))
  }

  get isDoorConnected() {
    return this.$store.getters[`doors/${this.door.thing_name}/connected`]
  }

  async saveComponent() {
    const component: any = {id: this.compId, caption: this.currentCaption}
    if (this.component.mountingType === 'extern' && this.mountingPosition)
      component.mountingPosition = this.mountingPosition

    const result = await this.updateComponent({thing: this.doorId, component})
    ;(this as any).UIResponder(result)
  }

  async onDeleteClicked() {
    this.$f7.dialog
      .create({
        title: this.$t("Component.remove"),
        text: this.$t("Component.remove-description"),
        buttons: [
          {
            text: this.$t("Component.remove"),
            onClick: async () => {
              const result = await this.removeComponent({thing: this.doorId, component: this.compId})
              ;(this as any).UIResponder(result)

              if (!result.err) this.$f7router.navigate(`/`, {clearPreviousHistory: true})
            }
          },
          {
            text: this.$t("Navigation.cancel")
          }
        ],
        verticalButtons: true
      })
      .open();
  }
}
