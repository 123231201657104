<template lang="pug">
  f7-list-item(:disabled="disabled")
    .flagbtn.nomover(slot='media', @click.stop="handleClick", :class="{_active:reportedFlag}")
      i(:class="icon")
        ._preload( v-if="inchange")
</template>

<script>
    import idx from "idx";

    export default {
        name: "flag-control-button-list-item",
        props: {
          flag: {
            type: String,
            required: false
          },
          thingName: {
            type: String,
            required: true
          },
          icon: {
            type: String,
            required: false
          },
          disabled: {
            type: Boolean,
            required: false
          }
        },
        data () {
          return {
            inchange: false
          }
        },
        watch: {
          reportedFlag(nv, ov) {
            if (nv === this.desiredFlag && this.inchange) {
              this.inchange = false;
            }
          }
        },

        computed: {
          reportedFlag() {
            return (
              idx(this.$store.state.doors[this.thingName], _ => _.reported.clb_cfg_flags[this.flag]) ||
              this.$store.state.doors[this.thingName].reportedflags[this.flag]
            )
          },
          desiredFlag() {
            return idx(this.$store.state.doors, _ => _[this.thingName].desired.clb_cfg_flags[this.flag])
          },
        },
        methods: {
          handleClick() {
            this.inchange = true;
            this.$emit('click',this.flag)
          }
        }
    }
</script>

<style scoped lang="stylus">

  .flagbtn
    display: inline-block
    vertical-align: baseline;
    position: relative
    // background: white
    background: var(--f7-list-bg-color)
    padding: .5em

    line-height: 38px
    height: 24px
    width: 24px

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.7)
    border-radius: 32px
    transition: all .1s
    border: 3px solid transparent
    color: #bfbfbf
    text-align: center

    &:hover
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5)
    &:active
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3)
      background: #65656533

    &._active
      color: #91ac25
      border: 3px solid #91ac25
      i
        color: #91ac25

    i
      display: inline-block;
      font-size: 24px;
      font-weight: 900;
      line-height: 24px;
      vertical-align: top;

    ._preload
      border: 3px solid #67676767
      border-top-color #91ac25
      animation: spin 1s infinite ease
      position: absolute
      border-radius: 32px
      // background #233
      top:0
      right:0
      left:0
      height: 34px
      width: 34px
      padding: 0
      margin: 0
  // .preloader i
  //   width: 32px
  //   height: 32px
  //   border: 3px solid #fff
  //   border-top: 3px solid #91ac25
  // &:active
  //   background: #fafafa
  //   box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5)

  @keyframes spin
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
</style>
