
import HomePage from './pages/home.vue'
import UserAccount from './pages/UserAccount.vue'

// import AboutPage from './pages/about.vue'
import Settings from './pages/Settings/Settings'
import TimeLine from './pages/TimeLine'
import Contact from './pages/Contact'
import Camera from './pages/Camera'

import SettingsDoor from './pages/Settings/Door/Door'
import SettingsPet from './pages/Settings/Pet/Pet'

import SettingsDoorAdvanced from './pages/Settings/Door/Advanced'

import NewPet from './pages/Settings/Pet/NewPet'
import NewService from './pages/Settings/Service/NewService'
import ScanForModules from "./pages/Settings/Service/ScanForModules"
import ModuleView from './pages/Settings/Service/ModuleView'
import NewDoor from './pages/Settings/Door/NewDoor/NewDoor'
import ServiceAlexa from "@/pages/Settings/Service/ServiceAlexa"

export default [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/home',
    name: 'home',
    keepAlive: true,
    component: HomePage
  },
  {
    path: '/useraccount/',
    component: UserAccount
  },
  {
    path: '/settings/',
    keepAlive: true,
    component: Settings
  },
  {
    path: '/newdoor/',
    component: NewDoor
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
/*
  {
    path: '/newdoor/',
    name: 'NewDoor',
    component: NewDoor
  },*/
  {
    path: '/newdoor/:id/',
    name: 'NewDoor',
    component: NewDoor
  },

  {
    path: '/camera',
    name: 'camera',
    component: Camera
  },
  {
    path: '/timeline/:id',
    name: 'timeline',
    component: TimeLine
  },
  {
    path: '/settings/door/:id',
    name: 'SettingsDoor',
    component: SettingsDoor
  },

  {
    path: '/settings/door/:thing/advanced',
    name: 'SettingsDoorAdvanced',
    component: SettingsDoorAdvanced
  },

  {
    path: '/settings/door/:doorId/components/:compId',
    name: 'ComponentSettings',
    component: ModuleView
  },

  {
    path: '/settings/pet/:id',
    name: 'SettingsPet',
    component: SettingsPet
  },

  {
    path: '/settings/newpet/',
    name: 'NewPet',
    component: NewPet
  },

  {
    path: '/settings/service/alexa',
    name: 'Alexa',
    component: ServiceAlexa
  },

  {
    path: '/settings/newservice/',
    name: 'NewService',
    component: NewService
  },
  {
    path: '/settings/newservice/module',
    name: 'ScanForModules',
    component: ScanForModules
  },
  {
    path: '(.*)',
    component: HomePage
  }
]

