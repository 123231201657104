<template lang="pug">
  f7-sheet.autoheight.flag_sheet(:swipe-to-close="swipe", backdrop, :class="sheetClass")
    flag-screen-head
    .backlink
      .fl
        f7-link(sheet-open='.Flag_Sheet', animate)
          i.icon-back-bold
          span {{$t("Back")}}
      .backlinkinfo {{backlinkinfo}}
    .clear
    .tac
      i(:class="icon")
    .padding-horizontal
      slot(name="radio")
        .tac
          pw-radio(:key="flag", :left="$t('Flagcontrol.toggle.deactivated')", :right="$t('Flagcontrol.toggle.activated')", :initval="true" :valone="reportedFlag", :valtwo="desiredFlag !== undefined ? desiredFlag : reportedFlag", @click="handleClick")
      slot(name="content")
        .padding(slot="content")
          f7-range(scale, :scaleSteps="5", :scaleSubSteps="2", :min='0', :max='100', :step='1', :value="value", :label='true', @range:changed="onSliderValueChange")
      p.tac(slot="text")
        slot(name="text")

</template>

<script>
  import pwRadio from "@/components/pwRadio";
  import RoundTimePicker from "@/components/RoundTimePicker";
  import idx from "idx";

  export default {
    components: { pwRadio, RoundTimePicker },
    name: "flag-control-sheet",
    props: {
      sheetClass: {
        type: String,
        required: true
      },
      backlinkinfo: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      flag: {
        type: String,
        required: true
      },
      shadow: {
        type: String,
        required: false
      },
      thingName: {
        type: String,
        required: true
      },
      swipe: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        inchange: false
      }
    },
    watch: {
      reportedFlag(nv, ov) {
        if (nv === this.desiredFlag && this.inchange) {
          this.inchange = false;
        }
      }
    },
    computed: {
      reportedFlag() {
        return (
          idx(this.$store.state.doors, _ => _[this.thingName].reported.clb_cfg_flags[this.flag]) ||
          idx(this.$store.state.doors, _ => _[this.thingName].reportedflags[this.flag])
        )
      },
      desiredFlag() {
        return idx(this.$store.state.doors, _ => _[this.thingName].desired.clb_cfg_flags[this.flag])
      },
      value() {
        return idx(this.$store.state.doors, _ => _[this.thingName].reported[this.shadow])
      }
    },
    methods: {
      onSliderValueChange(value) {
        if (value != this.value)
          this.$store.dispatch(`doors/${this.thingName}/desire`, {
            [this.shadow]: value
          })
      },
      handleClick() {
        this.inchange = true;
        this.$emit('click',this.flag)
      }
    }
  }
</script>


<style scoped lang="stylus">

  // #FlagControl

  .Flag_Sheet
    box-shadow: 0px 0px 18px 0 rgba(0, 0, 0, 0.2);
    padding-bottom:32px
    max-width: 840px
    left:0
    right:0
    margin: auto
    background: var(--f7-list-bg-color)

  .autoheight
    height: auto
    max-width: 840px
    left:0
    right:0
    margin: auto

  .backlink
    overflow: auto;
    margin-top: 18px
    a
      margin-left: 8px
      height: 20px;
      line-height: 20px;
      font-size: 14px!important
      font-weight: 700
      color: #424242
      font-size: 16px
  // background: #234
  .backlinkinfo
    float: right
    margin-right: 8px
    font-size: 14px!important
    font-weight: 700

  .spacer84
    // height: 84px
    clear:both
</style>
