<template lang="pug">
f7-page#UserAccount
  f7-navbar(:title='$t("UserAccount.account")', :back-link='$t("Navigation.back")', back-link-url="/")

  .max960(v-if="uiloader.startloader")
    .pwloader
  .max960(v-else-if="['demo', 'unset'].includes(userState.value)")
    UserCreds

  .max960(v-else)

    //- f7-list(inset, strong, no-hairlines, no-hairlines-between)
      f7-list-item(:title="'logout'", @click="$awsCognito.logOutAWSUser()")

    f7-block-title(medoium) {{$t("UserAccount.user_info")}}
    f7-list(v-if="!!user" strong, media-list, no-hairlines)
      f7-list-item(:title="user['username']", header='USERNAME', info="Full name please")
      f7-list-item.popup-open(link='#', :header='$t("UserAccount.email")', data-popup='#UserDataChangerPop', :title="user.email", :after='$t("UserAccount.change")')
      f7-list-item.popup-open(link='#', :header='$t("UserAccount.password")', data-popup='#PasswordChangerPop', title="***", :after='$t("UserAccount.change")')
      f7-list-item(v-if="!user.email_verified", header="CONFIRMED" link='#', :title="'Verified: '+ user.email_verified", :after='$t("UserAccount.verify")')
      f7-list-item(v-else-if="user.email_verified", header="CONFIRMED", :title="'Verified: ' + user.email_verified")

    //- Notifications
    f7-block-title(medoium) {{$t("UserAccount.notificationsettings")}}
    f7-list(strong, media-list, no-hairlines)
      f7-list-item(checkbox, :key="i", v-for="(n,i) in notificationsettingsLOC", :value="i", :name="i" :checked='notificationsettingsLOC[i]', @change="ChangeNotis($event.target.value)")
        span(slot="title") {{$t(`UserAccount.settings.${i}`)}}

    ._savesettings(:class="{ opened: notisChanged}")
      pwButton(texter="UserAccount.save_notification", loader="changedNotifications", size="m", col="outline", position="center", @click="SaveNotifications()")

    f7-block-title(medium) {{$t("UserAccount.account")}}
    f7-list(strong, media-list, no-hairlines)
      f7-list-item(link='#', view='#main-view', @click.prevent="logout")
        span(slot="title").bold.color-red {{$t("UserAccount.logout")}}

    f7-block-title(medium) {{$t("UserAccount.dangerous")}}
    f7-list(strong media-list no-hairlines)
      f7-list-item.popup-open(link="#" view="" data-popup="#ConfirmUserDeletionPop")
        span(slot="title").bold.color-red {{$t("UserAccount.delete")}}

  PasswordChangerPop
  UserDataChangerPop
  ConfirmUserDeletionPop

</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import { teardown } from "@/libs/notifications"
import ConfirmUserDeletionPop from "@/pages/UserAccount/ConfirmUserDeletionPop"

let self = null
export default {
  components: { ConfirmUserDeletionPop },
  beforeCreate() {
    self = this
  },
  name: "UserAccount",
  data() {
    return {
      notificationsettingsLOC: {}
    }
  },
  watch: {
    notificationsettings: {
      immediate: true,
      deep: true,
      handler(nv) {
        self.$set(self, "notificationsettingsLOC", self.$_cp(nv))
      }
    }
  },

  computed: {
    ...mapState(["user", "uiloader", "notificationsettings"]),
    // ...mapGetters([]),
    notisChanged() {
      return (
        JSON.stringify(self.notificationsettingsLOC) !==
        JSON.stringify(self.notificationsettings)
      )
    }
  },

  created() {},
  mounted() {},
  methods: {
    ...mapActions(["_postNotificationSettings"]),
    async SaveNotifications() {
      self.$f7.dialog.progress()

      let resp = await self._postNotificationSettings(self.notificationsettingsLOC)
      self._TOAST(resp)

      self.$f7.dialog.close()
    },
    ChangeNotis(e) {
      self.notificationsettingsLOC[e] = !self.notificationsettingsLOC[e]
    },
    async logout() {
      await this.$awsCognito.logOutAWSUser()
      this.$f7router.navigate('/')
    }
  }
}
</script>

<style scoped lang="stylus">
._savesettings
  height: 0 !important;
  overflow: hidden
  transition: height 0.3s;
  &.opened
    height: 52px !important;
</style>
