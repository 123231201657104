




















































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState, mapMutations } from "vuex";
import { State, Getter, namespace } from 'vuex-class'
import { version } from "@/libs/version"
import ConfirmErrorAcknowledgement from "@/pages/ConfirmErrorAcknowledgement.vue"

const UserState = namespace('userState')
@Component({
  components: {ConfirmErrorAcknowledgement}
})
export default class DoorControl extends Vue {
  @State uiloader
  @State wifiName
  @State reconnect
  @Getter _reported
  @UserState.Mutation quitDemo

  get isWsMqttConnecting() {
    return this._currentDoor.isWsConnecting || this._currentDoor.isMqttConnecting
  }

  get needsAknowledgement(): boolean {
    return version(this._reported.clb_state_version).gt(version('0.1.19')) &&
      [1,2,3,5,6,10,98].includes(this._reported.clb_state_error?.value)
  }
  get canAknowledgeError(): boolean {
    return this.needsAknowledgement && this._currentDoor.wscon
  }

  get isUpdateInProgress() {
    return ['running'].includes(this._currentDoor.firmware.jobStatus) ||
      (this._currentDoor.firmware.componentUpdates || []).some(x => ['running'].includes(x.jobStatus))
  }

  turnDoorONOff() {
    this.$store.dispatch(`doors/${this._currentDoorId}/desire`, {
      clb_state_opmode: 'on'
    })
  }

  openCloseDoor() {
    window.LOG.log("openCloseDoor", this._reported.clb_state_door_pos, this._currentDoorId);

    if (this._reported.clb_state_door_pos === "open") {
      this.$store.dispatch(`doors/${this._currentDoorId}/desire`, {
        clb_state_door_pos: 'closed'
      })
    } else /*if (this._reported.clb_state_door_pos.value === "closed")*/ {
      this.$store.dispatch(`doors/${this._currentDoorId}/desire`, {
        clb_state_door_pos: 'open'
      })
    }
  }

  navigateToNewDoorDialog() {
    this.$f7router.navigate('/newdoor/')
  }
}
