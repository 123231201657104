/*
Description:
  library to manage Cognito Calls like login / register / forgot password.. or get accesstoken
*/

// @ts-ignore
import Amplify from 'aws-amplify'
import store from '@/store/store'
import env from '@/env'

import utils from '@/libs/utils'

import i18next from 'i18next'
import {connect, disconnect} from "@/pets/storage"
import {putCognitoIdentity} from "@/api"
import {teardown} from "@/libs/notifications"

const t = i18next.t.bind(i18next)

declare global {
  interface Window { LOG: any; }
}

Amplify.configure({
  Auth: {
    identityPoolId: env.aws.iot.IdentityPoolId,
    region: env.aws.region,
    userPoolId: env.aws.poolData.UserPoolId,
    userPoolWebClientId: env.aws.poolData.ClientId,
    mandatorySignIn: false
  }
})

let self = {
  ...utils,
  Amplify: () => Amplify,

  //* *************************************************
  async cognitoIdentityID() {
    let userinfo = await Amplify.Auth.currentUserInfo()
    return userinfo.id
  },

  //* *************************************************
  async credentials() {
    let credentials = await Amplify.Auth.currentCredentials()
    return credentials
  },

  //* *************************************************
  async pwheader() {
    try {
      let currentuser = await Amplify.Auth.currentAuthenticatedUser()
      return {
        headers: {
          Authorization: currentuser.signInUserSession.idToken.jwtToken,
          UserAccess: currentuser.signInUserSession.accessToken.jwtToken
          // version: env.version
        }
      }
    } catch (err) {
      console.error(err)
      console.log('ERROR WHILE GETTING CREDENTIALS -> FORCEFULLY LOGGING OUT')
      await this.logOutAWSUser()
    }
  },

  //* *************************************************
  async logOutAWSUser() {
    try {
      console.log('logging out...')

      await disconnect()
      console.log('disconnected mqtt')

      await Amplify.Auth.signOut()
      console.log('signed out.')

      store.commit('resetall')
      store.commit('credentials/clearLogin')
      localStorage.clear()
      console.log('cleared storage')

      // here is breakage!
      await teardown()
      console.log('cleared push notification token')

      console.log('logged out. redirecting to root...')
      ;(window as any).cordova?.plugins.diagnostic.restart(null, false)
    } catch (e) {
      console.error('error while logging out!', e)
    }
  },

  // WHEN IN ACCOUNT!!

  //* *************************************************
  async changeUserEmailInAws(newemail) {
    if (store.state.user.email === newemail) {
      return {
        text: t('AWS_change_email_sameemail'),
        code: null,
        err: true
      }
    } else {
      try {
        let resp = await Amplify.Auth.updateUserAttributes(Amplify.Auth.user, { email: newemail })
        window.LOG.log(resp)
        this.checkUserBeforeStartApp()

        return { text: t('AWS_changeemail_codesent'), err: false }

      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_change_email_')
        window.LOG.log(err, error)
        return {
          text: error,
          code: err.code ? err.code : '',
          err: true
        }
      }
    }
  },

  //* *************************************************
  async verifyCodeinAws(verificationcode) {
    if (store.state.user.email_verified) {
      return {
        text: t('AWS_changeemail_already_verified'),
        code: null,
        err: true
      }
    } else if (verificationcode.length === 0) {
      return {
        text: t('AWS_changeemail_codeempty'),
        code: null,
        err: true
      }
    } else {
      try {
        let resp = await Amplify.Auth.verifyCurrentUserAttributeSubmit('email', verificationcode)
        window.LOG.log(resp)
        this.checkUserBeforeStartApp()
        return { text: t('AWS_changeemail_emailchangedandverified'), err: false }
      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_change_email_')
        window.LOG.log(err, error)
        return {
          text: error,
          code: err.code ? err.code : '',
          err: true
        }
      }
    }
  },

  //* *************************************************
  async reSendAWSVerificationCode() {
    if (store.state.user.email_verified) {
      return {
        text: t('AWS_changeemail_already_verified'),
        code: null,
        err: true
      }
    } else {
      try {
        let resp = await Amplify.Auth.verifyCurrentUserAttribute('email')
        this.checkUserBeforeStartApp()
        window.LOG.log(resp)
        return { text: t('AWS_account_emailverifywithcode'), err: false }
      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_change_email_')
        window.LOG.log(err, error)
        return {
          text: error,
          code: err.code ? err.code : '',
          err: true
        }
      }
    }
  },

  //* *************************************************
  async ChangeAWSPassword(oldpw, nupw) {
    if (oldpw.length > 6) {
      try {
        let resp = await Amplify.Auth.changePassword(
          Amplify.Auth.user,
          oldpw,
          nupw
        )
        window.LOG.log(resp)
        return {
          text: 'OK, Password Changed.', err: false
        }
      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_change_pw_')
        window.LOG.log(error)
        return {
          text: error,
          code: err.code ? err.code : '',
          err: true
        }
      }
    } else {
      return {
        text: t('AWS_checkcurrentpw'), // if username/email/password is empty
        err: true
      }
    }
  },

  //* *************************************************
  async checkUserBeforeStartApp() {
    try {
      let currentuser = await Amplify.Auth.currentAuthenticatedUser()
      let currentsession = await Amplify.Auth.currentSession()
      let currentUserInfo = await Amplify.Auth.currentUserInfo()

      if (Object.keys(currentUserInfo).length > 0) {
        let user = {
          username: currentUserInfo.username,
          email: currentUserInfo.attributes.email,
          email_verified: currentUserInfo.attributes.email_verified,
          sub: currentUserInfo.attributes.sub,
          identity: currentUserInfo.id
        }
        console.log(user)

        store.commit('set', ['user', user])
        store.commit('userState/login')
        store.commit('set', ['checkeduser', true])

        store.commit('credentials/setLogin', [
          `cognito-idp.${env.aws.region}.amazonaws.com/${currentuser.pool.userPoolId}`,
          currentuser.getSignInUserSession().getIdToken().getJwtToken()
        ])

        await putCognitoIdentity(currentUserInfo.id)
        await connect(currentUserInfo.id)

        return {
          data: { currentuser, currentsession, currentUserInfo },
          err: false
        }
      } else {
        // TODO: think about this one and the one below. Fails on invalid credentials
        //  OR no connection, which would result in diferent user states
        store.commit('userState/offline')

        return {
          text: 'unset user',
          err: true
        }
      }
    } catch (err) {
      window.LOG.log('ERROR checkUserBeforeStartApp', err)
      store.commit('userState/offline')
      return {
        text: err,
        code: err.code || '',
        err: true
      }
    }
  },

  //* *************************************************
  async signInAWSUser(username, password) {
    username = username.trim().toLowerCase()
    // password = password
    if (username.length > 0 && password.length > 0) {
      try {
        let resp = await Amplify.Auth.signIn(username, password)
        await this.checkUserBeforeStartApp()
        return {
          text: '',
          code: null,
          err: false
        }
      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_signin_')
        window.LOG.log(error)
        return {
          text: error,
          code: err.code ? err.code : '',
          err: true
        }
      }
    } else {
      return {
        text: t('AWS_signin_EmaiAndPasswordEmpty'), // if username/email/password is empty
        code: null,
        err: true
      }
    }
  },

  //* *************************************************
  async forgotAWSPassword(username) {
    username = username.trim().toLowerCase()
    try {
      let resp = await Amplify.Auth.forgotPassword(username)
      window.LOG.log(resp)
      return {
        text: t('AWS_forgotpw_VerificationCodeSent'),
        code: null,
        err: false
      }
    } catch (err) {
      let error = await this.ErrorHandler(err, 'AWS_forgotpw_')
      window.LOG.log(err, '.', error)
      return {
        text: error,
        code: err.code ? err.code : '',
        err: true
      }
    }
  },

  //* *************************************************
  async sendVerificationCodeForAWSPassword(username, verificationcode, newpassword) {
    username = username.trim().toLowerCase()
    if (username.length > 0 && verificationcode.trim().length > 0 && newpassword.length > 0) {
      if (self.$_validatePassword(newpassword)) {
        try {
          let resp = await Amplify.Auth.forgotPasswordSubmit(username, verificationcode, newpassword)
          window.LOG.log(resp)
          return {
            text: t('AWS_submitpw_CodeSuccessfullySubmited'),
            code: null,
            err: false
          }
        } catch (err) {
          window.LOG.log(err)
          let error = await this.ErrorHandler(err, 'AWS_submitpw_')
          window.LOG.log(error)
          return {
            text: error,
            code: err.code ? err.code : '',
            err: true
          }
        }
      } else {
        return {
          text: t('AWS_submitpw_InvalidParameterException'),
          code: null,
          err: true
        }
      }
    } else {
      return {
        text: t('AWS_submitpw_EmptyEmailCodePassword'),
        code: null,
        err: true
      }
    }
  },

  //* *************************************************
  async resendConfirmation(username) {
    if (username.trim().toLowerCase().length > 0) {
      try {
        let resp = await Amplify.Auth.resendSignUp(username.trim().toLowerCase())
        window.LOG.log(resp)
        return {

          text: t('AWS_login_confirmationsent') + ' ' + resp.CodeDeliveryDetails.Destination,
          err: false
        }
      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_resendverivication_')
        window.LOG.log(error)
        return {
          text: error,
          code: err.code ? err.code : '',
          err: true
        }
      }
    } else {
      return {
        text: t('AWS_checkyouremail'), //
        code: null,
        err: true
      }
    }
  },

  //* *************************************************
  async signUpAWSUser(email, username, password) {
    if (
      email.trim().toLowerCase().length > 0 &&
      username.trim().toLowerCase().length > 0 &&
      password.length > 0
    ) {
      try {
        let resp = await Amplify.Auth.signUp({
          username: username.trim().toLowerCase(),
          password: password,
          attributes: {
            email: email.trim().toLowerCase()
          },
          validationData: []
        })
        window.LOG.log(resp)
        return {
          text: '',
          code: null,
          err: false
        }
      } catch (err) {
        let error = await this.ErrorHandler(err, 'AWS_signup_')
        window.LOG.log(error)
        return {
          text: error,
          code: null,
          err: true
        }
      }
    } else {
      return {
        text: t('AWS_emailpassempty'), // if username/email/password is empty
        code: null,
        err: true
      }
    }
  },

  //* *************************************************
  async ErrorHandler(err, prefix) {
    let pf = prefix || ''
    if (err.code) {
      if (t(pf + err.code)) {
        return t(pf + err.code)
      } else if (t(err.code)) {
        // if there is NO Prefix Yet
        this.setUnCoughtError('NO_PREFIX_YET_' + err.code)
        return t(err.code)
      } else if (err.message) {
        this.setUnCoughtError(err)
        return err.message
      } else {
        this.setUnCoughtError(err)
        return err
      }
    } else if (t(pf + err)) {
      // FOR ERRORS WITH OUT CODE PROPRTYIE
      return t(pf + err)
    } else {
      this.setUnCoughtError(err)
      return err
    }
  },

  //* *************************************************
  setUnCoughtError(err: string) {
    let arr = JSON.parse(localStorage.getItem('errorarr'))
    if (!arr) { arr = [] }
    arr.push(err)
    localStorage.setItem('errorarr', JSON.stringify(arr))
  },

}

export default self
