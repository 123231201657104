// "use strict";
// @ts-ignore
import Amplify, { PubSub } from 'aws-amplify'
// @ts-ignore
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'
import env from '@/env'
import utils from '@/libs/utils'
import WS from '@/libs/WS'
import store from '@/store/store'
// let client
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: env.aws.region,
  aws_pubsub_endpoint: env.aws.iot.endpointAddress
}))

let devWS = env.devws

// let TheClient = {}
let waitForMqtt = null
window.TheClient = {}
let self = {
  ...utils,

  //! *************************************************
  async init() {
    let doors = store.state.doors
    for (let thing in doors) {
      let door = doors[thing]
      if (door.mqttcon) {
        // store.commit('set', ['doors', thing, 'mqttcon', false])
        store.commit('setToDoor', [thing, 'mqttcon', false])
      }
      if (window.TheClient[thing]) {
        window.TheClient[thing].unsubscribe()
        // delete window.TheClient[thing]
      }
      setTimeout(async () => {
        if (door.case === 'cloud' && !door.mqttcon) {
          store.commit('setToDoor', [thing, 'isMqttConnecting', true])
          clearTimeout(waitForMqtt)
          waitForMqtt = setTimeout(() => {
            store.commit('setToDoor', [thing, 'isMqttConnecting', false])
          }, 5000)
          // let thing = doors[door].thing_name
          // let topicget2 = '$aws/things/' + thing + '/shadow/get'
          let topicGetAccepted = '$aws/things/' + thing + '/shadow/get/accepted'
          let topicUpdateAccepted = '$aws/things/' + thing + '/shadow/update/accepted'
          // let topicUpdateRejected = '$aws/things/' + thing + '/shadow/update/rejected'
          // const res = await PubSub.subscribe([topicGetAccepted, topicUpdateAccepted]).subscribe({
          try {
            window.TheClient[thing] = await PubSub.subscribe([topicGetAccepted, topicUpdateAccepted]).subscribe({
              next: data => {
                LOG.log('Message received', data)
                let topicSymbol = data.value[Object.getOwnPropertySymbols(data.value)[0]]
                if (topicSymbol) {
                  let thing = topicSymbol.split('/')[2]
                  let topic = topicSymbol.split('/')[4]
                  let status = topicSymbol.split('/')[5]
                  let reported = data.value.state.reported || {}
                  let desired = data.value.state.desired || {}
                  if (Object.keys(reported).length > 0) { self.MQTTMessageHandler(thing, topic, status, reported) }
                  store.commit('setToDoor', [thing, 'isMqttConnecting', false])
                }
              },
              error: error => {
                window.LOG.err('error', error)
                // store.commit('set', ['doors', thing, 'mqttcon', false])
                store.commit('setToDoor', [thing, 'mqttcon', false])
                store.commit('set', ['reconnect', false])
                store.commit('set', ['reconnectMessage', {}])
                store.commit('setToDoor', [thing, 'isMqttConnecting', false])
              },
              close: (c) => {
                window.LOG.log('onConnectionLost', c)
                // store.commit('set', ['doors', thing, 'mqttcon', false])
                store.commit('setToDoor', [thing, 'mqttcon', false])
                store.commit('set', ['reconnect', false])
                store.commit('set', ['reconnectMessage', {}])
                store.commit('setToDoor', [thing, 'isMqttConnecting', false])
              }
            })
            // AFTER CONNECT SEND FIRSTGET PUBLISH
            let topicget = '$aws/things/' + thing + '/shadow/get'
            PubSub.publish(topicget, '')
            // IF FOR SOME CASE THE CLIENT IS NOT READY (SHITTY AWS PUBSUB DOESNT RESPOND CORRECTLY!!) THERE ARE 4 RECONNECT ATTEMPTS:
            setTimeout(() => { if (!doors[thing].mqttcon) { PubSub.publish(topicget, '') } }, 1000)
            setTimeout(() => { if (!doors[thing].mqttcon) { PubSub.publish(topicget, '') } }, 2000)
            setTimeout(() => { if (!doors[thing].mqttcon) { PubSub.publish(topicget, '') } }, 4000)
            setTimeout(() => { if (!doors[thing].mqttcon) { PubSub.publish(topicget, '') } }, 6000)
          } catch (err) {
            window.LOG.log(err)
          }
        } else {
          window.LOG.log('nocon:', door.thing_name)
        }
      }, 20)
    }
    return 'done'
  },
  //! *************************************************
  MQTTMessageHandler(thing, topic, status, reported) {
    let doors = store.getters._allDoors
    if (store.state.reconnect) {
      PubSub.publish(store.state.reconnectMessage.topic, store.state.reconnectMessage.msg)
      store.commit('set', ['reconnect', false])
      store.commit('set', ['reconnectMessage', {}])
    }
    if (reported && (topic === 'get' || topic === 'update') && status === 'accepted') {
      // store.commit('set', ['doors', thing, 'mqttcon', true])
      store.commit('setToDoor', [thing, 'mqttcon', true])
      let savestate = {}
      Object.keys(reported).forEach(key => {
        let item = reported[key]
        savestate[key] = {}
        if (key === 'clb_state_door_pos') {
          savestate[key].value = item.value.toLowerCase()
          // store.commit("loader", ["openclosedoor", false]);
        } else if (key === 'clb_state_error') {
          savestate[key].value = item.value
          savestate[key].message = item.message
        } else if (item.hasOwnProperty('value')) {
          savestate[key] = item.value
        } else {
          savestate[key] = item
        }
      })

      store.dispatch('synchronizeComponentState', { components: reported.components, pets: reported.pets, thing })

      // store.commit('updateMQTT', [thing, savestate])
      // LOG.log('savestate MQTT: ', savestate);

      if (savestate.clb_cfg_flags !== undefined) {
        let madeFlags = self.$_makeFlags(savestate.clb_cfg_flags.toString())
        store.commit('updateFlagsReported', [thing, madeFlags])
      }
      store.commit('updateMQTTReported', [thing, savestate])
      store.commit('updateMQTTWish', [thing, savestate])

      if (devWS || (!doors[thing].wscon && savestate.isConnected)) {
        console.log('reconnect ws from mqtt', doors[thing].wscon)
        WS.init(thing)
      }
    }
  },
  //! *************************************************
  async SendToMQTT(thing, key, value) {
    let doors = store.getters._allDoors
    let savestate = {}
    if (key === 'clb_state_door_pos') {
      // store.commit("loader", ["openclosedoor", false]);
      savestate[key] = { value }
    } else if (key === 'clb_state_opmode') {
      savestate[key] = value
    } else if (key === 'clb_cfg_flags') {
      let madeFlags = self.$_makeBinFromFlags(doors[thing].wishedflags)
      value = madeFlags
      savestate = { [key]: value }
    } else {
      // savestate = { [key]: value }
      savestate[key] = value
    }
    store.commit('updateMQTTWish', [thing, savestate])
    if (thing !== 'demodoor') {
      let topic = '$aws/things/' + thing + '/shadow/update'

      let desired
      if (['components', 'pets'].includes(key)) desired = { [key]: value }
      else desired = { [key]: { 'source': 'APP', value } }

      let msg = { 'state': { desired } }
      try {
        await PubSub.publish(topic, msg)
      } catch (err) {
        // IF ERROR CATCH - SAVE TOPIC/MESSAGE AND SEND AGAIN AFTER RECONNECT
        if (err.message.includes('AMQJS0011E')) {
          // store.commit('set', ['doors', thing, 'mqttcon', false])
          store.commit('setToDoor', [thing, 'mqttcon', false])
          // AMQJS0011E Invalid state not connected.
          store.commit('set', ['reconnect', true])
          store.commit('set', ['reconnectMessage', { topic, msg }])
          self.init()
        }
      }
    } else {
      // DEMODOOR!!
      store.commit('updateMQTTReported', [thing, savestate])
    }
  }
}



export default self
