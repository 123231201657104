const failedRequests = (maxEntries) => ({
  namespaced: true,
  state: () => ({
    ptr: 0,
    log: []
  }),
  mutations: {
    push(state, record) {
      state.log[state.ptr] = record
      state.ptr = ++state.ptr % maxEntries
    }
  },
  getters: {
    asList(state) {
      const val = []

      let it = state.ptr
      do {
        if (state.log[it]) val.push(state.log[it])
        it = ++it % maxEntries
      } while (it !== state.ptr)

      return val
    }
  }
})

export default {
  namespaced: true,
  modules: {
    failedRequests: failedRequests(100),
  }
}
