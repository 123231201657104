<template lang="pug">

f7-page#Contact
  f7-navbar(:title='$t("Contact.Contact")', back-link='Back')

  f7-block

    .max960

      .tac

        i.icon-ask.large
        h1 {{$t("Contact.Contact")}}

        p {{$t("Contact.contact_p1")}}
        p {{$t("Contact.contact_p2")}}
        p {{$t("Contact.contact_p3")}}

      f7-block-title(medium) {{$t("Contact.Contact")}}
      f7-list( strong, no-hairlines)
        f7-list-item(external, :link='"mailto:"+email+"?subject=App-Feedback&body=Hello petWALK,%0D%0A%0D%0A...my feedback...%0D%0A%0D%0A"+sendmailcontent', :title='$t("Contact.sendmail")')
          i.icon-email.small(slot='media')
        f7-list-item(v-if="userState.value !== 'demo'", external, :link='"mailto:"+email+"?subject=Bug-Report&body=Hello petWALK,%0D%0A%0D%0A...my report...%0D%0A%0D%0A"+sendmailcontent', :title='$t("Contact.reportbug")')
          i.icon-bug.small(slot='media')
        f7-list-item(external, :link='"tel:"+tel', :title='$t("Contact.callus")+ tel')
          i.icon-telephone.small(slot='media')

      f7-block-title(medium) Social media
      f7-block
        .contact-links
          .flex

            a.external._hover(href="https://www.petwalk.at/",target="blank")
              i.icon-contact_web.medium(slot='media')
            a.external._hover(href="https://www.youtube.com/channel/UCNHDCebO0nB1cmlJwJa72yA",target="blank")
              i.icon-contact_yt.medium(slot='media')
            a.external._hover(href="https://facebook.com/petwalk.doors/",target="blank")
              i.icon-contact_fb.medium(slot='media')
            a.external._hover(href="https://twitter.com/petwalk_doors",target="blank")
              i.icon-contact_tw.medium(slot='media')
            a.external._hover(href="https://www.instagram.com/petwalk.doors/",target="blank")
              i.icon-contact_ig.medium(slot='media')

      f7-block-title(medium) {{$t("Contact.Address")}}
      f7-block
        p Petwalk Solutions GmbH und Co KG
        p Josef Huber Straße 6 Top 1
        p A-2620 Ternitz
        p AUSTRIA

</template>

<script>
import { mapState, mapGetters } from "vuex"
import redirectWhenUnsetUserState from "../libs/mixins/redirectWhenUnsetUserState"
let self = null
export default {
  beforeCreate() {
    self = this
  },
  name: "Contact",
  data() {
    return {
      tel: "+43263566937",
      email: "support@petwalk.at",
      mailcontent: {},
      sendmailcontent: ""
    }
  },
  watch: {},

  mounted() {
    let d = new Date()
    self.$set(self.mailcontent, "senddate", d)
    // self.$set(self.mailcontent, "device", self.platform);
    self.$set(self.mailcontent, "version", self.env.version)
    self.$set(self.mailcontent, "platform", navigator.platform)
    self.$set(self.mailcontent, "languageindevice", navigator.language)
    self.$set(self.mailcontent, "languagechosen", self.lang)
    self.$set(self.mailcontent, "appVersion", navigator.appVersion)
    // self.$set(self.mailcontent, "awsacc", self.$root.awsacc);
    self.$set(self.mailcontent, "userState", self.userState.value)
    self.$set(self.mailcontent, "user", self.user)
    self.$set(self.mailcontent, "doors", self.$_cp(self.doors))
    self.$set(self.mailcontent, "pets", self.$_cp(self.pets))
    self.$set(self.mailcontent, "userservices", self.$_cp(self.userservices))

    self.$set(self.mailcontent, "failedRequests", self.$_cp(self['instrumentation/failedRequests/asList']))

    delete self.mailcontent.doors.demodoor
    Object.keys(self.mailcontent.doors).forEach(function(key) {
      // self.$delete(self.mailcontent.doors[key], "flags")
      // self.$delete(self.mailcontent.doors[key], "reported")
      self.$delete(self.mailcontent.doors[key], "wishedflags")
      self.$delete(self.mailcontent.doors[key], "wished")
      self.$delete(self.mailcontent.doors[key], "weather")
      self.$delete(self.mailcontent.doors[key], "desired")
      self.$delete(self.mailcontent.doors[key], "location")
    })

    Object.keys(self.mailcontent.pets).forEach(function(key) {
      self.$delete(self.mailcontent.pets[key], "petImage")
      self.$delete(self.mailcontent.pets[key], "sortname")
    })

    let t1 = "%0D%0A%0D%0A--- F E E D B A C K - R E P O R T ---%0D%0A%0D%0A"
    self.sendmailcontent = t1 + JSON.stringify(self.mailcontent) + t1
    console.log(self.mailcontent)
  },
  computed: {
    ...mapState([
      'user',
      "platform",
      "pets",
      "lang",
      "userservices",
      "doors"
    ]),
    ...mapGetters(['instrumentation/failedRequests/asList'])
  },
  methods: {},
  mixins: [redirectWhenUnsetUserState]
}
</script>

<style scoped, lang="stylus">
#Contact

  .contact-links
    // a
    //   color:#424242!important
    .flex
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 480px
      margin: auto

    a
      &:hover
        opacity .8

    a
      &:active
        opacity .6

</style>
