import subscriptionList from "@/libs/subscriptions"
import {createDeviceClient} from "@/deviceWebsocket/client"
import {marshal, unmarshal} from "@/deviceShadow"

import Framework7 from 'framework7'

export function createShadowAbstractionClient(thingName: string, url: string) {
  const client = createDeviceClient(`ws://${url}:1234`)
  const reportedSubscriptions = subscriptionList()
  const desiredSubscriptions = subscriptionList()
  client.onCommandResponse(c => {
    if (c.DeviceInfo) {
      desiredSubscriptions.callEachWith(c.DeviceInfo[0])
      reportedSubscriptions.callEachWith(c.DeviceInfo[0])
    }
  })
  client.onUpdated(data => {
    desiredSubscriptions.callEachWith(data)
    reportedSubscriptions.callEachWith(data)
  })

  const transformAndCall = cb => (s, r) => cb(unmarshal(sanitizePayload(s)), r)

  return {
    get version() { return client.version },
    get ready() { return client.ready },
    desire(state) {
        return client.update(marshal(state))
    },
    onDesiredUpdated(cb) {
        desiredSubscriptions.push(transformAndCall(cb))
    },
    onReportedUpdated(cb) {
        reportedSubscriptions.push(transformAndCall(cb))
    },
    onError(cb) {
        return client.onError(cb)
    },
    close() {
        client.close()
    }
  }
}

/**
 * Fix some data layout differences between iot/cloud and websocket payloads
 */
function sanitizePayload(p) {
  return {
    ...p,
    ...('clb_state_error' in p && {clb_state_error: {value: p.clb_state_error, message: undefined}})
  }
}
