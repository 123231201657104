







import {Vue, Component, Prop, Action, Getter} from '@/vue-collection'

@Component
export default class extends Vue {
  @Prop(String) doorName
  @Prop(Object) credentials: {serial: string, secret: string}
  @Action _assignDoorToUserCloud
  @Action _postNewNameForCloudDoor
  @Getter _allDoors: {[key: string]: { serial: string }}

  async mounted() {
    try {
      const existingDoor = Object.values<any>(this._allDoors).find(d => d.serial === this.credentials.serial)
      if (existingDoor)
        await this._postNewNameForCloudDoor({
          deviceID: existingDoor.id,
          device_caption: this.doorName
        })
      else {
        const result = await this._assignDoorToUserCloud([this.credentials.serial, this.credentials.secret, this.doorName])
        this.UIResponder(result)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.$emit('finished')
    }

  }
}
