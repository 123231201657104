let store;

export function plugin(st) {
  store = st
}

export function cache(getter) {
  return methodStyleCache(() => store._vm, getter)
}

function methodStyleCache(getVm, getterFunction) {
  let watchers = {};
  return (...args) => {
    for (const w of Object.values(watchers)) w.teardown()
    watchers = {}

    // this injects vuex parameters into the outer function
    const innerMethod = getterFunction(...args);

    return (...innerArgs) => {
      const key = JSON.stringify(innerArgs)
      let watcher = watchers[key];

      if (!watcher) {
        watcher = computed(getVm(), () => innerMethod(...innerArgs));
        watchers[key] = watcher;
      }
      return watcher.lazyValue();
    }
  }
}

function computed(vm, func) {
  vm.$watch(func, { lazy: true });
  const watcher = vm._watchers[vm._watchers.length - 1];

  watcher.lazyValue = () => {
    if (watcher.dirty) {
      watcher.evaluate();
    }
    watcher.depend();
    return watcher.value;
  };
  return watcher;
}
