import env from "@/env"
import {v4 as uuid} from 'uuid'
import axios, {AxiosError} from "axios"
import awsCognito from "@/libs/awsCognito"
import store from '@/store/store'
import {type as hostDeviceType} from "@/hostDevice"

const awsPwInstance = axios.create();
async function credentials() {
  let config: any = await awsCognito.pwheader()
  return {
    Authorization: ((config || {}).headers || {}).Authorization,
    UserAccess: ((config || {}).headers || {}).UserAccess,
    'Client-Version': env.version
  }
}

function storeAndRethrow(meta: any, e: AxiosError) {
  store.commit('instrumentation/failedRequests/push', {
    ...meta,
    error: {
      message: e.message,
      trace: e.stack,
      status: e.response?.status,
      data: e.response?.data
    }})
  throw e
}

async function get(url, headers = {}) {
  try {
    return await awsPwInstance.get(`${env.mainurl}${url}`, {
      headers: {
        ...await credentials(),
        ...headers
      }
    })
  } catch (e) {
    storeAndRethrow({url: `${env.mainurl}${url}`}, e)
  }
}

async function post(url, data, headers = {}) {
  try {
    return await awsPwInstance.post(`${env.mainurl}${url}`, data, {
      headers: {
        ...await credentials(),
        ...headers
      }
    })
  } catch (e) {
    storeAndRethrow({url: `${env.mainurl}${url}`, data}, e)
  }
}

async function patch(url, data, headers = {}) {
  try {
    return await awsPwInstance.patch(`${env.mainurl}${url}`, data, {
      headers: {
        ...await credentials(),
        ...headers
      }
    })
  } catch (e) {
    storeAndRethrow({url: `${env.mainurl}${url}`, data}, e)
  }
}

async function put(url, data, headers = {}) {
  try {
    return await awsPwInstance.put(`${env.mainurl}${url}`, data, {
      headers: {
        ...await credentials(),
        ...headers
      }
    })
  } catch (e) {
    storeAndRethrow({url: `${env.mainurl}${url}`, data}, e)
  }
}

async function del(url, headers = {}) {
  try {
    return await awsPwInstance.delete(`${env.mainurl}${url}`, {
      headers: {
        ...await credentials(),
        ...headers
      }
    })
  } catch (e) {
    storeAndRethrow({url: `${env.mainurl}${url}`}, e)
  }
}


export async function createJob(command, thingName, payload) {
  const id = uuid();
  const jobId = `${command}_${id}`

  await post(`devices/${thingName}/jobs`, {
    jobId,
    payload
  })

  return jobId
}

export async function getDefaultAlexaDevice() {
  const result = await get(`default-alexa-device`)
  //@ts-ignore
  return result.data
}

export async function setDefaultAlexaDevice(doorId) {
  await post(`default-alexa-device`, {
    doorId
  })
}

export async function getUpdateInfo() {
  const result = await get(`update_info`)
  //@ts-ignore
  return result.data
}

export async function confirmFirmwareUpdate(data: {deviceID: number, swVersion: string}) {
  await post(`update_confirm`, data)
}

export async function getNotificationSettings() {
  const result = await get(`notifications/settings`)
  //@ts-ignore
  return result.data
}

export async function updateNotificationSettings(settings: object) {
  await patch(`notifications/settings`, {settings})
}

export async function getTimeline(id: number) {
  const result = await get(`door_events?deviceID=${id}&intervalDays=14`)
  //@ts-ignore
  return result.data
}

export async function updateLanguage(lang: string) {
  await put(`language`, {lang})
}

export async function assignDoor(data: {
  device_serialnr: string,
  device_secret: string,
  device_caption: string,
  cognitoIdentityID: string
}) {
  await post(`device_assign`, data)
}

export async function removeDoor(id: number) {
  await post(`device_remove?deviceID=${id}`, {})
}

export async function updateDeviceName(data: {deviceID: number, device_caption: string}) {
  await post(`device_assign`, data)
}

export async function updateDeviceLocation(data: {location: string, doorID: number}) {
  await post(`door_location`, data)
}

export async function updateNotificationToken(data: {
  previousToken: string,
  currentToken: string,
  os: string
}) {
  await post(`refresh_notify_token`, data)
}

export async function putCognitoIdentity(identity) {
  await put(`identity`, {cognitoIdentityId: identity})
}

export async function deleteUser() {
  await del('user')
}
