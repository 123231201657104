





import Vue from 'vue'
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import {nextAnimationFrame} from "@/libs/utils"

const transitionString = 'height 0.3s ease-out'

@Component
class PwCollapse extends Vue {
  @Prop() open: boolean
  height: string | null = null
  transition: string = transitionString
  collapsed: boolean = !this.open

  @Watch('open') async openWatcher() {
    const element = this.$refs.collapsible as any
    const height = element.scrollHeight

    if (this.open) {
      this.height = `${height}px`
      this.collapsed = false
    } else { // collapse
      this.transition = '';

      await nextAnimationFrame()

      this.height = `${height}px`
      this.transition = transitionString

      await nextAnimationFrame()

      this.height = null
      this.collapsed = true
    }
  }

  onTransitionFinished() {
    this.height = null
  }
}
export default PwCollapse
