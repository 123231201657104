<template lang="pug">
f7-panel#leftpanel(left='', cover, theme-dark)
  f7-page(data-page="doorlist")

    f7-block-title
      span {{$t("DoorList.alldoors")}}
      span(v-if="uiloader.refreshDoors")
        .pwloader._mm.margin-left

    p.tac(v-if="$_size($store.state.doors) === 0") No doors available!
    f7-list(inset, strong, no-hairlines)
        li.alldoors(v-for="(item, index) in _sortedDoorList")
          .item-link.item-content.panel-close(href="#" @click="$store.commit('set',['currentdoor',item.thing_name])")
            .item-media
              img.dooricon(:src="$img.minidoor")
            .item-inner
              .item-title  {{item.Caption}} {{item.case}}

        li.alldoors(v-if="$_size($store.state.doors) === 0")

          a.item-link.item-content.panel-close(href="/newdoor/")
            .item-media
              img.dooricon(:src="$img.minidoor")
            .item-inner
              .item-title Add New Door

</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  name: "DoorList",
  data: () => ({}),
  computed: {
    ...mapState(['uiloader']),
    ...mapGetters(["_sortedDoorList"])
  }
}
</script>

<style scoped lang="stylus">
#leftpanel
  .ios .block
    padding-right: 2px!important;
    padding-left: 2px!important;
    margin-right: 2px!important;
    margin-left: 2px!important;
  .dooricon
    width: 36px
    height: 36px



</style>

