const VERSION_REGEX = /(\d+).(\d+).(\d+)/

export interface Version {
  major: number
  minor: number
  patch: number
  toString(): string

  eq(other: Version): boolean
  gt(other: Version): boolean
  lt(other: Version): boolean
}

export function version(versionString: string): Version {
  const match = VERSION_REGEX.exec(versionString)
  if (!match) throw new Error('Invalid version string: ' + versionString)

  const [major, minor, patch] = match.slice(1)
  return Object.freeze({
    major: parseInt(major),
    minor: parseInt(minor),
    patch: parseInt(patch),
    toString(): string {
      return `${major}.${minor}.${patch}`
    },

    eq(other: Version): boolean {
      return this.major == other.major && this.minor == other.minor && this.patch == other.patch
    },

    gt(other: Version): boolean {
      if (this.major > other.major) return true
      if (this.major < other.major) return false
      // this.major == other.major

      if (this.minor > other.minor) return true
      if (this.minor < other.minor) return false
      // this.minor == other.minor

      return this.patch > other.patch
    },

    lt(other: Version): boolean {
      if (this.major > other.major) return false
      if (this.major < other.major) return true
      // this.major == other.major

      if (this.minor > other.minor) return false
      if (this.minor < other.minor) return true
      // this.minor == other.minor

      return this.patch < other.patch
    }
  })
}
