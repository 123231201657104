<template lang="pug">
  svg(viewBox="-60 -60 120 120" class="pulse")
    circle(cx="0" cy="0" r="20" fill="rgba(145, 172, 37, 1)")
    circle(v-for="i in [1, 2, 3]" cx="0" cy="0" :r="20 + ((i * (i+1)) / 2 * 12.5)" :stroke="`rgba(145, 172, 37, ${1 - (0.3 * i)})`" stroke-width="0.5" fill="none")
    text(x="-17.5" y="2.5" textLength="35" style="font: 8px sans-serif; fill: white") scanning...
</template>

<script>
  export default {
    name: "PwPulse.vue"
  }
</script>

<style lang="stylus" scoped>
  .pulse
    position absolute
    top 0
    left 0
    margin auto
    width 100%
    height 100%
</style>
