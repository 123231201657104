<template lang="pug">
#AssignNewChipPop
  .sf7-block
    f7-block-title {{$t("Assigns.assignchip")}}
    div(v-if="_settingsDoor.wscon")
      div(v-if="RFID[_settingsDoorId]")
        div(v-if="[AssignState.waiting].includes(assignState)")
          pw-pulse
        template(v-else)
          .mblock-header
            p.tac.cutat480
              i.icon-rfidsymbol.medium
            p.tac
              span.bold {{$t("Assigns.assignchip")}}
              span  (v{{_settingsDoor.reported.version}})

          div(v-if="assignState === AssignState.unset")

            f7-list(inset)
              f7-list-item(v-for="(comp,idx) in _doorPetComponentRelation[_settingsDoorId]" v-if="comp.online === 'online'")
                span(slot="title") {{comp.caption || idx}}
                  span.bold.color-red(v-if="!comp.online") &nbsp; (offline)
                span(slot="after")
                  pw-button(texter="Assigns.assignchip", size="ss", col="outlinegreen", position="inline", @click="AssignChip(comp.lcId)", :block="!comp.online",
                    v-if="assignmentRecords({ door: _settingsDoor.id, component: comp.lcId, pet: petId }).length === 0")

      span(v-else)
        .tac
          .pwloader._mm
          p.padding.bold {{$t("Assigns.waitforchips")}}

    span(v-else-if="!_settingsDoor.wscon")
      p.tac.bold.color-red {{$t("Assigns.pleasechecklocal")}}

</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import PwPulse from '@/components/PwPulse'
import {tagList, startLearn} from '@/devices/rfid'

const AssignState = Object.freeze({
  unset: Symbol('unset'),
  waiting: Symbol('waiting'),
  spinner: Symbol('spinner'),
  assigning: Symbol('assigning'),
  ready: Symbol('ready')
})

let self = null;
export default {
  beforeCreate() {
    self = this;
  },
  props: ['petId'],
  components: { PwPulse },
  name: "AssignNewChipPop",
  data: () => ({
    assignState: AssignState.unset,
    toUseCompId: "",
    watchedComp: "",
    learnMode: ""
  }),
  computed: {
    ...mapState(["RFID", "pets"]),
    ...mapGetters([
      "text",
      "_doorPetComponentRelation",
      "assignmentRecords"
    ]),
    pet() {
      return self.pets[this.petId];
    }
  },

  methods: {
    ...mapActions(["assignPetToExistingToken"]),
    //! *************************************************/
    async AssignChip(compId) {
      if (self.RFID[self._settingsDoorId]) {
        let freeslots = Array.from(Array(20), (x, i) => i);

        const tagListForComponent = self.RFID[self._settingsDoorId].RFIDTagList.find(x => x.localComponentId === compId)
        self.$_removeDoubles(freeslots, tagListForComponent.memIdx)

        self.freeslot = freeslots[0];
        self.toUseCompId = compId;

        self.beforeLearnListLength = tagListForComponent.memIdx.length

        if (self.freeslot >= 0 && self.freeslot < 20) {
          try {
            self.assignState = AssignState.waiting
            const result = await startLearn(self._settingsDoorId, compId, self.freeslot)
            self.assignState = AssignState.unset

            if (result === 'ok') {
              await self.assignPetToCloudDoor()
              self.UIResponder({
                title: self.$t('Assigns.notifications.chip-learned.success-title'),
                text: self.$t('Assigns.notifications.chip-learned.success-text-extern'),
                err: false
              })
            } else if (result === 'error_duplicate')
              self.resetWithError({
                title: self.$t('Assigns.notifications.learn-started.error-title'),
                text: self.$t('Assigns.notifications.learn-started.duplicate-error'),
              })
            else if (result === 'error_timeout')
              self.resetWithError({
                title: self.$t('Assigns.notifications.learn-started.error-title'),
                text: self.$t('Assigns.notifications.learn-started.timeout-error'),
              })
            else
              self.resetWithError({
                title: self.$t('Assigns.notifications.learn-started.error-title'),
                text: self.$t('Assigns.notifications.learn-started.generic-error'),
              })
          } catch (e) {
            console.error(e)
            self.resetWithError({
              title: self.$t('Assigns.notifications.learn-started.error-title'),
              text: self.$t('Assigns.notifications.learn-started.generic-error'),
            })
          }
        }
      } else {
        window.LOG.err("no door RFIDLISTED");
      }
    },

    //! *************************************************/
    async assignPetToCloudDoor() {
      let doorId = self._allDoors[self._settingsDoorId].id;

      let resp = await this.assignPetToExistingToken({
        doorId,
        componentId: self.toUseCompId,
        petId: this.petId,
        idx: this.freeslot
      })

      resp.title = "Assigns.notifications.pet-assigned-existing-chip.title";
      self.UIResponder(resp);

      self.$f7.popup.close("#PetsDoorSets", true);
    },

    resetWithError(message) {
      this.assignState = AssignState.unset;
      this.UIResponder({
        ...message,
        err: true
      });
    }
  },

  mixins: [{
    computed: {
      AssignState: () => AssignState
    }
  }]
};
</script>

<style scoped lang="stylus">

</style>
