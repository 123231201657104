import {observe, publish} from "@/deviceShadow/mqtt"
import {IoTJobsDataPlane} from "aws-sdk"
import {createJob} from "@/api"

export interface JobsClient {
  execute(command: string, payload: object): Promise<any>
  track(requestId: string): Promise<any>
}

export function createClient(thingName: string) {
  let jobs = {}

  const subscription = observe<IoTJobsDataPlane.GetPendingJobExecutionsResponse>([
     `$aws/things/${thingName}/jobs/get/#`
  ]).filter(p => !!p.inProgressJobs && !!p.queuedJobs).subscribe((payload) => {
    subscription.unsubscribe()
    payload.queuedJobs.forEach(j =>
      jobs[j.jobId] = {
        id: j.jobId,
        status: 'QUEUED',
        task: awaitCompletion(thingName, j.jobId)
      })
    payload.inProgressJobs.forEach(j =>
      jobs[j.jobId] = {
        id: j.jobId,
        status: 'IN_PROGRESS',
        task: awaitCompletion(thingName, j.jobId)
      })
  })
  setTimeout(() => publish(`$aws/things/${thingName}/jobs/get`, {}).catch(console.error), 500)

  const jobNotifications = observe([
    `$aws/things/${thingName}/jobs/notify`
  ]).subscribe((payload: any) => {
    Object.entries<IoTJobsDataPlane.JobExecutionSummaryList>(payload.jobs).flatMap(([k, jobs]) =>
      jobs.map(j => ({
        ...j,
        status: k
      }))
    ).forEach(j => {
      jobs[j.jobId] = {
        id: j.jobId,
        status: j.status,
        task: awaitCompletion(thingName, j.jobId)
      }
    })
  }, console.error)


  return {
    async execute(command, payload) {
      const requestId = await createJob(command, thingName, JSON.stringify(payload))

      const task = awaitCompletion(thingName, requestId)

      jobs[requestId] = {
        id: requestId,
        status: 'QUEUED',
        task
      }

      return task
    },
    track(requestId) {
      if (!(requestId in jobs)) throw new Error('job id not found in storage')
      return jobs[requestId].task
    },
    close() {
      jobNotifications.unsubscribe()
      // TODO: anything else we need to clean up?
    }
  }
}

function awaitCompletion(thingName, jobId) {
  return new Promise((resolve, reject) => {
    const subscription = observe<JobExecutionTerminationEvent>([
      `$aws/events/jobExecution/${jobId}/#`,
    ]).subscribe(payload => {
      console.log(jobId, 'updated', payload)
      // Since the job always only targets one thing we can be sure that the event belongs to the correct thing.
      if (payload.status === 'SUCCEEDED') {
        subscription.unsubscribe()
        resolve(payload.statusDetails)
      }
      else if (['REJECTED', 'TIMED_OUT', 'CANCELED', 'FAILED'].includes(payload.status)) {
        subscription.unsubscribe()
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          status: payload.status,
          details: payload.statusDetails
        })
      }
    })
  })
}

interface JobExecutionTerminationEvent {
  eventType: "JOB_EXECUTION",
  eventId: string,
  operation: "succeeded" | "failed" | "rejected" | "canceled" | "removed" | "timed_out",
  jobId: string,
  thingArn: string,
  status: "QUEUED" | "IN_PROGRESS" | "FAILED" | "SUCCEEDED" | "CANCELED" | "TIMED_OUT" | "REJECTED" | "REMOVED",
  statusDetails: { [key: string]: string }
  clientToken: string,
  timestamp: number,
}
