import {createClient as createShadowClient} from "./shadow"
import {makeBinFromFlags, makeFlagsFromBin} from "./flags"
import {ThingShadow, ValueWithSource} from "./Shadow.d"

export interface DeviceShadowHandler {
  refresh(): Promise<void>
  ready: Promise<void>
  desire(data: any, requestId?: string): Promise<void>
  onDesiredUpdated(cb: (shadowPart: Partial<any>, requestId?: string) => void): ZenObservable.Subscription
  onReportedUpdated(cb: (shadow: any, requestId?: string) => void): ZenObservable.Subscription
  onError(cb: (error: any) => void): ZenObservable.Subscription
  onClose(cb: () => void): ZenObservable.Subscription
  close(): void
}

export function createClient(thingName: string): DeviceShadowHandler {
  const client = createShadowClient<ThingShadow>(thingName)

  const transformAndCall = cb => (s, r) => cb(unmarshal(mapShadowToRecord(s)), r)

  return {
    refresh: client.refresh,
    ready: client.ready,
    async desire(data: any, requestId = null) {
      const mapped = Object.fromEntries(Object.entries<any>(marshal(data)).map(([k, v]) => {
        if (plainValues.includes(k)) return [k, v]
        else return [k, { source: 'APP', value: v }]
      })) as Partial<ThingShadow>

      return client.desire(mapped, requestId)
    },
    onDesiredUpdated(cb: (shadowPart: Partial<any>, requestId?: string) => void): ZenObservable.Subscription {
      return client.onDesiredUpdated(transformAndCall(cb))
    },
    onReportedUpdated(cb: (shadow: any, requestId?: string) => void): ZenObservable.Subscription {
      return client.onReportedUpdated(transformAndCall(cb))
    },
    onError(cb) {
      return client.onError(cb)
    },
    onClose(cb) {
      return client.onClose(cb)
    },
    close(): void {
      client.close()
    }
  }

}

const plainValues = ['pets', 'components', 'clb_cfg_rest_api']

function mapShadowToRecord(shadow: Partial<ThingShadow>): any {
  return Object.fromEntries(Object.entries<any>(shadow).map(([k, v]) => {
    if (k === 'clb_state_door_pos') return [k, v.value.toLowerCase()]
    else if (isValueWithSource(v)) return [k, v.value]
    else return [k, v]
  }))
}

function isValueWithSource(v: any | ValueWithSource<any>): boolean {
  return v instanceof Object && ('source' in v) && ('value' in v)
}

export function marshal(state): ThingShadow {
  return {
    ...state,
    ...('clb_cfg_flags' in state) && {clb_cfg_flags: makeBinFromFlags(state.clb_cfg_flags)},
    ...('pets' in state) && {pets: state.pets.map(petObjectToRecord)},
    ...('components' in state) && {components: state.components.map(componentObjectToRecord)}
  }
}

export function unmarshal(state) {
  return {
    ...state,
    ...('clb_cfg_flags' in state) && {clb_cfg_flags: makeFlagsFromBin(state.clb_cfg_flags)},
    ...('pets' in state) && {pets: state.pets.map(petRecordToObject)},
    ...('components' in state) && {components: state.components.map(componentRecordToObject)}
  }
}

function petRecordToObject([id, name, species, settings, updatedAt, deleted]) {
  return {id, name, species, settings, updatedAt, petImage: '', delete: deleted}
}

function componentRecordToObject([id, caption, confirmed, manufacturer, type, serial, swVersion, hwVersion, mountingPosition, mountingType, online, petIds]) {
  return {id, caption, confirmed, manufacturer, type, serial, swVersion, hwVersion, mountingPosition, mountingType, online, petIds}
}

function petObjectToRecord({id, name, species, settings, updatedAt, delete: deleted}) {
  return [id, name, species, settings, updatedAt, deleted]
}

function componentObjectToRecord({id, caption, confirmed, manufacturer, type, serial, swVersion, hwVersion, mountingPosition, mountingType, online, petIds}) {
  return [id, caption, confirmed, manufacturer, type, serial, swVersion, hwVersion, mountingPosition, mountingType, online, petIds]
}
