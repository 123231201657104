
<template lang="pug">

#FlagScreenHead
  .topheadflags
    .gripline
    .fl
      i.icon-entry-small.flag(:class="{ _active: _reportedFlags.INFLAG }")
      span.bold.inout(:class="{ _active: _reportedFlags.INFLAG }") {{$t("IN")}}
      i.icon-exit-small.flag(:class="{ _active: _reportedFlags.OUTFLAG }")
      span.bold.inout(:class="{ _active: _reportedFlags.OUTFLAG }") {{$t("OUT")}}
    .fr
      //- span.icon-on-off-small( :class="{ _active: reported.clb_state_opmode === 'on' }")
      i.icon-lock-small.flag(:class="{ _active: _reportedFlags.ACCESSFLAG }")
      i.icon-time-small.flag(:class="{ _active: _reportedFlags.TIMEFLAG }")
      i.icon-dark-small.flag(:class="{ _active: _reportedFlags.DARKFLAG }")
      i.icon-rain-small.flag(:class="{ _active: _reportedFlags.RAINFLAG }")
      i.icon-onoff-small.flag(:class=`{
        _active: _reported.clb_state_opmode.toLowerCase() === 'on',
        _off_active: _reported.clb_state_opmode.toLowerCase() !== 'on',
        _disabled_active: _reported.clb_state_error.value != 0
      }`)

  .clear
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FlagScreenHead",
  data: function() {
    return {
      d: {}
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["_reportedFlags","_reported"])
  },
  methods: {
  }
};
</script>

<style scoped lang="stylus">

.topheadflags
  position: relative
  height: 48px;
  line-height: 48px;
  background: var(--f7-list-bg-color)

  padding: 4px 12px
  cursor: pointer
  box-shadow: 0 3px 3px rgba(0,0,0,.1);
  margin-bottom: 0

  &:active
    filter grayscale(.1)

  span,i
    margin 0 3px

    &._active,
    &.icon._active
      color: #a4c121 !important
    &._off_active,
    &.icon._off_active
      color: #ee5555 !important
    &._disabled_active
    &.icon._disabeld_active
      color: #808080 !important

  .inout
    color #808080
    margin 0
.gripline
  background: #cecece;
  width: 32px;
  height: 6px;
  position: absolute;
  left: 0;
  top: 6px;
  right: 0;
  margin: auto;
  border-radius: 12px;

</style>
