import Framework7 from "framework7";

export function isLocationAccessAuthorized() {
  return new Promise((resolve,reject) => {
    cordova.plugins.diagnostic.getLocationAuthorizationStatus(function (status) {
      console.log('location permission status', status, cordova.plugins.diagnostic.permissionStatus)
      switch (status) {
        case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
        case cordova.plugins.diagnostic.permissionStatus.RESTRICTED:
          resolve(false)
          break
        case cordova.plugins.diagnostic.permissionStatus.GRANTED:
        case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
          resolve(true)
          break
        case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
        default:
          resolve(undefined)
          break
      }
    }, reject)
  })
}

export async function requestLocationAccessAuthorization() {
  if (Framework7.device.ios) {
    const state = await isLocationAccessAuthorized()
    if (state !== undefined) return state
  }
  return new Promise((resolve, reject) => {
    cordova.plugins.diagnostic.requestLocationAuthorization(status => {
      console.log('location permission request result', status)
      switch (status) {
        case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
        case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
        case cordova.plugins.diagnostic.permissionStatus.RESTRICTED:
          resolve(false)
          break
        case cordova.plugins.diagnostic.permissionStatus.GRANTED:
        case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
          resolve(true)
          break
      }
    }, reject, cordova.plugins.diagnostic.locationAuthorizationMode.WHEN_IN_USE)
  })
}
