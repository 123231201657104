import { render, staticRenderFns } from "./SettingsListItem.vue?vue&type=template&id=64d2234e&scoped=true&lang=pug&"
import script from "./SettingsListItem.vue?vue&type=script&lang=ts&"
export * from "./SettingsListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d2234e",
  null
  
)

export default component.exports