
<template lang="pug">

f7-popup#ChangeLanguagePop(name="ChangeLanguagePop")
  f7-page.white
    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small

    f7-block

      h3.tac
        i.icon-world.large
      p.tac {{$t("ChangeLanguage.language")}}
      p.tac.bold {{$t("ChangeLanguage.chooseyourlanguage")}}

      f7-list( strong, no-hairlines)

        f7-list-item(radio, value='en', name='language-radio', :checked="setlang === 'en'", title='English', @change="setlang = 'en'")
          img(slot='media', :src="$img.lang.en")
        f7-list-item(radio, value='de', name='language-radio', :checked="setlang === 'de'", title='Deutsch', @change="setlang = 'de'")
          img(slot='media', :src="$img.lang.de")
        f7-list-item(radio, value='fr', name='language-radio', :checked="setlang === 'fr'", title='Français', @change="setlang = 'fr'")
          img(slot='media', :src="$img.lang.fr")

      pwButton(texter="ChangeLanguage.savelanguage", loader="langChanger", size="m", col="outline", position="center", @click="postUserLanguage()", :block="setlang === lang")

</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"

let self = null
export default {
  beforeCreate() {
    self = this
  },
  name: "ChangeLanguagePop",
  data: function() {
    return {
      setlang: ""
    }
  },
  created() {
    self.setlang = self.lang === "unset" ? "en" : self.lang
  },

  mounted() {},

  computed: {
    ...mapState(["lang"])
    // ...mapGetters([])
  },

  methods: {
    ...mapActions(["_postUserLanguage"]),

    async postUserLanguage() {
      self.$f7.dialog.progress()
      self.UIResponder(await self._postUserLanguage(self.setlang))

      self.$f7.dialog.close()
      self.$f7.popup.close("#ChangeLanguagePop")
    }
  }
}
</script>

<style scoped lang="stylus">



img
  width: 42px
  height: 42px

</style>
