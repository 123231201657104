


















import {Vue, Component, Prop, Watch, State} from '@/vue-collection'

@Component({
  name: 'opt-door-contact-list-item'
})
export default class extends Vue {
  @Prop(Object) door
  @Prop(Boolean) disabled
  @State uiloader
  selectValue = this.door.reported.clb_cfg_did

  @Watch('selectValue') onValueChanced(nv) {
    this.$store.commit('loader', ['setoptDoorContact', true])
    this.$store.dispatch(`doors/${this.door.thing_name}/desire`, {
      clb_cfg_did: nv
    }).catch(console.error)
  }
  @Watch('door.reported.clb_cfg_did') onReportedChanged(nv) {
    this.$store.commit('loader', ['setoptDoorContact', false])
    this.selectValue = nv
  }
}
