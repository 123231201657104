

































































































import {Vue, Component, Prop, State, Mutation} from '@/vue-collection'

@Component
export default class extends Vue {
  @Prop(String) mode
  @Prop(Boolean) proposeDemo

  showPassword: boolean = false

  name = "UserCredsComp"
  SignInData = {
    username: "",
    password: "",
    verificationcode: "",
    newpassword: ""
  }
  RegisterData = {
    email: "",
    username: "",
    password: ""
  }
  forgotverifystate = false
  logstate = "signin"
  confirmstate = false
  UserType = "text"

  @State uiloader
  @State user
  @Mutation initializeDemoMode

  async signInAWSUser() {
    this.confirmstate = false;
    this.$store.commit("loader", ["UCloader", true]);
    setTimeout(async () => {
      this.UserType = this.$_validateEmail(
        this.SignInData.username.trim().toLowerCase()
      )
        ? "email"
        : "text";
      try {
        let resp = await this.$awsCognito.signInAWSUser(
          this.SignInData.username,
          this.SignInData.password
        );

        if (resp.err) {
          if (resp.code === "UserNotConfirmedException") {
            this.confirmstate = true;
          }
          resp.title = "SignIn";
          this.UIResponder(resp);
        }
      } catch (err) {
        window.LOG.err(err);
      }
      this.$store.commit("loader", ["UCloader", false]);
    }, 300);
  }
  //* *************************************************

  async signUpAWSUser() {
    this.$store.commit("loader", ["UCloader", true]);
    try {
      let resp = await this.$awsCognito.signUpAWSUser(
        this.RegisterData.email,
        this.RegisterData.username,
        this.RegisterData.password
      );

      if (!resp.err) {
        resp.title = "You successfully signed up";
        resp.text =
          resp.text || "Please check your account and verify your email.";
        this.logstate = "signin";
        this.SignInData.username = this.$_cp(this.RegisterData.username);
        this.SignInData.password = "";
      }

      this.UIResponder(resp);
    } catch (err) {
      window.LOG.err(err);
    }
    this.$store.commit("loader", ["UCloader", false]);
  }
  //* *************************************************

  async resendConfirmation() {
    this.$store.commit("loader", ["UCloader_resendEmail", true]);
    try {
      let resp = await this.$awsCognito.resendConfirmation(
        this.SignInData.username
      );
      window.LOG.log(resp);
      resp.title = "Resend Confirmation";
      this.UIResponder(resp);
      this.confirmstate = false;
    } catch (err) {
      window.LOG.err(err);
    }
    this.$store.commit("loader", ["UCloader_resendEmail", false]);
  }
  //* *************************************************

  async forgotAWSPassword() {
    this.$store.commit("loader", ["UCloader", true]);
    try {
      let resp = await this.$awsCognito.forgotAWSPassword(
        this.SignInData.username
      );
      window.LOG.log(resp);
      resp.title = "ForgotPassword";
      this.UIResponder(resp);
      if (!resp.err) this.forgotverifystate = true;
    } catch (err) {
      window.LOG.err(err);
    }
    this.$store.commit("loader", ["UCloader", false]);
  }
  //* *************************************************

  async sendVerificationCodeForAWSPassword() {
    this.$store.commit("loader", ["UCloader", true]);
    try {
      let resp = await this.$awsCognito.sendVerificationCodeForAWSPassword(
        this.SignInData.username,
        this.SignInData.verificationcode,
        this.SignInData.newpassword
      );
      window.LOG.log(resp);

      resp.title = "SubmitPassword";
      this.UIResponder(resp);

      if (!resp.err) {
        this.forgotverifystate = false;
        this.logstate = "signin";
      }
    } catch (err) {
      window.LOG.err(err);
    }
    this.$store.commit("loader", ["UCloader", false]);
  }
}
