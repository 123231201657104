import Vue from 'vue'
import utils from '@/libs/utils'
import * as _ from 'lodash'
import defaultState from '@/store/initialState'
import dayjs from 'dayjs'
import {remove} from "@/devices"
import store from "@/store/store"
import {tagList} from "@/devices/rfid"
import {MutationTree} from "vuex"
import {enumerate} from "@/libs/functional"

let doorModel = require('@/models/doorModel.json')
let petModels = require('@/models/petModels.json')
let self = utils

export default {
  resetall(state: any) {
    for (const thingName of Object.keys(state.doors)) remove(store, thingName)

    const s = defaultState()
    delete s.device
    for (const k in s) {
      Vue.set(state, k, s[k])
    }
    state.userState.value = 'unset'
  },
  set(state: any, path: any[]) {
    const [k, v] = path.splice(-2)
    const el = path.reduce((acc, cur) => acc && acc[cur], state)
    if (el) Vue.set(el, k, v)
  },
  device(state: any, device: 'android' | 'ios' | 'desktop') {
    console.log('device updated', device)
    state.device = device
  },
  setToDoor(state: any, path: any[]) {
    const [k, v] = path.splice(-2)
    const el = path.reduce((acc, cur) => acc && acc[cur], state.doors)
    if (el) Vue.set(el, k, v)
  },
  unset(state: any, v: any) {
    let a: any, b: any, c: any
    [a, b, c] = v
    if (typeof c !== 'undefined') Vue.delete(state[a][b], c);
    else Vue.delete(state[a], b);
  },

  removeDoor(state: any, thingName: any) {
    // window.LOG.orange(thingName);
    let doorarrs = self.$_findRemove(Object.keys(state.doors), thingName)
    window.LOG.orange(doorarrs);
    let newdoorTN = doorarrs[doorarrs.length - 1] || 'demodoor'
    window.LOG.orange(newdoorTN);
    if (state.currentdoor === thingName) {
      state.currentdoor = newdoorTN
    }
    if (state.setdoor === thingName) {
      state.setdoor = newdoorTN
    }
    Vue.delete(state.doors, thingName);

  },

  setRFID(s, rep) {
    let doorId = rep.thing
    let list = rep.list
    // delete rep._idforws;
    if (!s.RFID[doorId]) {
      Vue.set(s.RFID, doorId, {})
    }
    for (let key in list) {
      // LOG.log(key, list[key])
      if (key === 'RFIDTagList') {
        // CHECK IF IS OBJECT OR INTEGER // IF INT : V1 ELSE V2
        // window.LOG.orange(typeof list.RFIDTagList[0]);
        if (typeof list.RFIDTagList[0] === "number") {
          Vue.set(s.RFID[doorId], key, [{ localComponentId: 'intern', memIdx: list[key] }])
        } else if (list.RFIDTagList.length === 0) {
          Vue.set(s.RFID[doorId], key, [{ localComponentId: 'intern', memIdx: [] }])
        } else {

          Vue.set(s.RFID[doorId], key, list[key])
        }
      } else {
        Vue.set(s.RFID[doorId], key, list[key])
      }
      s.RFID._updateval = key
    }
    s.RFID._update++
  },

  setZIGBEE(s, rep) {
    let doorId = rep.thing
    let list = rep.list

    if (!s.ZIGBEE[doorId]) {
      Vue.set(s.ZIGBEE, doorId, {})
    }

    for (let key in list) {
      Vue.set(s.ZIGBEE[doorId], key, list[key])
      s.ZIGBEE._updateval = key
    }

    s.ZIGBEE._update++
  },

  loader(state: any, v: any) {
    let a: any, b: any
    [a, b] = v
    Vue.set(state.uiloader, a, b)
  },

  updateMQTTWish(state: any, v: any) {
    // UPDATES WISCHED STATE OF DOOR FROM MQTT AND WS!!
    let thing: any, savestate: any
    [thing, savestate] = v
    if (savestate.clb_cfg_petSetting && typeof savestate.clb_cfg_petSetting === 'string') { savestate.clb_cfg_petSetting = JSON.parse(savestate.clb_cfg_petSetting) }
    for (let vi in savestate) {
      Vue.set(state['doors'][thing]['wished'], vi, savestate[vi])
    }
    state.uiloader.mqttloader = false

  },

  setWebsocketApiVersion(state: any, [thing, version]: [string, string]) {
    state.doors[thing].ws_version = version
  },

  updateMQTTReported(state: any, v: any) {
    // UPDATES REPORTED STATE OF DOOR FROM MQTT AND WS!!
    let thing: any, savestate: any
    [thing, savestate] = v

    if (savestate.clb_cfg_petSetting && typeof savestate.clb_cfg_petSetting === 'string') savestate.clb_cfg_petSetting = JSON.parse(savestate.clb_cfg_petSetting)
    for (let vi in savestate) {
      Vue.set(state.doors[thing]['reported'], vi, savestate[vi])
    }
  },

  updateFlagsReported(state: any, v: any) {
    let thing: any, flags: any
    [thing, flags] = v

    Vue.set(state.doors[thing], 'reportedflags', self.$_cp(flags))
    Vue.set(state.doors[thing], 'wishedflags', self.$_cp(flags))
  },

  updateFlagsWished(state: any, v: any) {
    let thing: any, flags: any
    [thing, flags] = v

    Vue.set(state.doors[thing], 'wishedflags', flags)
  },

  delete(state: any, v: any) {
    let a: any, b: any
    [a, b] = v
    Vue.delete(state[a], b)
  },

  report(state: any, v: any) {
    // LOG.log('report', v)
    let doorId: any, whichKey: any, value: any
    [doorId, whichKey, value] = v

    Vue.set(state.doors[doorId].reported, whichKey, value)
  },

  wish(state: any, v: any) {
    let thing: any, b: any, c: any, d: any;
    [thing, b, c] = v

    Vue.set(state.doors[thing].wishedflags, b, c)
  },
  initializeDemoMode(state: any) {
    state.doors = { demodoor: doorModel } // TODO: use module
    state.pets = petModels
    state.setdoor = 'demodoor'
    state.currentdoor = 'demodoor'
    this.commit('userState/startDemo')
  },
  demoswitch(state: any) {

    state.doors.demodoor.reportedflags = self.$_cp(state.doors.demodoor.wishedflags)
    state.doors.demodoor.reported = self.$_cp(state.doors.demodoor.wished)
  },

  mergePets(state: any, pets: any[]) {
    const existingPets = state.pets
    pets.forEach(p => {
      if (!p.id) return
      const mergedDoors = Object.values({
        ...existingPets[p.id] && _.keyBy(existingPets[p.id].doors, 'doorID'),
        ..._.keyBy(p.doors, 'doorID')
      })

      if (!existingPets[p.id] || !existingPets[p.id].updatedAt || existingPets[p.id].updatedAt < p.updatedAt)
        Vue.set(existingPets, p.id, { ...existingPets[p.id], ...p, doors: mergedDoors })
        if (!existingPets[p.id].updatedAt) existingPets[p.id].updatedAt = dayjs().unix() // sanitize records without timestamp
      else {
        Vue.set(existingPets[p.id], 'doors', mergedDoors) // this should band-aid the synchronization problem for nested data?
      }
    })

    // TODO: throw out data that doesn't fit. Maybe alert user to the problem?
    const petList = Object.values<any>(existingPets)
    if (petList.length > 26) {
      // We have too many pets total! Remove as many deleted pets as necessary
      while (petList.length > 26) {
        const oldestDeletedEntry = petList.filter(p => p.delete).sort((a, b) => a.updatedAt - b.updatedAt || a.id.localeCompare(b.id))[0]
        if (!oldestDeletedEntry) break

        petList.splice(petList.indexOf(oldestDeletedEntry), 1)
        Vue.delete(existingPets, oldestDeletedEntry.id)
      }
    }
    if (petList.filter(p => !p.delete).length > 26) {
      // TODO: Big problem, we can't store this many...
    }
    if (petList.filter(p => !p.delete).length > 20) {
      // We have too many existing pets after merging!
      // Warn user and prompt to delete.
      // This is checked in the settings page to prevent user from creating more.
    }
  },
  removePets(state: any, pets: string[]) {
    const existingPets = state.pets
    state.pets = Object.fromEntries(Object.entries<any>(existingPets).map(([k, p]) => ([k, pets.includes(p.id)
      ? {...p, delete: true, updatedAt: dayjs().unix()}
      : p])))
  },

  petUrl(state, [petId, url]) {
    if (state.pets[petId]) state.pets[petId].petImage = url
  },

  setAssignedComponents(state, [thingName, components]) {
    state.doors[thingName].assigned_components = [...components]
  },

  assignPetToSlot(state, { petId, thingName, componentId, idx }) {
    const component = state.doors[thingName].assigned_components.find(x => x.id === componentId)
    component.petIds = component.petIds.map((x, i) => i === idx ? petId : x)

    const doorDbId = state.doors[thingName].id
    let petDoor = state.pets[petId].doors.find(x => x.doorID == doorDbId)
    if (!petDoor) {
      petDoor = {doorID: doorDbId, components: [], data: {settings: {in: 'default', out: 'default'}}}
      state.pets[petId].doors = [...state.pets[petId].doors, petDoor]
    }
    petDoor.components = [...petDoor.components, { memIdx: idx, componentId }]

    tagList(thingName).then(list => {
      store.commit('setRFID', {thing: thingName, list: {RFIDTagList: list}})
    }).catch(console.error)
  },

  unassignPetFromComponent(state, { petId, doorId: thingName, componentId }) {
    const component = state.doors[thingName].assigned_components.find(x => x.id === componentId)
    const removedIndices = component.petIds.map(enumerate).filter(([x]) => x === petId).map(([_, idx]) => idx)
    component.petIds = component.petIds.map(x => x === petId ? null : x)

    const doorDbId = state.doors[thingName].id
    const petDoor = state.pets[petId].doors.find(x => x.doorID == doorDbId)
    if (petDoor) petDoor.components = petDoor.components.filter(x => !removedIndices.includes(x.memIdx))

    if (thingName in state.RFID) {
      const tagListComponent = state.RFID[thingName].RFIDTagList.find(x => x.localComponentId === componentId)
      tagListComponent.memIdx = tagListComponent.memIdx.filter(x => !removedIndices.includes(x))
    }
  },

  changeSettingsDoor(state, settingsDoorId) {
    state.setdoor = settingsDoorId
  }


} as MutationTree<any>
