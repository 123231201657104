
<template lang="pug">
.petImage

  f7-block
    .petimage
      canvas#petimgcanvas( width='144', height='144')
    .imagebtns
      .cambtn._hover._bold
        input#petfile(type='file',accept="image/*", ref='petfile', @change='LoadNewImageFromInputFile()')
        label(v-if="$device.ios || $device.android", @click="$refs.actionCamView.open()")

          i.icon-photocamera
          | &nbsp; {{$t("PetImage.photo")}}
        label(v-else, for="petfile")
          i.icon-photocamera
          | &nbsp; {{$t("PetImage.photo")}}
      .rotbtn._hover._bold(@click="RotateImage()")
        i.icon-rotate
        | &nbsp; {{$t("PetImage.rotate")}}

  f7-actions(ref='actionCamView')
    f7-actions-group
      f7-actions-button(@click="OpenCameraOrLoader()")  {{$t("PetImage.camera")}}
      f7-actions-button
        label.filelabel(for="petfile")
          | {{$t("PetImage.loading")}}
      f7-actions-button(color='red') {{$t("PetImage.cancel")}}

  CameraView(@clicked="SavePictureFromCameraView")

</template>

<script>
export default {
  data() {
    return {}
  },
  props: ["species", "petId"],
  computed: {
    pet() {
      let pet
      if (this.petId) {
        pet = this._allPets[this.petId]
      }

      if (!pet) pet = {}

      if (!pet.petImage)
        if (this.species === "Dog") {
          this.$emit("rotated", this.$img.preset.newdogimg)
          pet.petImage = this.$img.preset.newdogimg
        } else if (this.species === "Cat") {
          this.$emit("rotated", this.$img.preset.newcatimg)
          pet.petImage = this.$img.preset.newcatimg
        }

      return pet
    }
  },
/*  watch: {
    pet: {
      immediate: true,
      handler(nv) {
        console.log('updating canvas...', this.pet.petImage)
        this.setCanvas()
      }
    }
  },*/
  mounted() {
    this.setCanvas()
  },

  methods: {
    setCanvas() {
      setTimeout(() => {
        let c = document.getElementById("petimgcanvas")
        let ctx = c.getContext("2d")
        let imageObj = new Image()
        imageObj.onload = () => {
          ctx.drawImage(imageObj, 0, 0, 144, 144)
        }

        imageObj.crossOrigin = 'anonymous'
        if (this.pet.petImage) imageObj.src = this.pet.petImage
      }, 30)
    },

    LoadNewImageFromInputFile() {
      this.files = this.$refs.petfile.files
      window.LOG.log(this.files)
      let file = this.files[0]

      if (file && file.type.match("image.*")) {
        this.$f7.dialog.progress()
        let reader = new FileReader()
        let canvas = document.getElementById("petimgcanvas")
        let ctx = canvas.getContext("2d")
        reader.readAsDataURL(file)
        reader.onload = evt => {
          if (evt.target.readyState === FileReader.DONE) {
            let imageObj = new Image()
            console.log(imageObj)
            imageObj.onload = () => {
              this.$f7.dialog.close()
              let wi = imageObj.width
              let hi = imageObj.height
              if (wi >= hi) {
                let rehi = 144 / hi
                let offsetw = (wi * rehi - 144) / 2
                ctx.drawImage(imageObj, -offsetw, 0, rehi * wi, rehi * hi)
              } else if (wi < hi) {
                let rewi = 144 / wi
                let offseth = (hi * rewi - 144) / 2
                ctx.drawImage(imageObj, 0, -offseth, rewi * wi, rewi * hi)
              }
              let savepetimg = canvas.toDataURL("image/jpeg", 1)
              this.$emit("rotated", savepetimg)
            }
            imageObj.crossOrigin = 'anonymous'
            imageObj.src = evt.target.result
          }
        }
      } else {
        window.LOG.log("not an image")
      }
    },
    RotateImage() {
      let canvas = document.getElementById("petimgcanvas")
      let ctx = canvas.getContext("2d")
      let img = new Image()
      let newpetimg = canvas.toDataURL("image/jpeg", 1)

      img.onload = () => {
        ctx.save() // saves the state of canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height) // clear the canvas
        ctx.translate(144 / 2, 144 / 2) // let's translate
        ctx.rotate((Math.PI / 180) * 90) // increment the angle and rotate the image
        ctx.drawImage(img, -144 / 2, -144 / 2, 144, 144)
        ctx.restore()

        let savepetimg = canvas.toDataURL("image/jpeg", 1)
        this.$emit("rotated", savepetimg)
      }
      img.crossOrigin = 'anonymous'
      img.src = newpetimg
    },

    OpenCameraOrLoader() {
      if (typeof CameraPreview !== "undefined") {
        this.$f7.popup.open("#CameraView")
        let options = {
          x: (window.screen.width - 288) / 2,
          y: 40,
          width: 288,
          height: 288,
          camera: CameraPreview.CAMERA_DIRECTION.BACK,
          toBack: false,
          tapPhoto: false,
          tapFocus: true,
          previewDrag: false
        }
        setTimeout(() => {
          CameraPreview.startCamera(options)
        }, 350)
      } else {
        this.$f7.popup.open("#CameraView")
      }
    },

    SavePictureFromCameraView(imageval, wi, hi) {
      // LOG.log(imageval, wi, hi);
      let canvas = document.getElementById("petimgcanvas")
      let ctx = canvas.getContext("2d")
      {
        let imageObj = new Image()
        imageObj.crossOrigin = 'anonymous'
        imageObj.onload = () => {
          let wi = imageObj.width
          let hi = imageObj.height
          if (wi >= hi) {
            let rehi = 144 / hi
            let offsetw = (wi * rehi - 144) / 2
            ctx.drawImage(imageObj, -offsetw, 0, rehi * wi, rehi * hi)
          } else if (wi < hi) {
            let rewi = 144 / wi
            let offseth = (hi * rewi - 144) / 2
            ctx.drawImage(imageObj, 0, -offseth, rewi * wi, rewi * hi)
          }
          let savepetimg = canvas.toDataURL("image/jpeg", 1)
          this.$emit("rotated", savepetimg)
        }
        imageObj.src = imageval
      }
    }
  }
}
</script>

<style lang="stylus">

.petimage
  width: 144px
  height: 144px
  line-height: 144px
  text-align: center
  overflow: hidden
  overflow: -webkit-paged-y
  margin: auto
  border: 4px solid #fff
  box-shadow:  #00000044 0px 0px 18px;
  max-width:420px

  margin-left:auto!important;
  margin-right:auto!important;
  border-radius: 50%
  canvas#imgcanvas
    border-radius: 120px

input#petfile
  display: none
label#petfilelabel
  // background: antiquewhite;
  display: block
  width: 100%
  position: relative
.hoverer:hover
  opacity: 0.8
  cursor: pointer
  label
    opacity: 0.8
    cursor: pointer

.imagebtns
  display: flex
  align-items: center
  justify-content: center;
  font-size:18px;
  text-transform:uppercase
  color:#24242
  padding:12px
  span.i
    padding-right:8px
  .cambtn,.rotbtn
    width: 160px;
    text-align: center;
.greyedtransition
  transition:all .3s;
  max-height:600px
  overflow:hidden
.greyed
  background:#eaeaea
  max-height:0
  overflow:hidden
._learnnotify.notifications
    width: 300px;
    position: fixed;
    top: 0;
    text-align: center

.filelabel
  display:block

</style>
