export default class TimeoutError extends Error {
  name: 'TimeoutError'

  constructor(msg = '') {
    super(`The operation timed out. ${msg}`);
  }
}

// For nicer display in browsers
TimeoutError.prototype.name = 'TimeoutError'
