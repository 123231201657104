





import Vue from 'vue'
import Component from "vue-class-component"
import {Getter} from 'vuex-class'
import {Prop} from "vue-property-decorator"
import {reconnect} from "@/devices"

@Component
export default class extends Vue {
  @Prop(Object) door
  @Prop(Boolean) noClick
  @Prop(Boolean) monotone
  @Prop(String) align
  @Getter _doorConnectionSymbol

  onClick() {
    if (this.noClick) return
    reconnect(this.door.thing_name)
  }
}
