<template lang="pug">
    f7-list-item(link="#", :sheet-open='sheet', :title='title' @click="$emit('click')" :disabled="disabled")
        .after(slot="after")
            slot(name="after")
                span(v-if="flag") {{$t("ON")}}
                span(v-else-if="!flag") {{$t("OFF")}}
        .text(slot="text")
            slot(name="text")
</template>

<script>
    export default {
        name: "flag-control-list-item",
        props: {
            flag: {
                type: Boolean,
                required: true
            },
            sheet: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: true
            },
            disabled: {
              type: Boolean,
              required: false
            }
        },
        data () {
            return {}
        },

        computed: {

        },
        methods: {

        }
    }
</script>
