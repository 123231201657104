import {get, has} from "@/devices/repository"

export async function timeSet(thingName: string, time) {
  const device = getDeviceOrThrow(thingName)
  return device.wsCommands.request('TimeSet', [time])
}

function getDeviceOrThrow(thingName: string) {
  if (!has(thingName)) throw new Error(`${thingName} does not exist`)
  return get(thingName)
}
