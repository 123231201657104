<template lang="pug">

f7-popup#PasswordChangerPop(@popup:open="beforeOpen()")

  f7-page.white
    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small

    f7-block

          h3.tac {{$t('PasswordChanger.changepassword')}}
          p.tac {{$t('PasswordChanger.typepassword')}}
          f7-list(inset, strong)
            f7-list-input(:label='$t("PasswordChanger.currentpassword")', type='password', :placeholder='$t("PasswordChanger.currentpassword")', name='current_pw_password', :value="oldPassword", @input="oldPassword = $event.target.value")
            f7-list-input(after="sd", :label='$t("PasswordChanger.newpassword")', type='password', :placeholder='$t("PasswordChanger.newpassword")', name='new_pw_password', :value="newPassword", @input="newPassword = $event.target.value")

          .warner.tac(v-if="newPassword && !$_validatePassword(newPassword)")
            h3 {{$t("newpasswordconditionalhead")}}
            p(v-html="$t('PasswordChanger.newpasswordconditional_HTML')")

          pwButton(texter="PasswordChanger.changepassword", loader="pwchangeloader", size="m", col="green", position="center", @click="changeAWSPassword()")

</template>
<script>
import { mapGetters, mapState } from "vuex"

let self = null
export default {
  beforeCreate() {
    self = this
  },
  name: "PasswordChangerPop",
  data: function() {
    return {
      newPassword: "",
      oldPassword: ""
    }
  },

  mounted() {},
  computed: {
    // ...mapState([])
  },

  methods: {
    beforeOpen() {},
    async changeAWSPassword() {
      try {
        self.$store.commit("loader", ["UCloader", true])
        let resp = await self.$awsCognito.ChangeAWSPassword(
          self.oldPassword,
          self.newPassword
        )
        self.$store.commit("loader", ["UCloader", false])
        window.LOG.log(resp)
        self.UIResponder(resp)

        if (!resp.err) {
          self.oldPassword = ""
          self.newPassword = ""
          self.$f7.popup.close("#PasswordChanger", true)
        }
      } catch (err) {
        self.$store.commit("loader", ["UCloader", false])
        window.LOG.err(err)
      }
    }
  }
}
</script>

<style scoped lang="stylus">


img
  width:46px
  height:46px
  margin-right: 10px;

</style>
