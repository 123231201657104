import {version as makeVersion} from "@/libs/version"
import {observe, publish} from "@/deviceWebsocket/ws"
import idx from "idx"
import {spread, tap, logIfNotTimeout} from "@/libs/utils"

const commands = [
  'RFIDStartLearn',
  // 'RFIDStopLearn',
  'RFIDDelete',
  'RFIDDeleteAll',
  'RFIDDeletePet',
  'RFIDTagList',
  'RFIDTagExists',
  'ZigBeeListDevices',
  'ZigBeeRemoveDevice',
  'ZigBeeJoinAllowed',
  'ZigBeeNameDevice',
  'ZigBeeUpdate',
  'ZigBeeJoinConfirm',
  'DeviceInfo',
  'WifiNetworkList',
  'WifiNetworkSet',
  'WifiScan',
  'TimeSet',
  'FactoryReset',
  'InitDriveStart',
  'ErrorCommit'
]

type Message = [{[key: string]: any}, string]

export function createDeviceClient(url: string) {
    let version = makeVersion('1.0.0')
    const subscriptions: Array<ZenObservable.Subscription> = []
    const subscription = observe(url)
      .map(({responses, 'request-id': requestId, version}) => {
          const responseMap = responses.reduce((acc, cur) => ({...acc, ...cur}), {})
          return {requestId, data: responseMap}
      })
    const shadowValueSubscription = subscription
      .map(({requestId, data}) => {
          return [Object.fromEntries<any>(Object.entries(data).filter(([k, v]) => !commands.includes(k)).map(sanitizeShadowValues)), requestId] as Message
      })
      .map(removeErrorMessages)
      .filter(emptyMessagePayload)
    const commandSubscription = subscription
      .map(({requestId, data}) => {
          return [Object.fromEntries<any>(Object.entries(data).filter(([k, v]) => commands.includes(k))), requestId] as Message
      })
      .filter(emptyMessagePayload)

    subscription.subscribe({
      next: ({data}) => {
        if (idx(data, _ => _.DeviceInfo[0].ws_version)) version = makeVersion(data.DeviceInfo[0].ws_version)
      },
      error: logIfNotTimeout
    })

    publish(url, {
        requests: [{function: 'DeviceInfo', params: []}],
    }).catch(logIfNotTimeout)

    const ready = new Promise<void>((res, rej) => {
      const sub = subscription.subscribe(({data}) => {
        if (data.DeviceInfo) {
          if (idx(data, _ => _.DeviceInfo[0].ws_version)) version = makeVersion(data.DeviceInfo[0].ws_version)
          sub.unsubscribe()
          res()
        }
      }, rej)
    })

    return {
        get version() {
            return version
        },
        get ready() { return ready },
        async update(desired, requestId = null) {
          await ready
          console.log('sending desire', desired, requestId)
          await publish(url, {
              version: version.toString(),
              requests: Object.entries<any>(desired).map(([k, v]) => ({
                  function: k,
                  params: [].concat(v),
                  method: 'put'
              })),
              // 'request-id': requestId
          })
        },
        onUpdated(cb) {
            return tap(s => subscriptions.push(s), shadowValueSubscription.subscribe(spread(cb), () => {}))
        },
        async request(func, params = [], requestId = null) {
          await ready
          await publish(url, {
              version: version.toString(),
              requests: [{function: func, params, 'request-id': requestId}],
          })
        },
        onCommandResponse(cb) {
            return tap(s => subscriptions.push(s), commandSubscription.subscribe(spread(cb), () => {}))
        },
        onError(cb) {
            return tap(s => subscriptions.push(s), subscription.subscribe({ error: e => cb(e) }))
        },
        close() {
            subscriptions.forEach(s => s.unsubscribe())
        }
    }
}

function emptyMessagePayload([data]: Message) {
  return !!Object.keys(data).length
}

function sanitizeShadowValues([k, v]: [string, any]): [string, any] {
  if (!['pets', 'components'].includes(k)) v = v[0]
  return [k, v]
}

function removeErrorMessages([data, requestId]: Message): Message {
  return [
    Object.fromEntries(Object.entries(data).filter(([k, v]) => {
      const isError = typeof v[0] === 'string' && v[0].startsWith("error")
      if (isError) console.error('received error message', k, v)
      return !isError
    })),
    requestId
  ]
}
