import {createClient} from "@/deviceShadow"
import {registerModule} from "@/devices/module"
import {compose, fork, ifNotNull, pick, except} from "@/libs/functional"
import idx from "idx"
import {createShadowAbstractionClient} from "@/deviceWebsocket/shadow"
import {createClient as createJobsClient} from '@/deviceShadow/jobs'
import {Store} from 'vuex'
import {
  has as deviceExists,
  put as addDevice,
  get as getDevice,
  del as removeDevice, get
} from "@/devices/repository"
import {createClient as createCommandClient} from "@/deviceWebsocket/commands"
import {tagList} from "@/devices/rfid"
import {version} from "@/libs/version"
import {logIfNotTimeout} from "@/libs/utils"
import {terminateConnection} from "@/deviceShadow/mqtt"

const MAX_HOLDOFF_STAGE = 6

export function register(store: Store<any>, thingName: string) {
  if (deviceExists(thingName)) return

  let wsConnected = false
  let mqttConnected = true

  registerModule(store, thingName)
  let mqttClient
  let jobsClient
  let wsShadowClient
  let wsCommandClient

  initializeMqttConnection()
  store.watch(s => idx(s, _ => _.doors[thingName].reported.clb_state_ip), initializeWebsocketClient, {immediate: true})
  store.watch(s => s.isInternet, async nv => {
    await terminateConnection()

    if (nv) {
      initializeMqttConnection()
    } else {
      // If we do not have internet connection, we can assume that we cannot connect to the mqtt broker,
      //  so we just terminate the connection to clean up
      if (mqttClient) mqttClient.close()
      if (jobsClient) jobsClient.close()
      mqttConnected = false
      store.commit(`doors/${thingName}/mqttConnected`, false)
    }
  })

  addDevice({
    get thingName() { return thingName },
    get wsConnected() { return wsConnected },
    get mqttConnected() { return mqttConnected },
    get mqtt() { return mqttClient },
    get mqttJobs() { return jobsClient },
    get ws() { return wsShadowClient },
    get wsCommands() { return wsCommandClient },
    get fwVersion() { return version(store.state.doors[thingName].reported.clb_state_version) },
    async reconnect() {
      await terminateConnection()
      nTimeout = 1
      nMqttTimeout = 1
      initializeMqttConnection()
      initializeWebsocketClient()
    },
    watchers: [],
  })

  let nTimeout = 1
  let pendingRetry
  function initializeWebsocketClient() {
    if (pendingRetry) clearTimeout(pendingRetry)
    const ip = idx(store, _ => _.state.doors[thingName].reported.clb_state_ip)

    if (!ip) return
    if (wsShadowClient) wsShadowClient.close()
    if (wsCommandClient) wsCommandClient.close()
    console.log('attempting to connect ws', ip, nTimeout)

    wsShadowClient = createShadowAbstractionClient(thingName, ip)
    wsShadowClient.ready.then(() => {
      nTimeout = 1
      wsConnected = true
      store.commit(`doors/${thingName}/wsConnected`, true)
    }).catch(logIfNotTimeout)
    wsShadowClient.onReportedUpdated(saveStateInStore)
    wsShadowClient.onError(e => {
      wsConnected = false
      store.commit(`doors/${thingName}/wsConnected`, false)

      nTimeout = Math.min(MAX_HOLDOFF_STAGE, nTimeout + 1)
      pendingRetry = setTimeout(initializeWebsocketClient, (Math.pow(2, nTimeout) * 1000) + Math.floor(Math.random() * 1000))
    })

    wsCommandClient = createCommandClient(ip)
    wsCommandClient.ready
      .then(() => tagList(thingName))
      .then(list => store.commit('setRFID', {thing: thingName, list: {RFIDTagList: list}}))
      .then(migrateTimezoneIfNeeded)
      .catch(logIfNotTimeout)
  }

  let nMqttTimeout = 1
  let pendingMqttRetry
  function initializeMqttConnection() {
    if (pendingMqttRetry) clearTimeout(pendingMqttRetry)

    if (mqttClient) mqttClient.close()
    if (jobsClient) jobsClient.close()

    if (!store.state.isInternet) return // Don't attempt to reconnect without internet access
    console.log('attempting to connect mqtt', nMqttTimeout)

    mqttClient = createClient(thingName)
    jobsClient = createJobsClient(thingName)

    // Since pet updates might not be immediately processed by a device, but need to picked up ASAP by the app,
    // we listen on the desired shadow directly as well
    mqttClient.onDesiredUpdated(compose(pick(['pets']), d => d.pets && saveStateInStore(d)))
    // We synchronize pet data from desired already, so we skip the reported section, since it might hold stale data
    mqttClient.onReportedUpdated(compose(except(['pets']), saveStateInStore))

    mqttClient.onError(e => {
      mqttConnected = false
      store.commit(`doors/${thingName}/mqttConnected`, false)

      console.log('mqtt error encountered', e)
      retry()
    })
    mqttClient.onClose(() => {
      mqttConnected = false
      store.commit(`doors/${thingName}/mqttConnected`, false)

      console.log('mqtt closed')
      retry()
    })
    mqttClient.ready.then(() => {
      store.commit(`doors/${thingName}/mqttConnected`, true)
      mqttConnected = true

      clearTimeout(pendingMqttRetry)
      nMqttTimeout = 1
    }).catch(() => {
      console.log('refresh timed out...')
      retry()
    })

    function retry() {
      nMqttTimeout = Math.min(MAX_HOLDOFF_STAGE, nMqttTimeout + 1)
      if (pendingMqttRetry) clearTimeout(pendingMqttRetry)
      pendingMqttRetry = setTimeout(initializeMqttConnection, (Math.pow(2, nMqttTimeout) * 1000) + Math.floor(Math.random() * 1000))
    }
  }

  function saveStateInStore(deviceData) {
    // shortcut if there is no new data
    if (!Object.keys(deviceData).length) return

    deviceData = {...deviceData}
    console.log('reported updated', thingName, deviceData)

    store.dispatch('synchronizeComponentState', { components: deviceData.components, pets: deviceData.pets, thing: thingName })
      .catch(console.error)

    store.commit(`doors/${thingName}/report`, {...deviceData})
    if ('clb_cfg_flags' in deviceData) {
      store.commit('updateFlagsReported', [thingName, deviceData.clb_cfg_flags])
      delete deviceData.clb_cfg_flags
    }
    store.commit('updateMQTTWish', [thingName, deviceData])
  }


  function migrateTimezoneIfNeeded() {
    const v = version(store.state.doors[thingName].reported.clb_state_version)
    const timezone = store.state.doors[thingName].reported.clb_cfg_timezone

    if (v.gt(version('0.1.19')) && !isNaN(parseInt(timezone))) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      store.dispatch(`doors/${thingName}/desire`, {
        clb_cfg_timezone: timezone
      }).catch(err => console.error('something went wrong when migrating timezone?', err))
    }
  }
}

export function reconnect(thingName: string) {
  if (!deviceExists(thingName)) return false

  const device = getDevice(thingName)
  device.reconnect()
}

export function remove(store, thingName: string) {
  if (!deviceExists(thingName)) return

  const device = getDevice(thingName)
  device.mqtt.close()
  device.watchers.forEach(w => w())
  removeDevice(device)

  store.unregisterModule(['doors', thingName])
}

function diffObjects(o = {}, n = {}) {
  return Object.fromEntries(Object.entries<any>(n).filter(([k, v]) => !o[k] || o[k] !== v))
}

export * from './zigbee'
export * from './rfid'
