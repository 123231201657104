<template lang="pug">
  img(:src="pet ? pet.petImage : ''")
</template>

<script>
export default {
  name: "PetIcon.vue",
  props: ['pet']
}
</script>

<style lang="stylus" scoped>
img
  border-radius 3px
  width 28px
</style>
