<template lang="pug">
f7-popup#CameraView.popup-tablet-fullscreen
  .cameraback
  .camera-btns
    a.camera-button._grey(href="#", @click='cancelTakingPicture')
      i.icon-close

    a.camera-button(href="#", @click='takePicture')
      i.icon-takeimage
</template>

<script>
let self;
export default {
  name: "CameraView",
  beforeCreate() {
    self = this;
  },
  data: function() {
    return {};
  },
  methods: {
    cancelTakingPicture() {
      if (typeof CameraPreview !== "undefined") {
        CameraPreview.stopCamera();
      }
      self.$f7.popup.close("#CameraView");
    },
    takePicture() {
      if (typeof CameraPreview !== "undefined") {
        CameraPreview.getSupportedPictureSizes(function(dimensions) {
          CameraPreview.takePicture(
            {
              width: dimensions[0].width,
              height: dimensions[0].height,
              quality: 85
            },
            function(base64PictureData) {
              let imageval = "data:image/jpeg;base64," + base64PictureData;
              CameraPreview.stopCamera();
              self.$f7.popup.close("#CameraView");
              self.$emit(
                "clicked",
                imageval,
                dimensions[0].width,
                dimensions[0].height
              );
              self.$f7.popup.close("#CameraView");
            }
          );
        });
      } else {
        self.$emit("clicked", "imageval", 144, 144);
        self.$f7.popup.close("#CameraView");
      }
    }
  }
};
</script>

<style lang="stylus" scoped >
#CameraView

.cameraopen
  position: fixed;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50000;
.cameraback
  background: #222
  height:368px;
  width:100%

.camera-btns
  display: flex
  align-items: center
  justify-content: center;
  max-width: 480px
  margin 24px auto
  .camera-button
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #e55;
    color: #fff;
    text-align: center;
    margin: auto;
    line-height: 70px;
    font-size: 24px;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.4)
    transition: all .15s
    border:3px solid #fff

    &:active
      box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.4)
    &.active
      background: #A4C121
    &._disabled
      background: #d2d2d2;
      box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.4)
    &._grey
      background: #ededed
      color:#424242

</style>
