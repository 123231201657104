




















import {Vue, Component, Prop} from '@/vue-collection'

const iossettingimg = require('@/img/settings.png')
const androidsettingimg = require('@/img/settingsandroid.png')
const wifi = require('@/img/wifi.png')
const logo = require('@/img/logo.png')

@Component
export default class extends Vue {
  @Prop(String) wifiToConnect: string
  declare $device: any

  get settings() {
    return this.$device.android ? androidsettingimg : iossettingimg
  }

  get wifi() { return wifi }
  get logo() { return logo }

  goToSettings() {
    if (this.$device.android) {
      cordova.plugins.diagnostic.switchToWirelessSettings(function() {
        console.log("Successfully switched to Settings app");
      }, function(error) {
        console.error("The following error occurred: " + error);
      });
    } else if (this.$device.ios) {
      cordova.plugins.diagnostic.switchToSettings(function() {
        console.log("Successfully switched to Settings app");
      }, function(error) {
        console.error("The following error occurred: " + error);
      });
    } else {
      console.log("Not an mobile device")
    }
  }
}
