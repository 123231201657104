import RegistrationEventResponse = PhonegapPluginPush.RegistrationEventResponse

let push: PhonegapPluginPush.PushNotification

export function setup() {
  if (!window.PushNotification) return

  return new Promise<RegistrationEventResponse>(async (resolve, reject) => {
    if (push) await teardown()
    push = window.PushNotification.init({
      android: {},
      browser: {},
      ios: {
        alert: true,
        sound: true,
        badge: true
      },
      windows: {}
    })

    push.on("registration", resolve)
    push.on("notification", data => {
      window.LOG.log(data)
    })

    push.on("error", reject)
  })
}

export function teardown() {
  return new Promise((resolve, reject) => {
    if (!push) return resolve()
    push.unregister(() => {
      console.log('push token invalidated')
      resolve()
      push = undefined
    }, () => {
      reject(new Error('token invalidation failed'))
    })
  })
}
