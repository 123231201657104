<template lang="pug">

f7-popup#TimePickerPop(@popup:open="beforeOpen()")

  f7-page.white
    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small
    f7-block(v-if="(_settingsDoor||{}).reported")
      h3.tac {{$t("TimePicker.title")}}

      div.section
        h4 {{$t("TimePicker.title-time-format")}}
        pw-toggle(key="tm", :left="12", :right="24", :initval="24", :valone="_settingsDoor.reported.clb_cfg_time_mode", :valtwo="_settingsDoor.desired.clb_cfg_time_mode || _settingsDoor.reported.clb_cfg_time_mode", @click="switchTimeMode")

      template(v-if="onMobile")
        template(v-if="_settingsDoor.wscon")
          div.section
            h4 {{$t("TimePicker.title-use-smartphone-time")}}
            pw-button(texter="TimePicker.button-use-smartphone-time", size="m", col="outline", position="center", @click="setAutoValues")
        template(v-else)
          div.section
            h4 {{$t("TimePicker.title-use-smartphone-time")}}
            p.bold.color-red {{$t("General.ws-connection-required-prompt")}}

      template(v-else)
        div.section
          h4 {{$t("TimePicker.title-use-smartphone-time")}}
          mobile-app-needed

</template>

<script>
import { timeSet } from "@/devices/time"
import { mapGetters } from 'vuex'
import dt from "dayjs"
import utc from "dayjs/plugin/utc"
import MobileAppNeeded from "@/pages/MobileAppNeeded"
import { timeZonesNames } from '@vvo/tzdb'
import { version } from "@/libs/version"

dt.extend(utc)

export default {
  name: "TimePickerPop",
  data: () => {
    return {
      isSet: false,
      timemode: null,
      pickerInline12: null,
      pickerInline24: null,

      OpenTimerValue24: [],
      OpenTimerValue12: [],
      listening: false
    };
  },
  components: { MobileAppNeeded },
  computed: {
    ...mapGetters(['onMobile']),

    timeZoneNames() {
      return timeZonesNames
    }
  },
  methods: {
    async seDoorTime() {
      try {
        const date = new Date()
        const time = date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds()

        const offsetHours = new Date().getTimezoneOffset() / 60;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        // eslint-disable-next-line camelcase
        const clb_cfg_timezone = version(this._settingsDoor.reported.clb_state_version).gt(version('0.1.19'))
          ? timezone
          : offsetHours

        await timeSet(this._settingsDoorId, time)

        if (this._settingsDoor.reported.clb_cfg_timezone !== clb_cfg_timezone) {
          await this.$store.dispatch(`doors/${this._settingsDoorId}/desire`, {
            clb_cfg_timezone
          })
        }
      } catch (e) {
        console.error('problem when sending time to client', e)
      } finally {
        this.$store.commit("loader", ["savetimeloader", false])
      }
    },

    switchTimeMode() {
      let sendtimemode = 12;
      if (parseInt(this._settingsDoor.reported.clb_cfg_time_mode) === 12) {
        sendtimemode = 24;
      } else if (
        parseInt(this._settingsDoor.reported.clb_cfg_time_mode) === 24
      ) {
        sendtimemode = 12;
      }

      this.$store.dispatch(`doors/${this._settingsDoorId}/desire`, {
        clb_cfg_time_mode: sendtimemode
      })

      this.listening = true;
      this.$store.commit("loader", ["savetimeloader", true]);
    },

    setAutoValues() {
      this.seDoorTime();
    },

    beforeOpen() {
      this.timemode = this._settingsDoor.reported.clb_cfg_time_mode
        ? this._settingsDoor.reported.clb_cfg_time_mode.toString()
        : "24";
      this.timezone = this._settingsDoor.reported.clb_cfg_timezone
        ? this._settingsDoor.reported.clb_cfg_timezone.toString()
        : "0";

    }
  }
};
</script>

<style scoped lang="stylus">

.white
  background: #ffffff
.thetime
  font-size: 1.6rem
  text-align: center
  padding: 12px
  width: 240px
  margin: auto

.section
  margin-bottom 1em

</style>
