<template lang="pug">
.pwtogle

  //- pre {{valone}} {{valtwo}} {{initval}}

  ._value {{left}}
  ._switcher(v-bind:key="'key'", @click="handleClick()")
    .switcher(:class="{ _wait:valone !== valtwo,_false: initval!==valone,_true: initval===valone}")
      .blob.shadow(:class="{ _wait:valone !== valtwo,_false: initval!==valone,_true: initval===valone}")
        .pwloader._s(v-if="valone !== valtwo")

  ._value {{right}}

</template>

<script>
export default {
  props: ["left", "right", "valone", "valtwo", "initval"],

  data() {
    return {};
  },

  watch: {
    valtwo: {
      immediate: true,
      handler(nv) {
        setImmediate(() => this.$emit('changed', nv))
      }
    }
  },

  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped lang="stylus">
.pwtogle
  text-align:center
  line-height:32px
  padding:8px 0 8px 0;
  &._nopad
    padding:8px 0 12px 0;
  ._title
    display:inline-block
    // width:64px
    padding: 0 12px;
    text-align:left
    font-size:14px
    font-weight:700
  ._value
    display: inline-block;
    font-size:14px
    text-transform:uppercase
  ._switcher
    display:inline-block
    text-align:center
    width:68px
    text-align:center
    &._triple
      width:74px
  ._value off
    font-size:12px

  .switcher
    cursor:pointer
    position:relative
    // width:48px;
    // margin: auto;
    // height:16px
    // top: 3px;

    width: 56px;
    margin: auto;
    height: 27px;
    top: 7px;

    border-radius: 14px;
    transition: background-color 0.2s
    background-color:#aaa
    &._true
      background-color:#a4c121
    &._false
      background-color:#aaa
    &._wait
      background-color:#b0bb96
    &._block
      background-color:#eee
    .line
      position:absolute
      background:#aaa
    .blob
      transition: right 0.2s
      border: 1px solid #eee;
      position: absolute;
      background: #fff;
      width: 21px;
      height: 21px;
      border-radius: 16px;
      top: 2px;
      line-height: 28px;
      line-height: 19px;
      /* vertical-align: middle; */
      &._true
        right: 2px;
      &._false
        right: 31px;
      &._wait
        right: 17px;
      &._block
        background-color:#eee
        border: 1px solid #eee;

</style>

<docs>
# docs
</docs>
