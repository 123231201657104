<template lang="pug">

f7-page#NewPet(name="NewPet")
  f7-navbar(:title='nupetname || $t("NewPet.addnewpet")', back-link='Back')

  .page-content(:class="{ _screen_species:petblock === 'screen_species'}")
    ._middlehorizontal(v-if="['cloud','offline'].includes(userState.value)")
      transition(name='fade')
        f7-block.transition_wrapper(:key="petblock")
            .pet_species(v-if="petblock === 'screen_species'")
              .aligner-item
                .tac
                  h1._center._bold {{$t("NewPet.whichoneisit")}}
              .aligner
                .aligner-item
                  img(:src="$img.newcat")
                  a._choosespecies._bold._btnshadow(href="#", @click="SelectSpecies('Cat')") {{$t("NewPet.cat")}}
                .aligner-item
                  img(:src="$img.newdog")
                  a._choosespecies._bold._btnshadow(href="#", @click="SelectSpecies('Dog')") {{$t("NewPet.dog")}}

            .pet_name.max960(v-if="petblock === 'screen_name'")

              pet-image(:petId="null", :species="species" @rotated="petImage = $event")

              f7-block
              form(autocomplete="off", onsubmit="return false;")
                f7-block-title Name
                f7-list(strong, media-list, no-hairlines)
                  f7-list-input(:label="$t('Pet.petsname')", type='text', placeholder='Name', clear-button='', :value="nupetname", @input="nupetname = $event.target.value" maxlength="20")
                    i.icon-cat.large(slot='media', v-if="species === 'Cat'")
                    i.icon-dog.large(slot='media', v-if="species === 'Dog'")
                pw-button(texter="NewPet.savepet", loader="addpet", size="m", col="outline", position="center", @click="onSaveClicked")
  f7-actions(ref='actionCamView')
    f7-actions-group
      f7-actions-button(@click="OpenCameraOrLoader()")  {{$t("NewPet.camera")}}

      f7-actions-button
        label.filelabel(for="newimgfile")
          | {{$t("NewPet.loadimg")}}
      f7-actions-button(color='red') {{$t("NewPet.cancel")}}
  //- CameraView(@clicked="SavePictureFromCameraView")

</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import PetImage from "./PetImage";
// let preset = require("./../img/presetimages.json");
let self;
export default {
  components: { PetImage },
  data() {
    return {
      petblock: "screen_species",
      nupetname: "",
      species: "cat",
      canvasname: "newimgcanvas",
      petImage: ""
    };
  },
  watch: {},
  created() {
    self = this;
  },
  mounted() {
    self.petblock = "screen_species";
  },
  computed: {
    ...mapState(["load"])
    // ...mapGetters([])
  },
  methods: {
    ...mapActions(["savePet"]),

    SelectSpecies(species) {
      self.species = species;
      self.petblock = "screen_name";
      self.$f7.input.focus(".petsnameinput");
    },

    OpenCameraOrLoader() {
      if (typeof CameraPreview !== "undefined") {
        self.$f7.popup.open("#CameraView");
        let options = {
          x: (window.screen.width - 288) / 2,
          y: 40,
          width: 288,
          height: 288,
          // camera: CameraPreview.CAMERA_DIRECTION.BACK || null,
          toBack: false,
          tapPhoto: false,
          tapFocus: true,
          previewDrag: false
        };
        setTimeout(() => {
          CameraPreview.startCamera(options);
        }, 350);
      } else {
        self.$f7.popup.open("#CameraView");
      }
    },

    async onSaveClicked() {
      if (self.nupetname === "") {
        self.UIResponder({
          text: self.$t('NewPet.errors.no-pet-name'),
          err: true
        });
      } else {
        let data = {
          name: self.nupetname,
          species: self.species,
        };
        self.$f7.dialog.progress();

        const resp = await this.savePet({ data, image: self.petImage })

        resp.title = "NewPet.notifications.pet-added.title";
        self.UIResponder(resp);

        if (!resp.err) self.$f7.views.main.router.back();
        self.$f7.dialog.close();
      }
    }
  }
};
</script>
<style scoped lang="stylus">

.page-content // prevent top padding and background animation
  transition:all .25s
  &._screen_species
    padding-top: 0px!important;
    background:#a4c121

.transition_wrapper
  position:absolute
  width: 100%;

.openbtn
  height: 64px
  transition: height 0.25s
  overflow:hidden

  &._opened
    height: 64px !important

.previewpet
  width: 240px
  height: 240px

.grayed
  background: #eee

.mainopic
  width: 144px
  height: 144px
  line-height: 144px
  text-align: center
  overflow: hidden
  margin: auto
  border: 4px solid #fff
  border-radius: 120px
  canvas#newimgcanvas
    border-radius: 120px

input#newimgfile
  display: none
.filelabel
  display:block

.petimage
  max-width:420px
  margin-left:auto;
  margin-right:auto;

.imagebtns
  display: flex
  align-items: center
  justify-content: center;
  font-size:18px;
  text-transform:uppercase
  color:#24242
  padding:12px
  span.i
    padding-right:8px
  .cambtn,.rotbtn
    width: 160px;
    text-align: center;

.fade-enter-active, .fade-leave-active
  transition: all .25s
  opacity: 1
  transform: translateY(0%)
.fade-enter,.fade-leave-to
  opacity: 0
  transform: translateY(-90px)

.aligner
  display: flex
  align-items: center
  justify-content: center;
  max-width: 420px;
  margin: 0 auto;
  max-height: 640px;

.pet_species
  height: calc(100vh - 90px);
  // background: aliceblue;
  justify-content: center;
  flex-direction: column;
  display: flex;

  h1
    color:#fff
    font-size: 32px;

  .aligner-item
    // max-width: 50%
    position:relative
    img
      border-bottom: 6px solid #fff
      width: 180px
      max-width: 100%
      max-height: 70vh
      padding-top: 48px
    a._choosespecies
      font-size:20px;
      color:#424242
      background: #fff
      line-height:128px
      text-align:center
      outline:none
      position:absolute
      right:0
      left:0
      bottom:36px
      width:128px;
      height:128px;
      border-radius:64px;
      margin:auto
      &:hover
        background: #eee
      &:active
        background: #ededed
.pet_name
  input.petsname
    color : #de3
</style>
