



















import Vue from 'vue'
import {Component} from "vue-property-decorator"
import {deleteUser} from "@/api"

@Component
export default class extends Vue {
  confirmDeleteInput: string = ""

  deleteInProgress = false

  deleteAccount() {
    if (this.deleteInProgress) return

    this.deleteInProgress = true
    deleteUser()
     .then(() => {
       this.$f7.popup.close()
       this.$awsCognito.logOutAWSUser()
       this.$f7router.navigate('/')
     })
     .catch(err => {
       // TODO: proper error reporting
       console.error('error when trying to delete', err)
     })
    .then(() => {
      this.deleteInProgress = false
    })
  }
}
