




























import {Component, Vue, Watch, Prop} from '@/vue-collection'
import {timeout} from "@/libs/utils"
import {createClient} from "@/deviceWebsocket/commands"

declare const WifiWizard2: any

@Component
export default class extends Vue {
  @Prop(Object) device: any

  client = null
  wifiList = []
  selectedWifi = null
  wifiPassword = ""
  error = ""

  @Watch('selectedWifi') resetWifiPassword() {
    this.wifiPassword = ""
  }

  mounted() {
    this.makeClient().catch(console.error)
  }

  async makeClient(retry = 0) {
    try {
      this.client = createClient(this.device.url)
      this.client.onError(err => this.error = err instanceof Error ? err.message : err)
      console.log('connecting to ', this.device.url)
      await this.client.ready
      console.log('client ready')
      this.wifiList = await this.client.request('WifiNetworkList')
    } catch (e) {
      if (retry < 5) {
        await timeout(500)
        await this.makeClient(++retry)
      }
      else this.error = "An unknown error occured"
    }
  }

  async connectDoorToWifi() {
    try {
      // Door will not respond in time before shutting down wifi, so don't wait
      const resultDoor = this.client.request('WifiNetworkSet', [this.selectedWifi, this.wifiPassword, false])

      // not using that, should happen automatically when setup wifi goes down
      // const resultWifi = await WifiWizard2.connect(this.selectedWifi, true)

      this.$emit('finished', this.selectedWifi)
    } catch (e) {
      console.error(e)
    }
  }
}
