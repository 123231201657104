import {AWSAppSyncClient} from "aws-appsync"
import AWS from 'aws-sdk'
import env from "@/env"
import gql from 'graphql-tag'
import store from '@/store/store'
import {except} from "@/libs/functional"

const query = gql`
query allPets {
  getPets {
    id
    name
    species
    updatedAt
    delete
  }
}
`

const mutation = gql`
mutation updatePets($userId: String!, $pets: AWSJSON!) {
  updatePets(userId: $userId, pets: $pets) {
    userId
    pets {
      id
      name
      species
      updatedAt
      delete
    }
  }
}
`

const subscription = identity => gql`
subscription updatedPets {
  updatedPets(userId: "${identity}") {
    userId
    pets {
      id
      name
      species
      updatedAt
      delete
    }
  }
}
`

let client: any
let cognitoIdentity: string
let sub: any

export async function connect(identity, force = false) {
  if (client && !force) return
  cognitoIdentity = identity
  client = new AWSAppSyncClient({
    url: env.aws.appsync.url,
    region: env.aws.region,
    auth: {
      type: 'AWS_IAM',
      credentials: AWS.config.credentials,
    },
    disableOffline: true
  });

  await client.hydrated()

  subscribeToUpdates()

  function subscribeToUpdates() {
    if (!client) return
    client.query({ query })
      .then(result => {
        const pets = result?.data?.getPets || []
        store.dispatch('mergePets', pets.map(except(['__typename'])).map(p => ({...p, doors: []})))
      })

    sub = client.subscribe({
      query: subscription(identity),
    }).subscribe({
      start(subscription: ZenObservable.Subscription): any {
      },
      next(e) {
        console.log('APPSYNC SUBSCRIPTION EVENT', e)
        const pets = e.data?.updatedPets?.pets
        if (pets) store.dispatch('mergePets', pets.map(except(['__typename'])).map(p => ({...p, doors: []})))
      },
      complete() {
        console.log('complete')
        setTimeout(subscribeToUpdates, 1000 + Math.random() * 1000)
      },
      error(err) {
        console.log('error', err)
        setTimeout(subscribeToUpdates, 1000 + Math.random() * 1000)
      }
    })
  }
}

export async function disconnect() {
  sub && sub.unsubscribe()
  client = null
  cognitoIdentity = null
}

export async function update(pets) {
  if (!client) return

  await client.mutate({
    mutation,
    variables: {
      "userId": cognitoIdentity,
      "pets": JSON.stringify(pets.map(except(['doors', 'petImage'])))
    }
  })
}
