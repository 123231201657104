






import {Vue, Component, Prop} from '@/vue-collection'

@Component
export default class extends Vue {
  @Prop(Boolean) strong
}
