import {get, has} from "@/devices/repository"
import {first, tap} from "@/libs/functional"

export function startLearn(thingName: string, componentId: string, slot: number) {
  const device = getDeviceOrThrow(thingName)

  if (device.wsCommands.version.major === 2)
    return device.wsCommands.request('RFIDStartLearn', [componentId, slot], 31000).then(first)
  else if (componentId === 'intern')
    return device.wsCommands.request('RFIDStartLearn', [slot], 31000).then(first)
}

export function tagList(thingName: string) {
  const device = getDeviceOrThrow(thingName)
  if (device.wsConnected)
    return device.wsCommands.request('RFIDTagList')
  // eslint-disable-next-line prefer-promise-reject-errors
  else return Promise.reject('not connected')
}

export function deletePet(thingName: string, petId: string, idx: number) {
  const device = getDeviceOrThrow(thingName)

  if (device.fwVersion.minor >= 1)
    if (device.wsConnected)
      return device.wsCommands.request('RFIDDeletePet', [petId])
        .then(result => {
          if (result[0] === 'ok') return {}
          // eslint-disable-next-line no-throw-literal
          else throw {status: 'FAILED', details: result[0]}
        }).then(tap(console.log))
    else
      return device.mqttJobs.execute('rfid', {action: 'delete', petId, localComponentId: 'all'})
  else {
    if (device.wsConnected) {
      return device.wsCommands.request('RFIDDelete', [idx])
        .then(result => {
          if (result[0] === 'ok') return {}
          // eslint-disable-next-line no-throw-literal
          else throw {status: 'FAILED', details: result[0]}
        })
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {status: 'FAILED', details: `Cannot remove pet from door ${thingName}. Door is not reachable`}
    }
  }
}

export function unlearnPet(thingName: string, petId: string, componentId: string, idx: number) {
  const device = getDeviceOrThrow(thingName)

  if (device.fwVersion.minor >= 1)
    if (device.wsConnected) {
      return device.wsCommands.request('RFIDDelete', [componentId, idx])
        .then(result => {
          if (result[0] === 'ok') return {}
          // eslint-disable-next-line no-throw-literal
          else throw {status: 'FAILED', details: result[0]}
        })
    } else
      return device.mqttJobs.execute('rfid', {action: 'delete', petId, localComponentId: componentId})
  else {
    if (device.wsConnected) // TODO throw if componentId != intern
      return device.wsCommands.request('RFIDDelete', [idx])
        .then(result => {
          if (result[0] === 'ok') return {}
          // eslint-disable-next-line no-throw-literal
          else throw {status: 'FAILED', details: result[0]}
        })
    else {
      // eslint-disable-next-line no-throw-literal
      throw {status: 'FAILED', details: `Cannot remove pet from door ${thingName}. Door is not reachable`}
    }
  }
}

function getDeviceOrThrow(thingName: string) {
  if (!has(thingName)) throw new Error(`${thingName} does not exist`)
  return get(thingName)
}
