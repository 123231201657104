<template lang="pug">

.lpbtn.longpress-button(:class='status', @touchend='cancel()', @touchstart.prevent='triggerCount()', @mouseup='cancel()', @mousedown.prevent='triggerCount()',:style="'width: '+size4+'px;height: '+size4+'px;'")
  .overlay(:style="'width: '+size2+'px;height: '+size2+'px;'")
      svg._rot(:height='size2', :width='size2')
        circle._off(:cx='size3', :cy='size3', :r='size',:style="'animation-duration:'+duration+'s;stroke-dasharray:'+2*3.142*size")
        circle._on(:cx='size3', :cy='size3', :r='size',:style="'animation-duration:'+duration+'s;stroke-dasharray:'+2*3.142*size+';stroke-dashoffset:'+2*3.142*size",v-if="status === 'counting'")
        circle._ex(:cx='size3', :cy='size3', :r='size',:style="'animation-duration:'+duration+'s;stroke-dasharray:'+2*3.142*size",v-if="status === 'executing'")

      .tabrow(:style="'width: '+size2+'px;height: '+size2+'px; font-size:'+ iconsize +'px;'")

        .innertext(v-if="status === 'default'")
          i(:class="icon1", :style="'color:'+color")
        .innertext(v-if="status === 'counting'")
          i(:class="icon2", :style="'color:'+color")
        .innertext(v-if="status === 'executing'")
          i(:class="icon3", :style="'color:'+color")

</template>
<script>
import { timeout } from '@/libs/utils'

let timer = null
export default {
  name: 'longpress-button',
  props: [
    'value',
    'onConfirm',
    'duration',
    'pressingText',
    'actionText',
    'size',
    'icon1',
    'icon2',
    'icon3',
    'iconsize',
    'color'
  ],
  created () {
    document.addEventListener('mouseup', () => this.cancel())

    // document.addEventListener('mouseleave', () => this.cancel())
  },
  mounted () {

  },
  destroyed () {
    document.removeEventListener('mouseup', () => this.cancel())
    // document.removeEventListener('mouseleave', () => this.cancel())
  },
  data () {
    return {
      status: 'default',
      counter: 0
    }
  },
  methods: {
    triggerCount () {
      if (this.status === 'executing' || this.status === 'counting') {
        return
      }
      this.status = 'counting'
      this.countAndConfirm()
    },
    async countAndConfirm () {
      while (this.counter < this.duration) {
        await timeout(1000)
        if (this.status !== 'counting') return // cancelled
        this.counter++
      }

      this.reset()
      if (this.onConfirm) this.onConfirm(this.value)
    },
    reset () {
      this.status = 'default'
      this.cancel()
    },
    cancel () {
      if (this.status === 'executing') {
        return
      }
      this.counter = 0
      clearTimeout(timer)
      this.status = 'default'
    }
  },
  computed: {
    countingPressingText () {
      const pressingText = this.pressingText || ''
      return pressingText
        .replace(/\{\$counter\}/gi, this.counter)
        .replace(/\{\$rcounter\}/gi, this.duration - this.counter)
        .replace(/\{\$duration\}/gi, this.duration)
    },
    size2: function () {
      return this.size * 2 + 4
    },
    size3: function () {
      return this.size * 1 + 2
    },
    size4: function () {
      return this.size * 2 + 12
    }
  }
}
</script>

<style scoped lang="css">
.overlay {
  margin: 4px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.4);
  font-size: 21px;
  transition: all .15s;
}

.overlay:hover {
  box-shadow: 0px 5px 12px 0 rgba(0, 0, 0, 0.4);
}

.lpbtn {
  display: inline-block;
}

.lpbtn.counting .overlay {
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.4);
}

.lpbtn.executing .overlay {
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.4);
}

._rot {
  transform: rotate(-90deg);
}

.longpress-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.longpress-button.executing {
  opacity: 0.8;
}

@keyframes longpress-progress {
  100% {
    width: 100%;
  }
}

.tabrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 4px;
  width: 100%;
  height: 90px;
  text-align: center;
  display: table;
}

.innertext {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  display: table-cell;
  color: #636363;
  line-height: 0px;

  text-shadow: 0px 0px 16px #fff;
}

circle._on {
  fill: rgba(255, 255, 255, 0.31);
  stroke: #A4C121;
  stroke-width: 4;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  opacity: 0.5;
  animation: rotator 5s linear;
}

circle._off {
  fill: rgba(255, 255, 255, 0.31);
  stroke: #fff;
  stroke-width: 4;
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
}

circle._ex {
  fill: rgba(255, 255, 255, 0.31);
  stroke: #A4C121;
  stroke-width: 4;
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
}

@keyframes rotator {
  to {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}
</style>
