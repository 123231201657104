












import Vue from "vue"
import Component from "vue-class-component"

@Component({
  name: 'settings-list-item',
  props: {
    title: String,
    description: String,
    showLoader: Boolean,
    icon: String,
    currentValueInfo: String,
    disabled: Boolean
  }
})
export default class extends Vue {
}
