const initialState = () => {
  return {
    defaultAlexaId: null,
    pets: {},
    services: {}, // FROM AWS
    userservices: {}, // FROM AWS
    notificationsettings: {}, // FROM AWS
    firmwareupdates: {},

    // PUSHNOTIFICATION PLUGIN TOKENS:
    fcmToken: "",
    fcmTokenPrev: "",
    currentdoor: 'demodoor',
    setdoor: 'demodoor',
    setpet: '1',
    lang: 'unset',
    ws: {},
    wswish: {},
    wscon: {}, // connecting wait ready error closed
    // UI
    uiloader: {}, // to show loading spinner for specific functions!
    isInternet: window.navigator.onLine,
    checkeduser: false,
    RFID: {},
    ZIGBEE: {
      _update: 0

    },
    permissions: { wifi: false }, // WIFI / CAMERA / LOCATION...
    updateInfo: {},
    reconnect: false, // for MQTT if lost signal
    reconnectMessage: {},
    wifiName: '',
    device: '',
    assignSerial: '',
    assignSecret: '',
    bonjourList: {},
    instrumentation: {
      failedRequests: {}
    }
  }
}
export default initialState
