/* eslint-disable */

import Debug from '@/libs/debugger'
import AWS from "aws-sdk"

let packagefile = require('../package.json')
let version = packagefile.version || '0.0.0'

let env = {
  'dev': {
    'version': version,
    'aws': {
      'poolData': {
        'UserPoolId': 'eu-west-1_b2szZ6Vlm',
        'ClientId': '38l3br8v6e1q86f9fp8omm14ke'
      },
      'appsync': {
        'url': 'https://fepvmmddkjdxtcpnvv37ekiv6q.appsync-api.eu-west-1.amazonaws.com/graphql'
      },
      'iot': {
        // 'endpointAddress': 'a3jaezcjtpysxb.iot.eu-west-1.amazonaws.com',
        // 'endpointAddress': 'a3jaezcjtpysxb-ats.iot.eu-west-1.amazonaws.com',
        // 'endpointAddress': 'wss://a3jaezcjtpysxb-ats.iot.eu-west-1.amazonaws.com',
        'endpointAddress': 'wss://a3jaezcjtpysxb-ats.iot.eu-west-1.amazonaws.com/mqtt',

        'region': 'eu-west-1',
        'IdentityPoolId': 'eu-west-1:0468d366-6ed2-4e36-bf7a-a3acc785eafd'
      },
      'region': 'eu-west-1',
      'translation_bucket': 'https://petwalk-translations-staging.s3-eu-west-1.amazonaws.com',
      'images_bucket_id': 'petwalkstg-petimagesbucket-17ngpjyt6u2ij'
    },
    'apixukey': '1c7c6f3c3cbb4461a4590630180312',
    'weatherstack': '58157c55c9faa8ddaf16e35bcf43d03f',
    'bundle': 'petWALK.PetWalkDEV',
    'mainurl': 'https://cm3qyfuggb.execute-api.eu-west-1.amazonaws.com/Master/',
    'translationurl': 'https://s3-eu-west-1.amazonaws.com/petwalk-translations-staging/lang.json',
    'googlemapskey': "AIzaSyBb5HlhJKmc8op6z5ZCt9FCiJG57Z0r7Lw",
    'senderID': "227398615620"
  },

  'prd': {
    'version': version,
    'aws': {
      'poolData': {
        'UserPoolId': 'eu-west-1_NaHCncUdX',
        'ClientId': '2qht0pl3vufdq8dmah5crv2e0o'
      },
      'appsync': {
        'url': 'https://47y74zycjbcy5ghjoqilngrv7u.appsync-api.eu-west-1.amazonaws.com/graphql'
      },
      'iot': {
        'endpointAddress': 'wss://a1quts5wkb23yv-ats.iot.eu-west-1.amazonaws.com/mqtt',
        // 'endpointAddress': 'a1quts5wkb23yv-ats.iot.eu-west-1.amazonaws.com',
        'region': 'eu-west-1',
        'IdentityPoolId': 'eu-west-1:b4772990-ba53-47c8-b42d-86f430f27ee3',
        'LoginKey': 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_shEZ4YDWX'
      },
      'region': 'eu-west-1',
      'translation_bucket': 'https://petwalk-translations.s3-eu-west-1.amazonaws.com',
      'images_bucket_id': 'petwalkprd-petimagesbucket-ie463kc67mf7'
    },
    'apixukey': '1c7c6f3c3cbb4461a4590630180312',
    'weatherstack': '58157c55c9faa8ddaf16e35bcf43d03f',
    'bundle': 'at.petWALK.PetWalk',
    'mainurl': 'https://caln02rdoj.execute-api.eu-west-1.amazonaws.com/Master/',
    'translationurl': 'https://s3-eu-west-1.amazonaws.com/petwalk-translations/lang.json',
    'googlemapskey': "AIzaSyBb5HlhJKmc8op6z5ZCt9FCiJG57Z0r7Lw",
    'senderID': "227398615620"
  }

}

let whichEnv = process.env.VUE_APP_AWS || 'dev'

// window.LOG.log(process.env.VUE_APP_AWS)
// window.LOG.log(process.env.VUE_APP_GITHASH)
// window.LOG.log(process.env.VUE_APP_WS);

env[whichEnv].githash = process.env.VUE_APP_GITHASH || 'githash'
env[whichEnv].devws = process.env.VUE_APP_WS === 'dev'
env[whichEnv].case = whichEnv

env[whichEnv].creds = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: env[whichEnv].aws.iot.IdentityPoolId,
})
AWS.config.region = env[whichEnv].aws.iot.region

Debug.INIT(whichEnv === 'dev')
window.verbose = false
// window.LOG.log(env[whichEnv]);

export default env[whichEnv]
