import {get, has} from "@/devices/repository"

export function factoryReset(thingName: string) {
  const device = getDeviceOrThrow(thingName)
  return device.wsCommands.request('FactoryReset')
}

export function initDriveStart(thingName: string) {
  const device = getDeviceOrThrow(thingName)
  return device.wsCommands.request('InitDriveStart')
}

function getDeviceOrThrow(thingName: string) {
  if (!has(thingName)) throw new Error(`${thingName} does not exist`)
  return get(thingName)
}
