








import {Vue, Component, Action, Watch, Prop, State} from '@/vue-collection'
import {setupSsid} from "./constants"
import ManualDoorConnectionInfo from './ManualDoorConnectionInfo.vue'

@Component({
  components: {
    ManualDoorConnectionInfo
  },
  methods: {
    setupSsid: () => setupSsid
  }
})
export default class extends Vue {
  @Prop(Boolean) skippable: boolean
  @State wifiName
  @Action _getWifiName
  @Action _askPermissionWifi

  searching: boolean = true

  @Watch('wifiName', {immediate: true}) onWifiNameChanged(nv, ov) {
    console.log(nv, setupSsid)
    if (nv === setupSsid) {
      this.$emit('finished')
    }
  }

  created() {
    document.addEventListener("resume", this.onResume.bind(this), false)
    document.addEventListener("pause", this.onPause.bind(this), false)
  }

  beforeDestroy() {
    document.removeEventListener("resume", this.onResume.bind(this), false)
    document.removeEventListener("pause", this.onPause.bind(this), false)
    this.searching = null
  }

  async onResume() {
    try {
      await this._askPermissionWifi()
      this.searching = true
    } catch (e) {
      console.error(e)
    }
  }

  onPause() {
    this.searching = false
  }

  mounted() {
    this.updateWifiName() // fire and forget. controlled by `this.searching`
  }

  async updateWifiName() {
    if (this.searching === null) return
    if (this.searching) try {
      await this._getWifiName()
    } catch (e) {
      console.error(e)
    }
    setTimeout(this.updateWifiName.bind(this), 500)
  }
}

