<template lang="pug">

f7-page#Settings(name="Settings")
  f7-navbar(:title='$t("Settings.Settings")', back-link='Back')

  f7-block.max960
      //- DOOR
      f7-block-title(medium) {{$t("Settings.alldoors")}}
      f7-list( strong, no-hairlines)
        f7-list-item(v-for="(door,i) in doors", :link='"/settings/door/"+door.thing_name',  :title='door.caption' :key="door.thing_name")
          span(slot="after")
            span.update-notice(v-if="door.isUpdateAvailable") {{$t('Door.update-available')}}
            span.update-notice(v-else-if="$store.getters[`doors/${door.thing_name}/isUpdateInProgress`]") {{$t('Door.update-in-progress')}}
            span {{door.case}} &nbsp;
            connection-state(:door="door" no-click align="bottom")

          i.icon-minidoor.small(slot='media')
        f7-list-item(v-if="!inDemoMode", link='/newdoor/', :title='$t("Settings.adddoor")')
          i.icon-add.small(slot='media')

      f7-block-title(medium) {{$t("Settings.allpets")}}
      f7-list( strong no-hairlines)
        f7-list-item(v-for="p in _allPets", :link='`/settings/pet/${p.id}`',  :title='p.Name || p.name' :key="p.id")
          span(slot='after') {{p.species}}
          pet-icon(slot='media', :pet="p")
        f7-list-item(v-if="!inDemoMode && hasOpenPetSlot()" link='/settings/newpet/' :title='$t("Settings.addpet")')
          i.icon-add.small(slot='media')
      template(v-if="hasTooManyPets()")
        f7-block.color-red.tac {{$t('Settings.pets.too-many')}}
      template(v-else-if="!hasOpenPetSlot()")
        f7-block.color-red.tac {{$t('Settings.pets.no-space')}}

      //- SERVICES
      template(v-if="Object.keys(_allDoors).length")
        f7-block-title(medium) {{$t('Settings.allservices')}}
        f7-list( strong, no-hairlines)
          f7-list-item(:link='`/settings/service/alexa`', :title="$t('Services.alexa')")
            i.icon-component.small(slot='media')

      //- ACCOUNT
      f7-block-title(medium) {{$t('Settings.myaccount')}}
      f7-list(strong, no-hairlines)
        f7-list-item(link='/useraccount/', :title='$t("Settings.myaccount")')
          i.icon-user.small(slot='media')

      f7-block-title(medium) {{$t('Settings.language')}}
      f7-list( strong, media-list, no-hairlines)
        f7-list-item.popup-open(link='#', data-popup='#ChangeLanguagePop', :title="$t('Settings.language')", text='English / Deutsch / Français')
          i.icon-lang.small(slot='media')

      f7-block-title(medium) {{$t('Settings.contact')}}
      f7-list( strong, media-list, no-hairlines)
        f7-list-item(link='/contact/',  :title='$t("Settings.contact")', :text="$t('Settings.contact-description')",)
          i.icon-support.small(slot='media')

      //- pwButton(texter="settings_tutorial", size="m", color="green", position="center", @click="$f7.popup.open('#tutorial')")
      .version v{{env.version}}
        span(v-if="env.case === 'dev'") - {{(env.githash).substring(0,7)}}
  ChangeLanguagePop

</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import redirectWhenUnsetUserState from "@/libs/mixins/redirectWhenUnsetUserState"
import PetIcon from "@/components/PetIcon.vue"

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["services"]),
    ...mapGetters(["_doorConnectionSymbol", "_allPets"]),
    ...mapGetters("userState", {inDemoMode: 'isDemo'}),

    doors() {
      return Object.values(this._allDoors).map(d => ({
        ...d,
        isUpdateAvailable: (d.firmware.jobStatus === 'none' && d.firmware.state === 1) ||
          (d.firmware.componentUpdates || []).some(x => x.jobStatus === 'none')
      }))
    }
  },
  mixins: [redirectWhenUnsetUserState],
  components: {PetIcon},
  methods: {
    ...mapActions(["_fetchUpdateInfoFromDb"]),
    hasOpenPetSlot() {
      return Object.values(this._allPets).length < 20
    },

    hasTooManyPets() {
      return Object.values(this._allPets).length > 20
    }
  },
  async mounted () {
    try {
      const result = await this._fetchUpdateInfoFromDb()
      if (result === false) this.UIResponder({
        text: this.$t('APIresponses.update-info.failed'),
        err: true
      })
    } catch (e) {
      this.UIResponder({
        text: this.$t('General.unknown-error'),
        err: true
      })
      console.error(e)
    }
  }
};
</script>

<style scoped  lang="stylus">
i
  height 28px

.version
  text-align right
  color: #bbbbbb

.update-notice
  color: red
  margin-right 2em
</style>
