




















import {Component, State, Vue, Watch, Mutation} from '@/vue-collection'
import {setDefaultAlexaDevice} from "@/api"

@Component
export default class extends Vue {
  @State doors
  @State defaultAlexaId
  @Mutation loader
  selectedDevice: number = null

  @Watch('defaultAlexaId', {immediate: true}) onDefaultAlexaIdChanged(nv) {
    this.selectedDevice = nv
  }

  async onSaveClicked() {
    try {
      this.loader(['modifyAlexaDefault', true])
      await setDefaultAlexaDevice(this.selectedDevice)
      this.UIResponder({title: 'Service.Alexa.notifications.default-id-set'});
    } catch (e) {
      console.error(e)
      this.UIResponder({title: 'Service.Alexa.notifications.generic-error', err: true})
    }

    this.loader(['modifyAlexaDefault', false])
  }
}
