interface Flags {
  OUTFLAG: boolean
  INFLAG: boolean
  ACCESSFLAG: boolean
  TIMEFLAG: boolean
  RAINFLAG: boolean
  DARKFLAG: boolean
  MUTEFLAG: boolean
  PARTIAL_RFID_INFLAG: boolean
  PARTIAL_RFID_OUTFLAG: boolean
  DARK_IN_AUTHFLAG: boolean
  DARK_OUT_AUTHFLAG: boolean
  RAIN_IN_AUTHFLAG: boolean
  RAIN_OUT_AUTHFLAG: boolean
}

export function makeBinFromFlags(doorflags: Partial<Flags>): number {
  if (doorflags === undefined) return undefined
  let flags = 0
  flags += (doorflags.OUTFLAG as unknown as number | 0) << 0
  flags += (doorflags.INFLAG as unknown as number | 0) << 1
  flags += (doorflags.ACCESSFLAG as unknown as number | 0) << 2
  flags += (doorflags.TIMEFLAG as unknown as number | 0) << 3
  flags += (doorflags.RAINFLAG as unknown as number | 0) << 4
  flags += (doorflags.DARKFLAG as unknown as number | 0) << 5
  flags += (doorflags.MUTEFLAG as unknown as number | 0) << 8
  flags += (doorflags.PARTIAL_RFID_INFLAG as unknown as number | 0) << 9
  flags += (doorflags.PARTIAL_RFID_OUTFLAG as unknown as number | 0) << 10
  flags += (doorflags.DARK_IN_AUTHFLAG as unknown as number | 0) << 12
  flags += (doorflags.DARK_OUT_AUTHFLAG as unknown as number | 0) << 13
  flags += (doorflags.RAIN_IN_AUTHFLAG as unknown as number | 0) << 14
  flags += (doorflags.RAIN_OUT_AUTHFLAG as unknown as number | 0) << 15
  return flags
}

export function makeFlagsFromBin(dec: number): Flags {
  if (dec === undefined) return undefined
  return {
    OUTFLAG: !!(dec & (1 << 0)),
    INFLAG: !!(dec & (1 << 1)),
    ACCESSFLAG: !!(dec & (1 << 2)),
    TIMEFLAG: !!(dec & (1 << 3)),
    RAINFLAG: !!(dec & (1 << 4)),
    DARKFLAG: !!(dec & (1 << 5)),
    MUTEFLAG: !!(dec & (1 << 8)),
    PARTIAL_RFID_INFLAG: !!(dec & (1 << 9)),
    PARTIAL_RFID_OUTFLAG: !!(dec & (1 << 10)),
    DARK_IN_AUTHFLAG: !!(dec & (1 << 12)),
    DARK_OUT_AUTHFLAG: !!(dec & (1 << 13)),
    RAIN_IN_AUTHFLAG: !!(dec & (1 << 14)),
    RAIN_OUT_AUTHFLAG: !!(dec & (1 << 15))
  }
}
