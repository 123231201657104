import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(['userState'])
  },
  watch: {
    userState: {
      deep: true,
      handler() {
        if (this.userState.value === 'unset') this.$f7router.navigate('/')
      }
    }
  }
}
