import dayjs from 'dayjs'
import TimeoutError from "@/libs/timeout-error"
const PasswordValidator = require('password-validator')
let schema = new PasswordValidator()
schema.is().min(8).is().max(64).has().uppercase().has().lowercase().has().digits().has().not().spaces()
let utils = {
  // BASIC HELPER:
  $_size(obj) {
    // RETURNS SIZE OF OBJECT
    return Object.keys(obj).length
  },
  $_findIdx(arr, key, val) {
    // RETURNS FIRST INDEX FROM ARRAY OF OBJECTS BY GIVEN KEY/VAL
    for (let idx in arr) {
      if ((arr[idx] || {})[key] === val) {
        return idx
      }
    }
  },
  $_cp(obj = {}) {
    // COPYS OBJECT FOR REUSE -> VUE HACK
    return JSON.parse(JSON.stringify(obj))
  },
  $_A2O(source, key, prefix) {
    // _.keyBy
    // RETURNS CONVERTED ARRAY TO OBJECT by given key and prefix  // ignores @ contained keys
    if (!prefix) prefix = ''
    key = key.toString()
    let nuobj = {}
    for (let item in source) {
      if (
        source[item][key].toString() &&
        source[item].toString() &&
        !source[item][key].toString().includes('@')) {
        nuobj[prefix + source[item][key]] = source[item]
      }
    }
    return nuobj
  },
  $_A2Odeep2(source, key1, key2, prefix) {
    // ARRAY to OBJECT by given key and prefix // same as before but with nested keys// only used in assigned components (APIACTIONS)
    if (!prefix) prefix = ''
    key1 = key1.toString()
    key2 = key2.toString()
    let nuobj = {}
    for (let item in source) {
      // LOG.log(source[item][key].toString());
      if (
        source[item][key1][key2].toString() &&
        source[item].toString() &&
        !source[item][key1][key2].toString().includes('@')) {
        // if (source[item][key] && source[item] && !source[item][key].includes('@')) {
        nuobj[prefix + source[item][key1][key2]] = source[item]
      }
    }
    return nuobj
  },
  $_O2A(source) {
    // RETURNS ARRAY FOM OBJECT
    return [].concat.apply([], Object.values(source))
  },
  $_sortBy(arr, key) {
    // SORTS ARRAY OF OBJECT BY VALUES FOR GIVEN KEY
    return arr.sort((a, b) => (a[key] > b[key]) ? 1 : -1)
  },
  $_findRemove(list, val) {
    // LOOKS FOR SPECIFIC "FIRST" VALUE IN ARRAY AND RETURNS ARRAY WITH OUT THIS VALUR
    for (let i in list) {
      if (list[i] === val) {
        list.splice(i, 1)
        break
      }
    }
    return list
  },
  $_replaceAll(str, str1, str2, ignore) {
    return str.replace(
      new RegExp(
        str1.replace(
          // eslint-disable-next-line
          /([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,
          "\\$&"
        ),
        ignore ? "gi" : "g"
      ),
      typeof str2 === "string" ? str2.replace(/\$/g, "$$$$") : str2
    )
  },
  $_removeDoubles(list, rlist) {
    // COMPARES TWO ARRAYS AND REMOVES VALUES FROM THE FIRST IF THES ARE CONTAINED IN THE SECOND
    for (let i in list) {
      for (let e in rlist) {
        if (list[i] === rlist[e]) {
          list.splice(i, 1)
        }
      }
    }
    return list
  },
  $_validateEmail(email) {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  },
  // schema() {
  //   return schema
  // },
  $_validatePassword(password) {
    return schema.validate(password)
  },
  // OTHER
  // OTHER
  // OTHER
  // OTHER
  $_makeFlags(dec) { // dec = STRING
    dec = parseInt(dec).toString(2)
    let doorflags = {}
    if (dec[dec.length - 1] === '1') { doorflags.OUTFLAG = true } else if (dec[dec.length - 1] === '0') { doorflags.OUTFLAG = false }
    if (dec[dec.length - 2] === '1') { doorflags.INFLAG = true } else if (dec[dec.length - 2] === '0') { doorflags.INFLAG = false }
    if (dec[dec.length - 3] === '1') { doorflags.ACCESSFLAG = true } else if (dec[dec.length - 3] === '0') { doorflags.ACCESSFLAG = false }
    if (dec[dec.length - 4] === '1') { doorflags.TIMEFLAG = true } else if (dec[dec.length - 4] === '0') { doorflags.TIMEFLAG = false }
    if (dec[dec.length - 5] === '1') { doorflags.RAINFLAG = true } else if (dec[dec.length - 5] === '0') { doorflags.RAINFLAG = false }
    if (dec[dec.length - 6] === '1') { doorflags.DARKFLAG = true } else if (dec[dec.length - 6] === '0') { doorflags.DARKFLAG = false }
    if (dec[dec.length - 9] === '1') { doorflags.MUTEFLAG = true } else if (dec[dec.length - 9] === '0') { doorflags.MUTEFLAG = false }
    if (dec[dec.length - 10] === '1') { doorflags.PARTIAL_RFID_INFLAG = true } else if (dec[dec.length - 10] === '0') { doorflags.PARTIAL_RFID_INFLAG = false }
    if (dec[dec.length - 11] === '1') { doorflags.PARTIAL_RFID_OUTFLAG = true } else if (dec[dec.length - 11] === '0') { doorflags.PARTIAL_RFID_OUTFLAG = false }
    if (dec[dec.length - 13] === '1') { doorflags.DARK_IN_AUTHFLAG = true } else if (dec[dec.length - 13] === '0') { doorflags.DARK_IN_AUTHFLAG = false }
    if (dec[dec.length - 14] === '1') { doorflags.DARK_OUT_AUTHFLAG = true } else if (dec[dec.length - 14] === '0') { doorflags.DARK_OUT_AUTHFLAG = false }
    if (dec[dec.length - 15] === '1') { doorflags.RAIN_IN_AUTHFLAG = true } else if (dec[dec.length - 15] === '0') { doorflags.RAIN_IN_AUTHFLAG = false }
    if (dec[dec.length - 16] === '1') { doorflags.RAIN_OUT_AUTHFLAG = true } else if (dec[dec.length - 16] === '0') { doorflags.RAIN_OUT_AUTHFLAG = false }
    doorflags.sumdec = parseInt(dec, 2)
    return doorflags
  },
  $_makeBinFromFlags(doorflags) {
    let bin = '0000000000000000'
    if (doorflags.OUTFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 1, '1')
    } else if (!doorflags.OUTFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 1, '0')
    }
    if (doorflags.INFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 2, '1')
    } else if (!doorflags.INFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 2, '0')
    }
    if (doorflags.ACCESSFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 3, '1')
    } else if (!doorflags.ACCESSFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 3, '0')
    }
    if (doorflags.TIMEFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 4, '1')
    } else if (!doorflags.TIMEFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 4, '0')
    }
    if (doorflags.RAINFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 5, '1')
    } else if (!doorflags.RAINFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 5, '0')
    }
    if (doorflags.DARKFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 6, '1')
    } else if (!doorflags.DARKFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 6, '0')
    }
    if (doorflags.MUTEFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 9, '1')
    } else if (!doorflags.MUTEFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 9, '0')
    }
    if (doorflags.PARTIAL_RFID_INFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 10, '1')
    } else if (!doorflags.PARTIAL_RFID_INFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 10, '0')
    }
    if (doorflags.PARTIAL_RFID_OUTFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 11, '1')
    } else if (!doorflags.PARTIAL_RFID_OUTFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 11, '0')
    }
    if (doorflags.DARK_IN_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 13, '1')
    } else if (!doorflags.DARK_IN_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 13, '0')
    }
    if (doorflags.DARK_OUT_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 14, '1')
    } else if (!doorflags.DARK_OUT_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 14, '0')
    }
    if (doorflags.RAIN_IN_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 15, '1')
    } else if (!doorflags.RAIN_IN_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 15, '0')
    }
    if (doorflags.RAIN_OUT_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 16, '1')
    } else if (!doorflags.RAIN_OUT_AUTHFLAG) {
      bin = this.$_replaceAt(bin, bin.length - 16, '0')
    }
    // doorflags.sum = '0000000000000000'
    let decFromBin = parseInt(bin, 2)
    // LOG.log('doorflags', doorflags.sumdec, doorflags.sum)
    return parseInt(decFromBin)
  },
  $_getTimeFromMins: function (mins, mode) {
    mins = Math.round(mins)
    if (mins >= 1440) {
      mins = 1439
    }
    if (mins < 0) {
      mins = 0
    }
    if (mins > 24 * 60 || mins < 0) {
      throw new RangeError(
        'Valid input should be greater than or equal to 0 and less than 1440.'
      )
    }
    var h = (mins / 60) | 0
    var m = mins % 60 | 0

    h = h < 10 ? '0' + h : '' + h
    m = m < 10 ? '0' + m : '' + m

    return h + ':' + m

  },
  $_replaceAt(tstring, index, replacement) {
    return tstring.substr(0, index) + replacement + tstring.substr(index + replacement.length)
  },
  $_getAntennaPossibilities(serial) {
    // FIXME:
    // PROD:
    // let retobj = { hasIntern: true, hasExtern: false } // DEFAULT / FALLBACK
    // DEV:
    let retobj = { hasIntern: true, hasExtern: true } // DEFAULT / FALLBACK
    if (serial.substring(0, 1) === '2') {
      retobj.hasIntern = false
      retobj.hasExtern = true
    } else if (serial.substring(1, 1) === '3') {
      if (serial.substring(3, 5) === '01') {
        retobj.hasIntern = true
        retobj.hasExtern = false
      } else if (serial.substring(3, 5) === '02') {
        retobj.hasIntern = true
        retobj.hasExtern = true
      }
    }
    return retobj
  },
  $_compareV(oldVersion, newVersion) {
    //RETURNS
    // new major: 100
    // new minor: 10
    // new patch: 1
    // no change: 0
    // old patch: -1
    // old minor: -10
    // old major: -100
    // THUMB RULE: UPDATE WHEN MINUS
    if (oldVersion === newVersion) {
      return 0
    }
    var aComponents = oldVersion.split(".")
    var bComponents = newVersion.split(".")
    var len = Math.min(aComponents.length, bComponents.length)
    if (oldVersion !== newVersion) {
      for (var i = 0; i < len; i++) {
        if (parseInt(aComponents[i]) > parseInt(bComponents[i])) return 1
        if (parseInt(aComponents[i]) < parseInt(bComponents[i])) return -1
        else {
          if (i === len - 1) {
            return 0
          }
        }
      }
    }
  }
}
export default utils

export function nextAnimationFrame() {
  return new Promise(res => requestAnimationFrame(res))
}

export function tap(cb, obj) {
  cb(obj)
  return obj
}

// eslint-disable-next-line standard/no-callback-literal
export const spread = (cb) => (argsArray) => cb(...argsArray)

export const timeout = ms => new Promise(res => setTimeout(res, ms))

export function logIfNotTimeout(err) {
  if (!(err instanceof TimeoutError)) console.error(err)
}
