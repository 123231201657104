import {createDeviceClient} from "@/deviceWebsocket/client"
import {v4 as uuid} from 'uuid'
import {Version} from "@/libs/version"

import Framework7 from 'framework7'

export interface CommandClient {
  version: Version
  request(fname: string, params?: Array<any>, timeout?: number): Promise<any>
  onError(cb: (any) => void)
  ready: Promise<void>
  close(): void
}

export function createClient(ip): CommandClient {
  const client = createDeviceClient(`ws://${ip}:1234`)

  return {
    get version() { return client.version },
    get ready() { return client.ready },

    async request(fname: string, params = [], timeout = 10000) {
      const id = uuid()
      await client.request(fname, params, id)

      return new Promise((resolve, reject) => {
        const subscription = client.onCommandResponse((result, requestId) => {
          let found = false
          if (client.version.major === 2 && requestId === id) found = true
          else if (Object.keys(result).length === 1 && fname in result) found = true

          if (found) {
            subscription.unsubscribe()
            resolve(result[fname])
          }
        })

        setTimeout(() => {
          subscription.unsubscribe()
          reject(new Error('timeout'))
        }, timeout)
      })
    },

    onError(cb) {
      return client.onError(cb)
    },

    close() {
      client.close()
    }
  }
}
