<template lang="pug">

f7-popup#LocationChangerPop(@popup:open="beforeOpen()")
  f7-page

    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small

    .location-block

      .mblock-header
        p.tac.cutat480
          i.icon-world.large
        p.tac.bold  {{$t("LocationChanger.infotext")}}

      .mblock-main
        GmapMap.gmap(:center='center', :zoom='10', map-type-id='roadmap')
          GmapMarker(:position='marker', :clickable='true', :draggable='true', @position_changed="updateCenter")

      .mblock-footer
        pwButton(texter="LocationChanger.findloc", loader="findlocationgps", size="s", col="green", position="center", @click="GetLocation()")
        pwButton(texter="LocationChanger.savelocation", loader="savelocation", size="m", col="outline", position="center", @click="SaveLocationLOC()")

</template>
<script>
import Vue from "vue"
import { mapGetters, mapState, mapActions } from "vuex"
import * as VueGoogleMaps from "vue2-google-maps"

import env from "@/env"

Vue.use(VueGoogleMaps, {
  load: {
    key: env.googlemapskey
  }
})

let self = null
export default {
  beforeCreate() {
    self = this
  },
  props: ["door"],
  name: "LocationChangerPop",
  data: () => {
    return {
      center: {
        lat: 46.721856,
        lng: 15.062709
      },
      marker: {
        lat: 46.721856,
        lng: 15.062709
      },
      savePosition: {
        lat: 46.721856,
        lng: 15.062709
      }
    }
  },

  mounted() {},
  computed: {
    // ...mapGetters([]),
    // ...mapState([]),
  },

  methods: {
    ...mapActions([
      "setToDoor",
      "_getWifiName",
      "_sendLocationToCloud"
    ]),

    beforeOpen() {
      let arr = self.door.location.split(",")
      self.marker = {
        lat: parseFloat(arr[0]),
        lng: parseFloat(arr[1])
      }
      self.savePosition = self.marker
      self.center = self.marker
    },
    updateCenter(latLng) {
      self.savePosition = {
        lat: parseFloat(latLng.lat()),
        lng: parseFloat(latLng.lng())
      }
    },

    /** ***********************************************
     SaveLocationLOC
    *************************************************/
    async SaveLocationLOC() {
      if (self.door.case === "cloud") {
        self.setToDoor([self.door.id, "weather", "update", 0])

        self.$f7.dialog.progress()
        let resp = await self._sendLocationToCloud({
          position: self.savePosition.lat + "," + self.savePosition.lng,
          doorID: self.door.id
        })
        self.UIResponder(resp)

        if (!resp.err) {
          self.$f7.popup.close("#LocationChangerPop")
        }
        self.$f7.dialog.close()
      } else {
        // SAVE DIRECTLY TO STORE DOOR IF NOT CLOUD!
        self.setToDoor([
          self.door.id,
          "location",
          self.savePosition.lat + "," + self.savePosition.lng
        ])
        self.UIResponder({ text: self.$t("LocationChanger.autopos_saved") })

        self.$f7.popup.close("#LocationChangerPop")
      }
    },

    async GetLocation() {
      await self._getWifiName()

      if (navigator.geolocation) {
        self.$store.commit("loader", ["findlocationgps", true])
        navigator.geolocation.getCurrentPosition(
          pos => {
            self.$store.commit("loader", ["findlocationgps", false])
            self.center = {
              lat: parseFloat(pos.coords.latitude),
              lng: parseFloat(pos.coords.longitude)
            }
            self.marker = self.center
            self.savePosition = self.center

            // self.SaveLocationLOC();
          },
          err => {
            self.$store.commit("loader", ["findlocationgps", false])

            // THE 'TEXT' PUTS THE CODE TO THE TRANSLATION
            self.UIResponder({
              text: self.$t(
                "LocationChanger.autopos_error" + err.code.toString()
              ),
              err: true
            })
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          }
        )
      } else {
        window.LOG.log("no gps found")
        self.UIResponder({
          text: self.$t("LocationChanger.autopos_error0"),
          err: true
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">

.gmap
  flex-grow: 1

.location-block
  padding: 0;
  // height: calc(100% - 0px);
  height: 100%
  display: flex;
  flex-direction: column;

.mblock-header
    min-height: 32px;

.mblock-main
  flex-grow: 1
  display: flex
  background-color: #ccc;

.mblock-footer
    min-height: 32px;
    padding: 12px;

</style>
