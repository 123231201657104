









import Vue from 'vue'
import Component from 'vue-class-component'
import {Action} from 'vuex-class'
import {isLocationAccessAuthorized} from "@/libs/permissions"

@Component
export default class extends Vue {
  @Action _askPermissionWifi
  permissionGranted: boolean = false

  async mounted() {
    document.addEventListener('resume', this.checkLocationPermission.bind(this))
    await this.checkLocationPermission()


    // Small timeout added to invocation so f7 has time to finish page transition.
    //  Sadly, there is no event emitted when routing transition is finished.
    //  TODO: request
    setTimeout(async() => {
      if (!this.permissionGranted)
        if (await this._askPermissionWifi()) {
          this.permissionGranted = true
          this.$emit('finished')
        }
    }, 500)
  }
  beforeDestroy() {
    document.removeEventListener('resume', this.checkLocationPermission.bind(this))
  }

  async checkLocationPermission() {
    if (await isLocationAccessAuthorized()) {
      this.permissionGranted = true
      this.$emit('finished')
    }
  }

  async requestLocationPermission() {
    if (!await this._askPermissionWifi())
      this.goToSettings()
    else {
      this.permissionGranted = true
      this.$emit('finished')
    }
  }

  goToSettings() {
    cordova.plugins.diagnostic.switchToSettings(function() {
    }, function(error) {
      console.error("The following error occurred: " + error);
    });
  }
}
