/* eslint-disable */

// MODULES
import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import utc from 'dayjs/plugin/utc'
// import * as VueGoogleMaps from "vue2-google-maps";
import App from './App.vue'
import VueI18Next from '@panter/vue-i18next'
import i18next from './i18n'
import store from './store/store'
import './registerServiceWorker'
import img from '@/libs/img.js'
import awsCognito from '@/libs/awsCognito'
import _ from "lodash";
import debounce from "v-debounce";

import MQTT from '@/libs/MQTT'
import WS from '@/libs/WS'
import dom7 from "dom7";
const { detailedDiff } = require("deep-object-diff");
import mixins from '@/libs/mixins'
import connect from '@/libs/connect'

// COMPONENTS
import pwButton from '@/components/pwButton.vue'
import pwToggle from "@/components/pwToggle.vue"
import MainLoader from '@/components/MainLoader.vue'
import CameraView from '@/components/CameraView.vue'

import FlagScreenHead from '@/components/FlagScreenHead.vue'
import DoorScreen from '@/components/DoorScreen.vue'
import DoorControl from '@/components/DoorControl.vue'
import FlagControl from '@/components/FlagControl.vue'
import UserCreds from '@/components/UserCreds.vue'
import LongPressButton from '@/components/LongPressButton.vue'
import DoorList from '@/components/Sidebar/DoorList.vue'
import ConnectionState from "@/components/ConnectionState.vue"
import PageHeader from '@/components/PageHeader.vue'

// PopUps
import PasswordChangerPop from '@/components/PopUp/PasswordChangerPop.vue'
import UserDataChangerPop from '@/components/PopUp/UserDataChangerPop.vue'
import TimePickerPop from '@/components/PopUp/TimePickerPop.vue'
import ChangeLanguagePop from '@/components/PopUp/ChangeLanguagePop.vue'
import PetsDoorSetsPopUp from '@/pages/Settings/Pet/PetsDoorSetsPopUp.vue'
import Square from '@/components/Square.vue'

// SERVICES
import ServiceALB from '@/pages/Settings/Service/ServiceALB.vue'

// LIBS
import env from '@/env'

import Framework7 from 'framework7/framework7.esm.bundle.js'
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js'

import 'framework7/css/framework7.bundle.min.css'

import './styles/custom.css'
import './styles/styles.stylus'
import './icons/rendered/icons.css'

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

// DAYJS
dayjs.extend(utc)

// PROTOTYPES
Vue.prototype.$img = img.setDesign('nizzle', 'svg')
Vue.prototype.$dayjs = dayjs
Vue.prototype.$awsCognito = awsCognito
Vue.prototype.MQTT = MQTT
Vue.prototype.$WS = WS
Vue.prototype.$_ = _
Vue.prototype.dom = dom7
Vue.prototype.env = env
Vue.prototype.$connect = connect
Vue.prototype.$diff = detailedDiff

Vue.directive("debounce", debounce)


// GLOBAL COMPONENTS
Vue.component('connection-state', ConnectionState)
Vue.component('pwButton', pwButton)
Vue.component('pwToggle', pwToggle)
Vue.component('MainLoader', MainLoader)
Vue.component('DoorScreen', DoorScreen)
Vue.component('DoorControl', DoorControl)
Vue.component('FlagControl', FlagControl)
Vue.component('UserCreds', UserCreds)
Vue.component('LongPressButton', LongPressButton)
Vue.component('DoorList', DoorList)
Vue.component('PasswordChangerPop', PasswordChangerPop)
Vue.component('UserDataChangerPop', UserDataChangerPop)
Vue.component('TimePickerPop', TimePickerPop)
Vue.component('ChangeLanguagePop', ChangeLanguagePop)
Vue.component('CameraView', CameraView)
Vue.component('PetsDoorSetsPopUp', PetsDoorSetsPopUp)
Vue.component('ServiceALB', ServiceALB)
Vue.component('FlagScreenHead', FlagScreenHead)
Vue.component('Square', Square)
Vue.component('page-header', PageHeader)

// CONFIGS
Framework7.use(Framework7Vue)
Vue.config.productionTip = false

Vue.use(VueI18Next)
Vue.use(mixins)

let device = Framework7.device.android
  ? "android"
  : Framework7.device.ios
    ? "ios"
    : "desktop";
store.commit('device', device)

const i18n = new VueI18Next(i18next)
new Vue({
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
