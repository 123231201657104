<template lang="pug">

f7-popup#FirmwareUpdatePop(@popup:open="beforeOpen()")
  f7-page.white
    .closepopupbtn
      f7-link(popup-close)
        i.icon-times.small

    f7-block(v-if="door")
      h3.tac {{$t('Firmware.popup-title')}}

      f7-block(inset, strong)
        div
          h3 {{(((firmware||{}).description||{})[firmware.targetVersion]||{}).header || '-'}}
          h4.preline {{(((firmware||{}).description||{})[firmware.targetVersion]||{}).info || '-'}}
          h4 {{currentVersion}} => {{targetVersion}}
          ul
            li(v-for="li in (((firmware||{}).description||{})[firmware.targetVersion]||{}).list") {{li}}
          p._center(v-if="!componentId" v-html="$t('Firmware.updatetext')")

      pw-button(texter="Firmware.startupdate", size="m", col="outline", position="center" loader="fwUpdate" @click="UpdateConfirm_LOC()")

</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import MobileAppNeeded from '@/pages/MobileAppNeeded'
import idx from "idx"
// const passwordValidator = require('password-validator')

let self = null
export default {
  components: { MobileAppNeeded },
  beforeCreate() {
    self = this
  },
  props: ["door", "componentId"],
  name: "FirmwareUpdatePop",
  data: () => {
    return {}
  },
  mounted() {},
  computed: {
    // ...mapState([]),
    ...mapGetters(["onMobile"]),
    firmware() {
      if (this.componentId) return idx(this.door, _ => _.firmware.componentUpdates.find(u => u.componentId = this.componentId)) || {}
      else return (this.door || {})["firmware"] || {}
    },
    currentVersion() {
      return this.truncateVersion(this.firmware.currentVersion)
    },
    targetVersion() {
      return this.truncateVersion(this.firmware.targetVersion)
    }
  },

  methods: {
    ...mapActions(["_postConfirmFirmwareUpdate", "_fetchUpdateInfoFromDb"]),
    ...mapMutations(['loader']),
    beforeOpen() {},
    async UpdateConfirm_LOC() {
      this.loader(['fwUpdate', true])

      try {
        const response = await self._postConfirmFirmwareUpdate({
          doorid: self.door.id,
          firmware: this.firmware.targetVersion,
          ...this.componentId && {componentId: this.componentId}
        })

        // if we get a positive response, we assume the update has been queued and pro-actively update our own state
        this.firmware.jobStatus = 'pending'

        self.UIResponder(response)
        self.$f7.popup.close('#FirmwareUpdatePop')
        self.$f7.dialog.close()
      } catch (e) {
        console.error(e)
      }

      this.loader(['fwUpdate', false])
    },

    truncateVersion(v = '') {
      if (this.componentId) return v.split('.').slice(0, -1).join('.')
      return v.split('.').slice(1).join('.')
    }
  }
}
</script>

<style scoped lang="stylus">


.preline
  white-space: pre-line
</style>
