// import vue from 'vue'
import env from '@/env'
import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import connect from '@/libs/connect'
import utils from '@/libs/utils'
import awsCognito from '@/libs/awsCognito'
import { PositiveFeedback, NegativeFeedback } from '@/libs/feedback'
import {register, remove} from "@/devices"
import store from '@/store/store'
import {
  assignDoor,
  getDefaultAlexaDevice,
  getNotificationSettings,
  getTimeline,
  getUpdateInfo, removeDoor, updateDeviceLocation, updateDeviceName, updateLanguage,
  updateNotificationSettings
} from '@/api'

let self = utils
const doorModel = require('@/models/doorModel.json')

var SunCalc = require('suncalc');

declare let window: any
const awsPwInstance = axios.create();

export default {

  //! *************************************************/
  async CallAllApis({dispatch}) {

    let config: any = await awsCognito.pwheader()
    awsPwInstance.defaults.headers.common = {
      Authorization: ((config || {}).headers || {}).Authorization,
      UserAccess: ((config || {}).headers || {}).UserAccess,
      'Client-Version': env.version
    }

    await dispatch('_fetchDevicesFromDb')
    await Promise.all([
      dispatch('_fetchServicesFromDb'),
      dispatch('_fetchUpdateInfoFromDb'), // FIRMWARE UPDATES!!
      dispatch('_fetchNotificationSettingsFromDb'),
      dispatch('_fetchDefaultAlexaId'),
      dispatch('SetPushNotifications') //globalactions!
    ])

  },

  //! *************************************************/
  async _fetchDevicesFromDb({state, dispatch, commit}) {
    if (state.uiloader.refreshDoors) return state.uiloader.refreshDoors

    const inner = (async () => {
      try {
        const resp = await awsPwInstance.get(env.mainurl + 'devices')
        if (!resp.data) {
          resp.data = []
        }

        try {
          let doorsCloud = self.$_A2O(resp.data, 'thing_name')
          let doorsStore = state.doors
          for (let tnStore in doorsStore) {
            // DELETE DOOR FROM STORE IF IT IS NOT IN THE CLOUDLIST
            if (!doorsCloud[tnStore]) {
              if (doorsStore[tnStore].case === 'demo' || doorsStore[tnStore].case === 'local') {
              } else {
                //  IF CURRENT DOOR IS DELETED!!
                if (tnStore === state.currentdoor) {
                  commit('set', ['currentdoor', Object.keys(state.doors)[0]])
                }

                remove(store, tnStore)
              }
            }
          }

          for (let tnCloud in doorsCloud) {
            // UPDATE STORE DOOR OR MAKE NEW ONE IF NOT IN LIST:
            let newdoor = doorsStore[tnCloud] ? doorsStore[tnCloud] : self.$_cp({...doorModel, assigned_components: [], pets: {}})
            newdoor.thing_name = doorsCloud[tnCloud].thing_name
            newdoor.Caption = doorsCloud[tnCloud].caption
            newdoor.caption = doorsCloud[tnCloud].caption //doubled because inconsistent!
            newdoor.device_serial = doorsCloud[tnCloud].device_serial
            newdoor.id = doorsCloud[tnCloud].id
            newdoor.last_online = doorsCloud[tnCloud].last_online
            newdoor.location = doorsCloud[tnCloud].location
            newdoor.system_note = doorsCloud[tnCloud].system_note
            newdoor.antenna = self.$_getAntennaPossibilities(doorsCloud[tnCloud].device_serial)
            newdoor.case = 'cloud'

            register(store, newdoor.thing_name)
            commit(`doors/${newdoor.thing_name}/hydrate`, newdoor)

            // TODO: set current door correctly
            if (state.currentdoor === 'demodoor' || !state.doors[state.currentdoor]) {
              commit('set', ['currentdoor', self.$_findRemove(Object.keys(state.doors), 'demodoor')[0] || 'demodoor'])
            }
            if (state.setdoor === 'demodoor' || !state.doors[state.setdoor]) {
              commit('set', ['setdoor', self.$_findRemove(Object.keys(state.doors), 'demodoor')[0] || 'demodoor'])
            }
          }
          for (let doorId in state.doors) {
            doorId !== 'demodoor' && dispatch('_fetchAddWeatherToDoor', state.doors[doorId])
          }

          connect.INITCONNECTIONS()
          return true
        } catch (err) {
          window.LOG.err('_fetchDevicesFromDb err2', err)
          return false
        }
      } catch (err) {
        window.LOG.err('_fetchDevicesFromDb err1', err)
        return false
      }
    })();

    commit('loader', ['refreshDoors', inner])
    const returnValue = await inner
    commit('loader', ['refreshDoors', false])
    return returnValue
  },

  //! *************************************************/
  async _fetchUpdateInfoFromDb({state, commit, dispatch}) {
    try {
      const data = (await getUpdateInfo()) || {}
      commit('set', ['updateInfo', data.update_states])
      await dispatch("_addVersionsToDoorStates", data.update_states)

    } catch (err) {
      window.LOG.err('_fetchUpdateInfoFromDb err', err)
      return false
    }
  },

  async _fetchDefaultAlexaId({state}) {
    try {
      const result = await getDefaultAlexaDevice()
      console.log('setting defaultAlexaId', result)
      state.defaultAlexaId = result
      console.log('setting done', state.defaultAlexaId)
    } catch (e) {
      window.LOG.err('_fetchDefaultAlexaId', e)
    }
  },

  //! *************************************************/
  async _addVersionsToDoorStates({state, commit}, updateStates) {
    for (let stateId in updateStates) {
      updateStates[stateId].state = self.$_compareV(updateStates[stateId].targetVersion, updateStates[stateId].currentVersion)
      let id = updateStates[stateId].deviceId
      for (let thingName in state.doors) {
        if (state.doors[thingName].id === id) {
          commit('set', ['doors', thingName, 'firmware', updateStates[stateId]])
        }
      }
    }
  },

  //! *************************************************/
  async _postConfirmFirmwareUpdate({commit, dispatch}, doordata) {
    let senddata = {
      deviceID: parseInt(doordata.doorid),
      swVersion: doordata.firmware,
      ...doordata.componentId && {componentId: doordata.componentId}
    }
    try {
      const resp = await awsPwInstance.post(env.mainurl + "update_confirm", senddata, await awsCognito.pwheader())
      dispatch("_fetchUpdateInfoFromDb")
      return PositiveFeedback('APIresponses.update-confirm.success')
    } catch (err) {
      window.LOG.err(err)
      return NegativeFeedback('APIresponses.update-confirm.failed')
    }

  },

  //! *************************************************/
  async _fetchServicesFromDb({commit, dispatch}) {
    try {
      const resp = await awsPwInstance.get(env.mainurl + 'services')
      if (!resp.data) {
        resp.data = []
      }
      let services = self.$_A2O(resp.data, 'id')
      commit('set', ['services', services])
      dispatch('_fetchUserServicesFromDb')
      return true
    } catch (err) {
      window.LOG.err('_fetchServicesFromDb err', err)
      dispatch('_fetchUserServicesFromDb')
      return false
    }
  },
  //! *************************************************/
  async _fetchUserServicesFromDb({commit}) {
    try {
      const resp = await awsPwInstance.get(env.mainurl + 'user_services')
      if (!resp.data) {
        resp.data = []
      }
      let userservices = self.$_A2O(resp.data, 'id')
      commit('set', ['userservices', userservices])
      return true
    } catch (err) {
      window.LOG.err('_fetchUserServicesFromDb err', err)
      return false
    }
  },

  //! *************************************************/
  async _fetchNotificationSettingsFromDb({commit}) {
    try {
      const data = (await getNotificationSettings()) || {}
      commit('set', ['notificationsettings', data.settings])
      return true
    } catch (err) {
      window.LOG.err('_fetchNotificationSettingsFromDb err', err)
      return false
    }
  },

  //! *************************************************/
  async _postNotificationSettings({commit, dispatch}, notifications) {
    commit('loader', ['changedNotifications', true])
    try {
      const resp = await updateNotificationSettings(notifications)
      await dispatch('_fetchNotificationSettingsFromDb')
      commit('loader', ['changedNotifications', false])
      return PositiveFeedback('APIresponses.notifications_changed')
    } catch (err) {
      window.LOG.err('_postNotificationSettings err', err)
      commit('loader', ['changedNotifications', false])
      return NegativeFeedback(err)
    }
  },

  //! *************************************************/
  async _fetchAddWeatherToDoor({commit}, door) {
    try {
      let timeleft = 60 * 1000 * 20 // 20min
      let weatherupdate = Date.now() - door.weather.update > timeleft
      if (door.location !== '0.0,0.0' && weatherupdate) {

        // PROPELELRCAT FOR OVERRULE UNSECURE CALLS
        // let weatherStackUrl = 'https://propellercat.com/cors/h/api.weatherstack.com/current?access_key=' + env.weatherstack + '&query=' + door.location
        let weatherStackUrl = 'https://api.weatherstack.com/current?access_key=' + env.weatherstack + '&query=' + door.location

        const response = await axios.get(weatherStackUrl, {})
        let data = response.data
        if (data && !data.error) {

          if (!data.current) data.current = {}

          var times = SunCalc.getTimes(new Date(), 51.5, -0.1)
          data.current.is_day = 1
          if (dayjs(times.sunrise).unix() < dayjs().unix() && dayjs(times.sunset).unix() > dayjs().unix()) {
            data.current.is_day = 1
          } else if (dayjs(times.sunrise).unix() > dayjs().unix() || dayjs(times.sunset).unix() < dayjs().unix()) {
            data.current.is_day = 0
          }

          data.update = Date.now()

          commit('setToDoor', [door.thing_name, 'weather', data])

          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (err) {
      window.LOG.err('_fetchAddWeatherToDoor', err)
      return false
    }
  },

  //! *************************************************/
  async _fetchTimeLineFromDb({state}, id: number) {
    try {
      const data = (await getTimeline(id)) || []
      return {
        data: data.reverse()
      }
    } catch (err) {
      window.LOG.err('_fetchTimeLineFromDb err', err)
      return NegativeFeedback('TimeLine.notifications.unknown-error')
    }
  },
  //! *************************************************/

  async _postUserLanguage({state, commit}, newLang: string) {
    try {
      if (['en', 'fr', 'de'].includes(newLang)) {
        if (state.userState.value !== "cloud") {
          commit('set', ['lang', newLang])
          return PositiveFeedback('APIresponses.language_changed')
        } else {
          commit('loader', ['langChanger', true])
          const resp = await updateLanguage(newLang)
          commit('set', ['lang', newLang])
          commit('loader', ['langChanger', false])
          return PositiveFeedback('APIresponses.language_changed')
        }
      } else return NegativeFeedback('APIresponses.no_valid_language')
    } catch (err) {
      window.LOG.err('_postUserLanguage err', err)
      commit('loader', ['langChanger', false])
      return NegativeFeedback(err)

    }
  },

  //! *************************************************/
  async _postNewNameForCloudDoor({commit, dispatch}, senddata) {
    commit('loader', ['renameDoor', true])
    try {
      let resp = await updateDeviceName(senddata)
      await dispatch('_fetchDevicesFromDb')
      commit('loader', ['renameDoor', false])
      return PositiveFeedback('APIresponses.doorname_changed')

    } catch (err) {
      window.LOG.err('_postNewNameForCloudDoor err', err)
      commit('loader', ['renameDoor', false])
      return NegativeFeedback(err)

    }
  },

  //! *************************************************/
  async _assignDoorToUserCloud({dispatch}, data) {
    try {
      let deviceSerialnr, deviceSecret, deviceCaption
      [deviceSerialnr, deviceSecret, deviceCaption] = data
      let cognitoIdentityID = await awsCognito.cognitoIdentityID()
      if (cognitoIdentityID) {
        var senddata = {
          device_serialnr: deviceSerialnr,
          device_secret: deviceSecret,
          device_caption: deviceCaption,
          cognitoIdentityID
        }
        let resp = await assignDoor(senddata)
        await dispatch('_fetchDevicesFromDb')
        return PositiveFeedback('NewDoor.notifications.door-assigned.success')
      } else {
        return NegativeFeedback('NewDoor.notifications.door-assigned.unknown-error')
      }
    } catch (err) {
      window.LOG.err('_assignDoorToUserCloud err', err)

      let status = err.response.status
      if (status === 401) {
        return NegativeFeedback('NewDoor.notifications.door-assigned.unknown-error')
      } else if (status === 403) {
        return NegativeFeedback('NewDoor.notifications.door-assigned.serial-secret-wrong')
      } else if (status === 470) {
        return NegativeFeedback('NewDoor.notifications.door-assigned.serial-secret-wrong')
      } else if (status === 500) {
        return NegativeFeedback('NewDoor.notifications.door-assigned.unknown-error')
      }

    }
  },

  //! *************************************************/
  async _sendLocationToCloud({commit, dispatch}, {position, doorID}) {
    commit('loader', ['savelocation', true])
    try {
      const resp = await updateDeviceLocation({location: position, doorID})
      await dispatch('_fetchDevicesFromDb')
      commit('loader', ['savelocation', false])
      return PositiveFeedback('APIresponses.location_changed')
    } catch (err) {
      window.LOG.err('_sendLocationToCloud err', err)
      commit('loader', ['savelocation', false])
      return NegativeFeedback(err)

    }
  },

  //! *************************************************/
  async _removeCloudDoorFromUser({state, commit, dispatch}, thing) {
    let door = state.doors[thing]
    try {
      commit('loader', ['removedoor', true])
      const resp = await removeDoor(door.id)
      remove(store, thing)
      window.LOG.green(resp)
      commit("removeDoor", thing)
      await dispatch("_fetchDevicesFromDb")
      commit('loader', ['removedoor', false])
      return PositiveFeedback('APIresponses.door_deleted')
    } catch (err) {
      window.LOG.err('_removeCloudDoorFromUser err', err)
      commit('loader', ['removedoor', false])
      return NegativeFeedback(err)
    }
  }
}

