
















import {Vue, Component, Getter, Action} from '@/vue-collection'
import StateMachine from 'javascript-state-machine'

import RequestDoorCredentials from "./RequestDoorCredentials.vue"
import ConnectToSetupWlan from "./ConnectToSetupWlan.vue"
import SearchingForDoor from "./SearchingForDoor.vue"
import FindExistingDoor from "./FindExistingDoor.vue"
import RequestDoorName from "./RequestDoorName.vue"
import RequestLocationPermission from "./RequestLocationPermission.vue"
import RequestWifi from "./RequestWifi.vue"
import StoreDoorInCloud from './StoreDoorInCloud.vue'
import {setupSsid} from "./constants"
import MobileAppNeeded from "@/pages/MobileAppNeeded.vue"

@Component({
  components: {
    RequestDoorCredentials,
    RequestLocationPermission,
    RequestDoorName,
    FindExistingDoor,
    ConnectToSetupWlan,
    SearchingForDoor,
    RequestWifi,
    StoreDoorInCloud,
    MobileAppNeeded
  },
  mixins: [{
    computed: {
      setupSsid: () => setupSsid
    }
  }]
})
export default class extends Vue {
  selectedDoor = null
  selectedWifi = null
  doorName = null
  credentials: {serial: string, secret: string} = null
  @Getter onMobile
  @Action _getWifiName

  sm = new StateMachine({
    init: "assure-location-permission",
    transitions: [
      { name: 'locationPermissionAssured', from: 'assure-location-permission', to: 'request-door-credentials' },
      { name: 'deviceCredentialsStored', from: 'request-door-credentials', to: 'manual-wifi-connection' },
      { name: 'wifiConnected', from: 'manual-wifi-connection', to: 'search-for-door' },
      { name: 'skipSetupWifi', from: 'manual-wifi-connection', to: 'find-existing-door' },
      { name: 'doorFound', from: 'search-for-door', to: 'ask-for-wifi' },
      { name: 'wifiCredentialsStored', from: 'ask-for-wifi', to: 'find-existing-door' },
      { name: 'doorFound', from: 'find-existing-door', to: 'ask-for-door-name' },
      { name: 'doorNameStored', from: 'ask-for-door-name', to: 'save-door' },
    ] as const,
  })

  async storeCredentialsAndContinue(credentials) {
    this.credentials = credentials
    try {
      await this._getWifiName()
    } catch (e) {
      console.error(e)
    }
    this.sm.deviceCredentialsStored()
  }

  storeSelectedDoorAndContinue(selectedDoor) {
    this.selectedDoor = selectedDoor
    this.sm.doorFound()
  }

  storeWifiAndContinue(wifiSsid)
  {
    this.selectedWifi = wifiSsid
    this.sm.wifiCredentialsStored()
  }

  storeSelectedDoor2(selectedDoor) {
    this.selectedDoor = selectedDoor
    this.sm.doorFound()
  }

  storeDoorName(name) {
    this.doorName = name
    this.sm.doorNameStored()
  }

  onFinish() {
    this.$f7router.back()
  }
}
