<template lang="pug">
f7-page#NewModule.theme-clear
  f7-navbar(back-link="Back", no-hairline, no-shadow, large-transparent)
    f7-nav-right
      f7-link
        i.small.icon-close
  f7-block.max960
    f7-block-title {{$t('ServiceAlb.new_component.title')}}
    p {{$t('ServiceAlb.new_component.description')}}
    f7-list(no-hairlines-md)
      f7-list-input(:label="$t('ServiceAlb.new_component.name')", type="text", :placeholder="$t('ServiceAlb.new_component.placeholder')", clear-button, :value="name" @input="name = $event.target.value")
    pw-button(col="green" size="m" position="center" texter='ServiceAlb.new_component.save', @click="onSaveClicked", loader="saveComponent")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
  export default {
    name: "NewModule",
    props: ['localComponentId'],
    data: () => ({
      name: ''
    }),
    methods: {
      ...mapMutations(['loader']),
      ...mapActions([ 'saveComponent' ]),
      async onSaveClicked() {
        this.loader('saveComponent', true)
        try {
          const result = await this.saveComponent({ localComponentId: this.localComponentId, name: this.name })
          this.UIResponder(result)

          if (!result.err) this.$f7router.navigate('/settings')
        } catch (e) {
          console.error(e)
        }
        this.loader('saveComponent', false)
      }
    }
  }
</script>

<style scoped>

</style>
