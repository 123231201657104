import AWS, { S3 } from 'aws-sdk'
import store from '@/store/store'
import env from '@/env'

const imagesBucket = env.aws.images_bucket_id
let s3;

export async function getOwnedSignedUrl(bucket, key) {
  if (!store.state.user) return Promise.resolve('')

  try {
    s3 = new S3()
    return await s3.getSignedUrlPromise('getObject', {
      Bucket: bucket,
      Key: `${store.state.user.identity}/${key}`
    })
  } catch (e) {
    return ''
  }
}

export function getPetImageUrl(petId) {
  return getOwnedSignedUrl(imagesBucket, `pet-images/${petId}`)
}

export function uploadImage(petId, image) {
  if (!store.state.user) return Promise.reject(new Error('no user loaded, cannot call api'))

  if (!s3) s3 = new S3()
  const [blob, contentType] = dataURItoBlob(image)

  return s3.upload({
    Bucket: imagesBucket,
    Key: `${store.state.user.identity}/pet-images/${petId}`,
    Body: blob,
    ContentType: contentType
  }).promise().then(() => getPetImageUrl(petId))
}

export function deleteImage(petId) {
  if (!s3) s3 = new S3()

  return s3.deleteObject({
    Bucket: imagesBucket,
    Key: `${store.state.user.identity}/pet-images/${petId}`,
  }).promise()
}

// from https://stackoverflow.com/questions/12168909/blob-from-dataurl
function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return [new Blob([ab], { type: mimeString }), mimeString];
}

