
<template lang="pug">
f7-page#Camera
  f7-navbar(title='Camera', back-link='Back')

  f7-block

  .max960
      f7-block-title nofin here yet

</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="stylus">
.example
  color: #234
</style>
