import {get, has} from "@/devices/repository"
import {first, tap} from "@/libs/functional"

export function acknowledgeError(thingName: string) {
  const device = getDeviceOrThrow(thingName)

  if (device.wsConnected) {
    return device.wsCommands.request('ErrorCommit')
  } else {
    throw new Error('not connected to websocket')
  }
}

function getDeviceOrThrow(thingName: string) {
  if (!has(thingName)) throw new Error(`${thingName} does not exist`)
  return get(thingName)
}
