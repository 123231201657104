import Observable from "zen-observable"

const zeroconf = () => typeof (window as any).cordova !== 'undefined' && (window as any).cordova.plugins && (window as any).cordova.plugins.zeroconf

const observerMap = {}
export async function observe(pattern = '_http._tcp.'): Promise<Observable<any>> {
  await reInit()

  const observable = observerMap[pattern] || new Observable<any>(o => {
    if (!zeroconf()) { o.error('cordova not loaded'); return }

    zeroconf().watch(pattern, 'local.', res => {
      console.log(res)
      if (res.action === 'resolved') o.next(res.service)
    }, err => {
      o.error(err)
    })
  })

  observerMap[pattern] = observable
  return observable
}

export function reInit() {
  return new Promise((res, rej) => zeroconf() ? zeroconf().reInit(res, rej) : rej(new Error('cordova not loaded')))
}
