














import {Mixins, Prop} from "vue-property-decorator"
import shadowValueState from "./shadowValueStateMixin"
import SettingsControl from "@/components/AdvancedSets/SettingsControl.vue"
import Component from "vue-class-component"

@Component({
  components: {SettingsControl}
})
export default class extends Mixins(shadowValueState('clb_cfg_volume', 'setSignalVolume', 2)) {
  @Prop(Object) readonly door
}
