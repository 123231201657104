import i18n from 'i18next'
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import env from './env'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: `${env.aws.translation_bucket}/{{lng}}.json`,
    },
    fallbackLng: ['de'], // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false,
    interpolation: { escapeValue: false },
  })
  .catch(console.error)

export default i18n
