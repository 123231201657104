<template lang="pug">
f7-page#home.nopadding.mainhome(name="Home")
  f7-navbar(v-if="!isUserStateUnset", title='', :innerClass="`homenavbar ${(($f7route.route||{}).component||{}).name}`")
    f7-nav-left
      transition(name='smoother', mode="out-in")
        .doorchooser(:key="_currentDoorId")
          a.bold.color-grey.panel-open(href='#', panel-open='left' @click="reloadDoorsBackground")
            template(v-if="_currentDoor")
              span(v-if="userState.value === 'demo'") {{_currentDoor.Caption}}
              span(v-else-if="(userState.value === 'offline' || userState.value === 'cloud') && $_size(_allDoors) > 0 ") {{_currentDoor.Caption}} &nbsp;&nbsp;
                i.icon-down
            span.nodoors.grey(v-else-if="(userState.value === 'offline' || userState.value === 'cloud') && $_size(_allDoors) === 0 ")  {{$t("Home.nodoors")}}
            span(v-if="uiloader.refreshDoors")
              .pwloader._mm

    f7-nav-right.homeicons
      f7-link(v-if="_currentDoor")
        span.img-wrapper
          connection-state(:door="_currentDoor")
      f7-link.color-grey(v-if="_currentDoor" :href='"/timeline/"+_currentDoorId')
        i.icon-bell
      f7-link.color-grey(href='/settings/')
        i.icon-settings

  .pull-to-refresh(:style="{transform: `translate3d(0px, ${-150 - pullRange}px, 0)`}" :class="{_release: pullRange <= -240}")
    i.icon-update

  DoorScreen

  span(v-if="!isUserStateUnset")
    door-control
    flag-control(:key="_currentDoorId")

  user-creds(mode="full", v-if="isUserStateUnset && !uiloader.startloader", propose-demo)

</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"

let self = null
export default {
  name: "Home",
  data: () => ({
    pullRange: 0
  }),
  beforeCreate() {
    self = this
  },
  computed: {
    ...mapState(["doors", "wscon", "uiloader"]),
    ...mapGetters(["_doorConnectionSymbol"]),
    ...mapGetters("userState", {isUserStateUnset: 'isUnset'})
  },
  methods: {
    ...mapActions(['_fetchDevicesFromDb']),
    reloadDoorsBackground() {
      return this._fetchDevicesFromDb()
    }
  },
  mounted() {
    // FLAG CONTROL HOME SWIPE / SCROLL!!
    document.getElementById("home").addEventListener(
      "wheel",
      e => {
        if (e.deltaY < 0) {
          self.$f7.sheet.open("#Flag_Sheet")
        }
      },
      true
    )

    document.getElementById("framework7-root").addEventListener(
      "wheel",
      e => {
        if (e.deltaY > 0) {
          self.$f7.sheet.close("#Flag_Sheet")
        }
      },
      true
    )

    let starttouch = -1
    self.$$("#home").touchmove(function(th) {
      mover(th.targetTouches[0])
      if (starttouch > 0) {
        let diff = starttouch - th.targetTouches[0].clientY

        if (diff > 32) {
          if (self.userState.value !== "unset" && self._hasdoors) self.$f7.sheet.open("#Flag_Sheet")
        }
      }
    })
    self.$$("#home").touchstart(function(th) {
      starter(th.targetTouches[0])
      starttouch = th.targetTouches[0].clientY
    })
    self.$$("#home").touchend(function(th) {
      ender(th.targetTouches[0])
      starttouch = -1
    })
    self.$$("#home").mousedown(starter)
    self.$$("#home").mousemove(mover)
    self.$$("#home").mouseup(ender)

    let touch = false
    let touchStart
    let refreshOnRelease = false
    function starter(th) {
      touch = true
      touchStart = th.clientY
    }
    function mover(th) {
      if (touch) {
        self.pullRange = Math.max(touchStart - th.clientY, -240)
        refreshOnRelease = self.pullRange <= -240
      }
    }
    function ender(th) {
      touch = false
      self.pullRange = 0
      touchStart = 0
      if (refreshOnRelease) {
        location.reload();
      }
    }
  }
}
</script>

<style scoped lang="stylus">

#home
  .page-content
    perspective: 500px;

.wscon
  position: fixed
  right: 0
  top: 64px
  width: 90px
  height: 28px
  color: #ffffff
  background: #e55

.doorchooser
  a
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700
    color: #424242;
    letter-spacing: -1px;
    span
      margin-left:4px;

.smoother-enter-active, .smoother-leave-active
  transition: all .25s ease;
.smoother-enter, .smoother-leave-to
  opacity: 0;
  transform: translateY(-100%) rotateX(90deg) scale(1);

.homeicons
  font-size: 28px
  a.link
    padding: 0 0px!important
    min-width: 42px!important
    text-align: center!important
    color: #424242!important
    i
      width: 100%;
      color: #424242!important
    &:hover
      opacity .85
    &:active
      opacity .7

.ios .navbar .left a+a, .ios .navbar .right a+a
  margin-left: 0px!important

.img-wrapper
  width 100%

.pull-to-refresh
  width 28px
  height 28px
  font-size 28px
  line-height 28px
  position: fixed;
  margin: auto;
  z-index 10
  left: 0;
  right: 0;
  text-align: center;
  transition: transform .25s, height 0.25s, width 0.25s, font-size .25s, color 0.25s;

  &._release
    transition unset
    animation spin 1s ease infinite
    top 90px
    width 40px
    height 40px
    font-size 40px

    i
      color: #91ac25
</style>
