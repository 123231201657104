













import {Vue, Component} from '@/vue-collection'

@Component
export default class extends Vue {
  doorName: string = ""
}
