







import Component from 'vue-class-component'
import { Mixins, Prop } from "vue-property-decorator"
import shadowValueState from './shadowValueStateMixin'
import SettingsControl from "./SettingsControl.vue"

@Component({
  components: {SettingsControl}
})
export default class extends Mixins(shadowValueState('clb_cfg_led_brightness', 'setLedBrightness', 50)) {
  @Prop(Object) door
}

