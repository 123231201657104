<template lang="pug">
.ServiceALB
  f7-block(v-for="(door, thingName) in _allDoors")
    f7-block-title {{door.caption}}
      .fr
        connection-state(:door="door")

    f7-list( strong, no-hairlines)
      f7-list-item(v-for="(lcObj, idx) in _doorPetComponentRelation[thingName]")
        span(slot="title") {{lcObj.caption || idx}}
          span.bold(v-if="!lcObj.online") (offline)
        i.icon-pet_recognition.small(slot='media')

        span(slot="after")
          span(v-if="lcObj.lcId !== 'intern'")
            pw-button(:texter="'remove'", loader="", size="ss", col="red", position="inline", @click="onRemoveClicked(idx, thingName)")
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  data: () => ({}),
  computed: {
    ...mapGetters([
      "_allDoors",
      "_doorConnectionSymbol",
      "_doorPetComponentRelation"
    ]),
  },
  methods: {
    ...mapActions(["removeComponent"]),
    async onRemoveClicked(localCompId, thingName) {
      let resp = await this.removeComponent({ thing: thingName, localComponentId: localCompId });
      resp.title = this.$t('Services.notification-title-remove');

      this.UIResponder(resp);
    }
  }
};
</script>
