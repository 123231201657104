
<template lang="pug">
f7-page#Timeline(ptr='', @ptr:refresh='getTimeline' data-ptr-distance="55" data-ptr-mousewheel="true")
  f7-navbar(:title='$t("TimeLine.TimeLine")' :back-link='$t("Navigation.back") || "Back"')

  .max960
    .timeloader(v-if="uiloader.timelineloader")
      .tac
        .pwloader._l

    f7-block(v-else-if="notimelinefound")
      h4 {{$t('TimeLine.noentries')}}

    .category(v-else)
      .scroller
        div.pet-selector(:class="{ selected: filter === null}", @click="filter = null")
          .icon
            f7-icon(icon="icon-notification" size="28px")
          .text
            span {{$t('TimeLine.filter-all')}}
        div.pet-selector(v-for="pet in _allPets" :class="{ selected: filter === pet.id }" @click="filter = pet.id" :key="pet.id")
          .icon
            pet-icon(:pet="pet")
          .text
            span {{pet.name}}

    transition(name="fade")
      div(v-if="filteredTimeline.length > 0 && filteredTimeline[selectedDay]")
        div.list-group-header
          div.date-controls(v-if="selectedDay < filteredTimeline.length - 1")
            button(@click="selectedDay += 1")
              i.icon-chevron-left-solid
          a(@click.stop="openSmartSelect" ref="smartSelectLink") {{ filteredTimeline[selectedDay][0] }}
            select(v-model="selectedDay" style="display: none")
              option(v-for="([k], idx) in filteredTimeline" :key="k" :value="idx" :selected="selectedDay === idx") {{ k }}
          div.date-controls(v-if="selectedDay > 0")
            button(@click="selectedDay -= 1")
              i.icon-chevron-right-solid
        f7-list#events-list
          pw-event(:key="idx", v-for="(it,idx) in filteredTimeline[selectedDay][1]" :ev="it")

</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import redirectWhenUnsetUserState from "../libs/mixins/redirectWhenUnsetUserState"
import PetIcon from "@/components/PetIcon.vue"
import Entry from './TimeLine/Entry'
let self = null;
export default {
  beforeCreate() {
    self = this;
  },
  name: "timeline",
  props: ['id'],
  data() {
    return {
      timeline: {},
      filteredTimeline: {},
      filter: null,
      notimelinefound: false,
      selectedDay: null
    };
  },
  watch: {
    checkeduser(nv) {
      if (nv) self.getTimeline();
    },
    id() {
      this.getTimeline()
    },
    filter() {
      this.updateFilteredTimeline()
    },
    timeline() {
      this.selectedDay = null
      this.updateFilteredTimeline()
    }
  },
  computed: {
    ...mapState(["checkeduser", "uiloader"]),
    ...mapGetters(['_allPets']),
    door() {
      return self._allDoors[this.id]
    }
  },
  mounted() {
    if (self.$f7route.name === "timeline" && this.checkeduser)
      self.getTimeline()
  },
  methods: {
    ...mapActions(["_fetchTimeLineFromDb"]),
    hasFilteredEntries(list) {
      return this.filter === null || !!list.find(e => e.pet && e.pet.id == this.filter)
    },
    async getTimeline() {
      self.notimelinefound = false;

      self.$store.commit("loader", ["timelineloader", true]);

      let resp = await self._fetchTimeLineFromDb(self.door.id);
      self.$f7.ptr.done();

      if (resp.err) self.UIResponder(resp);
      else if (resp.data.length === 0) {
        self.notimelinefound = true;
      } else {
        let data = resp.data;
        let ret = {};
        for (let item of data) {
          item.hrdate = self
            .$dayjs.utc(item.date)
            .local()
            .format("DD.MM.YYYY");
          item.hrtime = self
            .$dayjs.utc(item.date)
            .local()
            .format("ddd H:mm:ss");
          const key = self
            .$dayjs.utc(item.date)
            .local()
            .format('ddd DD.MM.YY')
          if (!ret[key]) ret[key] = [];
          ret[key].push(item);
        }
        self.timeline = ret;
      }

      self.$store.commit("loader", ["timelineloader", false]);
    },
    updateFilteredTimeline() {
      console.log('update filtered timeline')
      if (this.filter === null) this.filteredTimeline = Object.entries(this.timeline)
      else this.filteredTimeline = Object.entries(this.timeline)
        .map(([d, l]) => [d, l.filter(e => e.pet && e.pet.id == this.filter)])
        .filter(([d, l]) => l.length > 0)
      this.selectedDay = this.selectedDay <= 0 ? (this.filteredTimeline.length > 0 ? 0 : null) : this.selectedDay
      if (this.selectedDay !== null && this.selectedDay >= this.filteredTimeline.length) this.selectedDay = this.filteredTimeline.length - 1
    },
    openSmartSelect() {
      const smartSelect = this.$f7.smartSelect.create({ el: this.$refs.smartSelectLink })
      smartSelect.open()
    }
  },
  mixins: [redirectWhenUnsetUserState],
  components: {PetIcon, 'pw-event': Entry}
};
</script>

<style scoped lang="stylus">
#events-list
  margin-top 0

i
  font-size: 24px
  line-height: 24px;

.list-group-header
  padding: .5rem 1rem
  display: grid
  grid-template-columns 1fr auto 1fr
  align-items center
  font-weight: 400
  font-size: 24px
  background: #efeff4
  color: #676767

  a
    justify-self center
    grid-column: 2
  .date-controls:last-child
    justify-self end
  button
    all: unset
    cursor: pointer

.timeloader
  position: fixed
  top: 64px
  left:0
  right:0

.pet-selector
  width 9em
  height 5em
  cursor pointer
  box-sizing border-box
  display inline-block
  text-align center

  .icon
    display flex
    align-items center
    justify-content center
    height 3em
  .text
    height 2em

  transition: border-color .45s
  &.selected
    border-top: 3px solid #A4C121

.pet-icon-container
  width 28px
  height 28px
  vertical-align center

.category
  display: block
  background-color var(--f7-block-strong-bg-color)

  .scroller
    white-space: nowrap
    display: block
    overflow-y hidden

.fade-enter-active, .fade-leave-active
  transition: all .25s
  opacity: 1
  transform: translateY(0%)
.fade-enter,.fade-leave-to
  opacity: 0
  transform: translateY(90px)

.date-controls
  button
    margin-right: .5rem
    &:last-child
      margin-right: 0
</style>
